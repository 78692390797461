import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@ns-react/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBBtn } from 'mdbreact';

export default class ModalAlertObligatorio extends Modal {
    constructor(props){
        super(props);
        this.aceptar = this.aceptar.bind(this);
    }

    componentWillUnmount(){
        if (typeof this.props.onClose == 'function') this.props.onClose();
    }

    aceptar(){
        super.closeAlert();
    }

    render(){
        let items = this.props.items;
        return (
            <Modal>
                <ModalHeader>
                    <div className="modal-header modal-header-error" >
                        <FontAwesomeIcon icon="exclamation-circle" />&nbsp;
                        <span>{this.props.title === undefined ? 'Mensaje' : this.props.title }</span>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="modal-body" >
                        <ul>
                        {
                            items.map((u, i) => {
                                return  (<li key={i} >{u}</li>)
                            })
                        }
                        </ul>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="modal-footer-alert" >
                        <MDBBtn rounded className="btn-primario-modal-md" onClick={this.aceptar}>Aceptar</MDBBtn>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}