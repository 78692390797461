import BaseService from './base/BaseService';


export default class MatchService extends BaseService {

  static preguntasMatch(params) {
    return super.get('match/preguntas', params);
  }

  static respuestasMatch(params) {
    return super.get('match/respuestas', params);
  }

  static guadarMatch(data) {
    return super.post('match/saveMatch', null, data);
  }

  static obtenerMatch(params) {
    return super.get('match/empresas', params);
  }

  static obtenerMatchFiltro(params) {
    return super.get('match/empresasFiltro', params);
  }

  static hacerMatch() {
    return super.post('match/match', null, null);
  }

}
