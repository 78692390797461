import React from 'react';
import { connect } from 'react-redux';

/**
 * @description establece las props para el componente LabelMapper
 * de acuerdo al `state`
 */
const mapStateToProps = (state) => ({
  labelMap: state.labelMap,
});

class LabelMapper extends React.Component {
  render() {
    return (
      <span>{getLabel(this.props.labelMap, this.props.label)}</span>
    );
  }
}

/**
 * @author Sinecio Bermúdez Jacque
 * @description Permite obtener el valor de una propiedad
 * de un objeto literal con `dot.notation`
 */
function getLabel(labelMap, label = '') {
  const arrLabels = label.toUpperCase().split('.');
  label = arrLabels.shift();

  const map = upperCase(labelMap);
  if (map[label] == null) {
    arrLabels.unshift(label);
    return `{${arrLabels.join('.')}}`;
  }
  if (typeof map[label] === 'object') return getLabel(map[label], arrLabels.join('.'));
  return String(map[label]);
}

/**
 * @author Sinecio Bermúdez Jacque
 * @description Permite transformar un objeto literal con claves en Mayusculas
 */
function upperCase(obj) {
  const upper = {};
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i++) {
    upper[keys[i].toUpperCase()] = obj[keys[i]] != null && typeof obj[keys[i]] === 'object' ? upperCase(obj[keys[i]]) : obj[keys[i]];
  }
  return upper;
}

export default connect(
  mapStateToProps,
)(LabelMapper);
