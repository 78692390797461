import React from 'react';
import FileUpload from 'commons/components/FileUpload';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody
} from '@ns-react/modal';
import CloseButton from 'commons/components/CloseButton';
import { alertV2 } from 'commons/util';

class CargaImagenModal extends Modal {
    constructor(props) {
        super(props);
        this.state = {
            invalidFiles: [],
            id: ''
        }
        this.cerrarModal = this.cerrarModal.bind(this);
        this.actualizar = this.actualizar.bind(this);
        this.handleInvalidFile = this.handleInvalidFile.bind(this);
    }

    actualizar(response) {
        
        if (response.status === 200) {
            this.props.recarga();
            super.closeModal();
        }else if (response.status === 417 &&  response.response !== null &&  response.response !== '') {
            alertV2('Aviso', response.response);
        }else{
            super.closeModal();
            alertV2('Aviso', 'Por favor verifique el tamaño de la imagen');
        }
    }

    cerrarModal() {
        super.closeModal();
    }

    handleInvalidFile(files) {
        this.setState({
            invalidFiles: files
        });
    }

    componentWillMount() {
        let data = {
            id: this.props.id
        }
        this.setState({
            id: data
        });
    }

    render() {
        return (
            <Modal size="medium" className="centrado">
                <ModalHeader>
                    <div className="modal-header" >
                        <h5>Cambiar Imagen</h5>
                    </div>
                    <CloseButton onClick={this.cerrarModal}></CloseButton>
                </ModalHeader>
                <ModalBody>
                    <div className="modal-body" >
                        <FileUpload onComplete={this.actualizar} endpoint={this.props.url}
                            params=""
                            data={this.state.id}
                            onInvalidFile={this.handleInvalidFile}
                            multiple="false"></FileUpload>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

}
const mapStateToProps = state => {
    return {
        labelMap: state.labelMap,
        loginProps: state.loginProps
    };
}


export default connect(
    mapStateToProps
)(CargaImagenModal);
