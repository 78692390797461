import {
    MENSAJES,
} from '../actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case MENSAJES: {
                return action.payload.data;
        }
        default:
            return state;
    }
};