/**
 * @author Sinecio Bermúdez Jacque
 * @description Función que permite parsear una Url con paths dinamicos,
 * generando una url con queryString si los parámetros no pueden ser parseados
 * directamente en la url.
 * @param {String} url string que respresenta una url con path dinamicos
 * @param {Object} params objeto literal con parámetros a utilizar en la url
 * @example
 *      var params = {
 *          Path: 'Usuarios',
 *          ID: 1
 *      };
 *      var url = parsePath('/api/{path}/{id}', params) //la variable no es caso sensitivo
 *      //resultado: /api/Usuarios/1
 */
export default function parsePath(path, params = {}) {
  const queryString = [];
  const keys = Object.keys(params == null || typeof params !== 'object' ? {} : params);

  if (keys.length <= 0) return path.replace(/{.*?}/ig, '').replace(/\/$/, '');

  keys.forEach((key) => {
    const regex = new RegExp(`{${key}}`, 'ig');
    if (regex.test(path)) path = path.replace(regex, params[key] || '');
    else queryString.push(`${key}=${encodeURIComponent(params[key])}`);
  });

  if (queryString.length > 0) path = `${path}?${queryString.join('&')}`;

  return path.replace(/\/$/, '');
}
