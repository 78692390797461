import {
  LOAD_MAPPER_MAP,
} from '../actionTypes';

/**
 * @description define el estado para el componente `LabelMap` de acuerdo
 * a la acción notificada.
 */
export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_MAPPER_MAP: {
      /**
			 * no hay condiciones, sólo informa el nuevo valor
			 * desde `action.payload.map`
			 */
      return action.payload.map;
    }

    default: return state;
  }
};
