import BaseService from './base/BaseService';

/**
 * @author Sinecio Bermúdez Jacque
 * @description Servicio de Ejemplo que hereda métodos
 * de la clase base `BaseService` y expone métodos relacionados
 * al recurso `/labels`.
 */
export default class LabelService extends BaseService {
  /**
     * @author Sinecio Bermúdez Jacque
     * @description Realiza una llamada a la api `/labels`.
     * Como resultado es un objeto `Promise` donde cuyo valor resuelto
     * es un objeto literal con los atributos `status` y `data` donde
     * `status` es el código de estado de la petición y `data` es un objeto `Label`
     */
  static obtener() {
    return super.get('/home/getLabels');
  }
}
