import React from 'react';
import { connect } from 'react-redux';

import { LabelService } from '../services';
import { loadMapperMap } from '../redux/actions/labelMapActions';

class LabelMapperProvider extends React.Component {
  componentWillMount() {
    const { dispatch } = this.props;
    LabelService.obtener().then((resp) => {
      dispatch(loadMapperMap(resp));
    });
  }

  render() {
    return this.props.children;
  }
}

/**
 * @description establece las props para el componente LabelMapperProvider
 * de acuerdo al `state`
 */
const mapStateToProps = (state) => ({
  labelMap: state.labelMap,
});

export default connect(
  mapStateToProps,
)(LabelMapperProvider);
