import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
* @description establece las props para el componente MainMenu
* de acuerdo al `state`
*/
const mapStateToProps = (state) => {
    return { avatarProps: state.avatarProps }
};

class AvatarImpl extends Component {
    render() {
        const { icono, clase, params } = this.props;
        return (
            <div className={clase}
            
                style={{ backgroundImage: `url(${params})` }} >
                {
                    icono !== "" ?
                        <FontAwesomeIcon icon={["fas", "camera"]} size="2x" className="ico-camera" /> :
                        ""
                }
            </div>

        );
    }
}

export default connect(
    mapStateToProps,
)(AvatarImpl);
