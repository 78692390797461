import React, { Component } from "react";
import LoginModal from "commons/components/sesion/LoginModal";
import LabelMapper from "commons/components/LabelMapper";
import { modal } from "commons/util";
import {
  MiPerfil,
  MiEmpresa,
  MisMatch,
  REVISION_SESSION_INTERVALO,
  KEY_EXPIRE,
  MiMensajeria,
} from "commons/constants";
import {
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBCollapse,
  MDBSwitch,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavLink,
  NavbarNav,
  MDBNavbarToggler,
} from "mdbreact";
import {
  AUTH_KEY,
  AUTH_NOMBRE,
  MAIL_PENDIENTE,
  FILTRO_REGION,
} from "commons/constants";
import LoginService from "commons/services/LoginService";
import MailService from "commons/services/MailService";
import { loginData } from "commons/redux/actions/loginActions";
import {
  mensajesData,
  msgBandejaData,
} from "commons/redux/actions/mensajesAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AvatarImpl from "commons/components/avatar/AvatarImpl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalRenovarSesion from "../commons/components/sesion/ModalRenovarSesion";
import { Helmet } from "react-helmet";
import HomeRest from "commons/services/HomeRest";
import {
  destacadosTurData,
  destacadosTecData,
} from "commons/redux/actions/destacadosAction";
import MatchService from "commons/services/MatchService";
import { matchData } from "commons/redux/actions/matchAction";

/**
 * @description establece las props para el componente Header
 * de acuerdo al `state`
 */
const mapStateToProps = (state) => {
  return {
    labelMap: state.labelMap,
    loginProps: state.loginProps,
    mensajesProps: state.mensajesProps,
    msgBandejaProps: state.msgBandejaProps,
  };
};

class Header extends Component {
  state = {
    isOpen: false,
    authenticated: false,
    nombreUsuario: "",
    collapseID: "",
    time: 0,
    modalRenovar: false,
    redireccionar: false,
    switchRegion: false,
  };

  componentDidMount = () => {
    this.validaUsuarioSession();
    this.revisarMensajeria();
    this.setState({
      switchRegion:
        typeof sessionStorage.getItem(FILTRO_REGION) !== "undefined" &&
        sessionStorage.getItem(FILTRO_REGION) === "true",
    });
  };

  cerrarSesion = () => {
    const { dispatch } = this.props;

    sessionStorage.clear();
    dispatch(loginData(""));
    window.location.href = window.location.origin;
  };

  renovarSesion = () => {
    setInterval(() => {
      let { time, modalRenovar } = this.state;
      let keyExpire = parseInt(sessionStorage.getItem(KEY_EXPIRE));

      time = time + REVISION_SESSION_INTERVALO;
      if (!modalRenovar) {
        if (time >= keyExpire) {
          modalRenovar = true;
          modal(ModalRenovarSesion, {
            renovar: this.actualizarToken,
          });
        }
      }

      this.setState({
        time,
        modalRenovar,
      });
    }, REVISION_SESSION_INTERVALO);
  };

  actualizarToken = () => {
    this.setState({
      time: 0,
      modalRenovar: false,
    });
    LoginService.resfrescarTicket();
  };

  validaUsuarioSession() {
    let self = this;
    let { dispatch } = this.props;
    let token = sessionStorage.getItem(AUTH_KEY);
    if (token !== null) {
      LoginService.resfrescarTicket().then(function (returnData) {
        if (returnData.status === 200) {
          dispatch(loginData(returnData.data.access_token));
          self.renovarSesion();
        }
      });
    } else {
      sessionStorage.clear();
      dispatch(loginData(""));
      if (window.location.pathname !== "/") {
        window.location.href = window.location.origin;
      }
    }
  }

  modalLogin = () => {
    modal(LoginModal, { crearEmpresa: this.crearEmpresa });
  };

  crearEmpresa = () => {
    this.props.history.push(MiEmpresa);
  };

  handleSwitchRegion = () => {
    let valor = !this.state.switchRegion;
    this.actualizarFiltro(valor);
  };

  actualizarFiltro(valor) {
    let self = this;
    let data = {
      regionEmpresa: valor,
    };

    LoginService.saveFilter(data).then((parametros) => {
      if (parametros.status === 200) {
        sessionStorage.setItem(FILTRO_REGION, valor);
        self.setState({
          switchRegion: valor,
        });

        this.recargaDestacados();
      }
    });
  }

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  revisarMensajeria = () => {
    let { dispatch } = this.props;
    let cantidadMensajes =
      sessionStorage.getItem(MAIL_PENDIENTE) != null
        ? sessionStorage.getItem(MAIL_PENDIENTE)
        : 0;
    dispatch(mensajesData(cantidadMensajes));

    setInterval(() => {
      let token = sessionStorage.getItem(AUTH_KEY);
      if (token !== null) {
        this.actualizaMsgTotal();
        if (window.location.pathname.toString().includes("mensajeria")) {
          this.actualizaMsgBandeja();
        }
      }
    }, 1000 * 30);
  };

  actualizaMsgTotal() {
    let { dispatch } = this.props;
    MailService.countPending().then((resp) => {
      if (resp.status === 200) {
        let cantidadMensajes = isNaN(parseInt(resp.data)) ? 0 : resp.data;
        dispatch(mensajesData(cantidadMensajes));
      }
    });
  }

  actualizaMsgBandeja() {
    let { dispatch } = this.props;
    MailService.obtenerPorBandejaEntrada().then((notificaciones) => {
      let data = notificaciones === null ? [] : notificaciones.data;
      const params = {
        notificaciones: data,
      };
      dispatch(msgBandejaData(params));
    });
  }

  recargaDestacados() {
    if (window.location.pathname.toString() === "/") {
      if (
        sessionStorage.getItem(FILTRO_REGION) != null &&
        sessionStorage.getItem(FILTRO_REGION) === "true"
      ) {
        this.findDestacadosTurFiltro();
        this.findDestacadosTecFiltro();
      } else {
        this.findDestacadosTur();
        this.findDestacadosTec();
      }
    } else if (window.location.pathname.toString().includes("match")) {
      this.findMatch();
    }
  }

  findDestacadosTurFiltro() {
    const { dispatch } = this.props;
    const data = {
      region: sessionStorage.getItem(FILTRO_REGION),
    };
    HomeRest.findDestacadosTurFiltro(data).then((destacados) => {
      const params = {
        configuracion:
          destacados !== null && destacados.data !== null
            ? destacados.data.configuracion
            : {},
        destacados:
          destacados !== null && destacados.data !== null
            ? destacados.data.contenido
            : [],
      };
      dispatch(destacadosTurData(params));
    });
  }

  findDestacadosTur() {
    const { dispatch } = this.props;
    HomeRest.findDestacadosTur().then((destacados) => {
      const params = {
        configuracion:
          destacados !== null && destacados.data !== null
            ? destacados.data.configuracion
            : {},
        destacados:
          destacados !== null && destacados.data !== null
            ? destacados.data.contenido
            : [],
      };
      dispatch(destacadosTurData(params));
    });
  }

  findDestacadosTecFiltro() {
    const { dispatch } = this.props;
    const data = {
      region: sessionStorage.getItem(FILTRO_REGION),
    };
    HomeRest.findDestacadosTecFiltro(data).then((destacados) => {
      const params = {
        configuracion:
          destacados !== null && destacados.data !== null
            ? destacados.data.configuracion
            : {},
        destacados:
          destacados !== null && destacados.data !== null
            ? destacados.data.contenido
            : [],
      };
      dispatch(destacadosTecData(params));
    });
  }

  findDestacadosTec() {
    const { dispatch } = this.props;
    HomeRest.findDestacadosTec().then((destacados) => {
      const params = {
        configuracion:
          destacados !== null && destacados.data !== null
            ? destacados.data.configuracion
            : {},
        destacados:
          destacados !== null && destacados.data !== null
            ? destacados.data.contenido
            : [],
      };
      dispatch(destacadosTecData(params));
    });
  }

  findMatch() {
    const { dispatch } = this.props;
    const params = {
      verResultados: false,
    };
    dispatch(matchData(params));

    const data = {
      region: sessionStorage.getItem(FILTRO_REGION),
    };
    MatchService.obtenerMatchFiltro(data).then(function (response) {
      const params = {
        configuracion: {},
        destacados: response.data.empresas,
        filtros: response.data.categorias,
        rating: response.data.rating,
      };
      dispatch(matchData(params));
    });
  }

  render() {
    const { loginProps } = this.props; 
    const { labelMap } = this.props;
    const { mensajesProps } = this.props;
    const { switchRegion } = this.state;
    let link = labelMap.LINK || {};
    let cantidadMensajes = isNaN(parseInt(mensajesProps)) ? 0 : mensajesProps;
    // console.log(this.state.collapseID)

    return (
      <React.Fragment>
        <Helmet>
          <title>Conecta Turismo - Sernatur</title>
          <meta name="description" content="Conecta Turismo" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className="header d-none d-md-block">
          <MDBRow>
            <MDBCol className="col-3 col-xl-2">
              <Link to="/">
                {" "}
                <img
                  className="logoConectaTurismo"
                  width={180}
                  height={114}
                  alt="Sernatur"
                  src={link.LOGO_HEADER}
                />
              </Link>
            </MDBCol>

            <MDBCol className="col-md-9 col-xl-10 headerContainer">
              {Object.keys(loginProps).length !== 0 ? (
                <MDBRow className="usuarioSesion avatarNombre pt-1">
                  {/* Paolo Inicio Filtro Region */}
                  <MDBCol>
                    <span className="descripcion-match">Filtro Región</span>
                    <MDBSwitch
                      labelLeft=""
                      labelRight=""
                      checked={switchRegion}
                      onChange={this.handleSwitchRegion}
                    />
                  </MDBCol>
                  {/* Paolo fin Filtro Region  */}

                  {/* Paolo Inicio notificaciones */}
                  <MDBCol className="pt-3 text-capitalize notificacion">
                    <Link to={MiMensajeria}>
                      <ul className="dots">
                        <li>
                          <span className="glyphicon glyphicon-user">
                            <FontAwesomeIcon icon={["far", "comments"]} />
                            {/* <mark>{sessionStorage.getItem(MAIL_PENDIENTE)}</mark> */}
                            <mark>{cantidadMensajes}</mark>
                          </span>
                        </li>
                      </ul>
                    </Link>
                  </MDBCol>
                  {/* Paolo fin notificaciones  */}

                  <MDBCol>
                    <Link to={MiPerfil}>
                      <AvatarImpl
                        icono=""
                        clase="rounded-circle shadow usuarioSesion fotoUsuarioEnSesion"
                      ></AvatarImpl>
                    </Link>
                  </MDBCol>
                  <MDBCol className="pt-3 text-capitalize">
                    <MDBNavbar expand="sm">
                      <MDBCollapse
                        id="navbarCollapse3"
                        isOpen={this.state.isOpen}
                        navbar
                      >
                        <MDBNavbarNav right>
                          <MDBNavItem>
                            <MDBDropdown>
                              <MDBDropdownToggle nav caret>
                                <span className="mr-2">
                                  {sessionStorage.getItem(AUTH_NOMBRE)}
                                </span>
                              </MDBDropdownToggle>
                              <MDBDropdownMenu>
                                <MDBDropdownItem href="">
                                  <Link to={MiPerfil}>
                                    <FontAwesomeIcon
                                      icon={["far", "user"]}
                                      className="mr-2"
                                    ></FontAwesomeIcon>
                                    Mi Perfil
                                  </Link>
                                </MDBDropdownItem>
                                <MDBDropdownItem href="">
                                  <Link to={MiEmpresa}>
                                    <FontAwesomeIcon
                                      icon={["far", "building"]}
                                      className="mr-2"
                                    ></FontAwesomeIcon>
                                    Mi Empresa
                                  </Link>
                                </MDBDropdownItem>
                                <MDBDropdownItem href="">
                                  <Link to={MisMatch}>
                                    <FontAwesomeIcon
                                      icon={["fas", "link"]}
                                      transform={{ rotate: 45 }}
                                      className="mr-2"
                                    ></FontAwesomeIcon>
                                    Mis Match
                                  </Link>
                                </MDBDropdownItem>
                                {/* <MDBDropdownItem href="" >
                                                                        <Link to={Favoritos} >
                                                                            <FontAwesomeIcon icon={["far", "heart"]} className="mr-2" ></FontAwesomeIcon>Favoritos
                                                                        </Link>
                                                                    </MDBDropdownItem> */}
                                <MDBDropdownItem href="">
                                  <Link to={MiMensajeria}>
                                    <FontAwesomeIcon
                                      icon={["far", "comments"]}
                                      className="mr-2"
                                    ></FontAwesomeIcon>
                                    Mensajes
                                  </Link>
                                </MDBDropdownItem>
                                <hr className="my-0" />
                                <MDBDropdownItem
                                  href=""
                                  onClick={this.cerrarSesion}
                                  className="text-center"
                                >
                                  <FontAwesomeIcon
                                    icon={["far", "share-square"]}
                                    className="mr-2"
                                  ></FontAwesomeIcon>
                                  Cerrar Sesión
                                </MDBDropdownItem>
                              </MDBDropdownMenu>
                            </MDBDropdown>
                          </MDBNavItem>
                        </MDBNavbarNav>
                      </MDBCollapse>
                    </MDBNavbar>
                  </MDBCol>
                </MDBRow>
              ) : (
                <MDBBtn
                  className="btn btn-sm usuarioSesion ingreso"
                  color="primary"
                  rounded
                  onClick={this.modalLogin}
                >
                  <LabelMapper label="HEADER.BUTTON1"></LabelMapper>
                </MDBBtn>
              )}
            </MDBCol>
          </MDBRow>
        </div>

        <div className="header p-0 d-md-none d-lg-none d-xl-none">
          <MDBNavbar light>
            <MDBContainer>
              <MDBNavbarBrand>
                <Link to="/">
                  {" "}
                  <img
                    className="logoMobileConectaTurismo"
                    width={180}
                    height={114}
                    alt="Sernatur"
                    src={link.LOGO_HEADER}
                  />
                </Link>
              </MDBNavbarBrand>
              {Object.keys(loginProps).length !== 0 ? (
                <React.Fragment>
                  {/* Paolo Inicio notificaciones */}
                  <MDBCol className="pt-3 text-capitalize notificacion">
                    <Link to={MiMensajeria}>
                      <ul className="dots">
                        <li>
                          <span className="glyphicon glyphicon-user">
                            <FontAwesomeIcon icon={["far", "comments"]} />
                            <mark>{cantidadMensajes}</mark>
                          </span>
                        </li>
                      </ul>
                    </Link>
                  </MDBCol>
                  {/* Paolo fin notificaciones  */}

                  <MDBNavbarToggler
                    onClick={this.toggleCollapse("navbarCollapse1")}
                  />
                  <MDBCollapse
                    id="navbarCollapse1"
                    isOpen={this.state.collapseID}
                    navbar
                    className="py-2 listaMenuMobile"
                  >
                    <NavbarNav right>
                      <MDBNavItem>
                        <MDBRow>
                          <MDBCol className="pl-1 text-dark">
                            <span>Filtro Región</span>
                          </MDBCol>
                          <MDBCol className="float-right">
                            <MDBSwitch
                              className="float-right"
                              labelLeft=""
                              labelRight=""
                              checked={switchRegion}
                              onChange={this.handleSwitchRegion}
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBNavItem>
                      <MDBNavItem onClick={this.toggleCollapse("")}>
                        <MDBNavLink to={MiPerfil}>
                          <FontAwesomeIcon
                            icon={["far", "user"]}
                            className="mr-2"
                          ></FontAwesomeIcon>
                          Mi Perfil
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem onClick={this.toggleCollapse("")}>
                        <MDBNavLink to={MiEmpresa}>
                          <FontAwesomeIcon
                            icon={["far", "building"]}
                            className="mr-2"
                          ></FontAwesomeIcon>
                          Mi Empresa
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem onClick={this.toggleCollapse("")}>
                        <MDBNavLink to={MisMatch}>
                          <FontAwesomeIcon
                            icon={["fas", "link"]}
                            transform={{ rotate: 45 }}
                            className="mr-2"
                          ></FontAwesomeIcon>
                          Mis Match
                        </MDBNavLink>
                      </MDBNavItem>
                      {/* <MDBNavItem onClick={this.toggleCollapse('')} >
                                                    <MDBNavLink to={Favoritos}><FontAwesomeIcon icon={["far", "heart"]} className="mr-2" ></FontAwesomeIcon>Favoritos</MDBNavLink>
                                                </MDBNavItem> */}
                      <MDBNavItem onClick={this.toggleCollapse("")}>
                        <MDBNavLink to={MiMensajeria}>
                          <FontAwesomeIcon
                            icon={["far", "comments"]}
                            className="mr-2"
                          ></FontAwesomeIcon>
                          Mensajes
                        </MDBNavLink>
                      </MDBNavItem>
                      <hr className="my-0" />
                      <MDBNavItem>
                        <MDBNavLink to="" onClick={this.cerrarSesion}>
                          <FontAwesomeIcon
                            icon={["far", "share-square"]}
                            className="mr-2"
                          ></FontAwesomeIcon>
                          Cerrar Sesión
                        </MDBNavLink>
                      </MDBNavItem>
                    </NavbarNav>
                  </MDBCollapse>
                </React.Fragment>
              ) : (
                <MDBBtn
                  className="btn btn-sm usuarioSesion ingreso btn-ingresar-movil"
                  color="primary"
                  rounded
                  onClick={this.modalLogin}
                >
                  <LabelMapper label="HEADER.BUTTON1"></LabelMapper>
                </MDBBtn>
              )}
            </MDBContainer>
          </MDBNavbar>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(Header);
