import React, { Component } from 'react';
import HomeRest from 'commons/services/HomeRest';

import CarouselImpl from 'commons/components/carousel/CarouselImpl';
import { MDBRow } from 'mdbreact';

class CarouselHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagen: {},
    };
  }

  componentDidMount() {
    this.getCarouselData();
  }

  getCarouselData() {
    const self = this;
    HomeRest.findCarrusel().then((imagenes) => {
      self.setState({
        imagen: imagenes.data,
      });
    });
  }

  render() {
    const { imagen } = this.state;
    return (
      <MDBRow>
        <CarouselImpl imagen={imagen} />
      </MDBRow>
    );
  }
}

export default CarouselHome;
