import React, { Component } from 'react';
import HomeRest from 'commons/services/HomeRest';
import DestacadosImplVideo from 'commons/components/destacados/DestacadosImplVideo';
import { connect } from 'react-redux';
import { destacadosTecData } from 'commons/redux/actions/destacadosAction';

class DestacadosProviderVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        destacados: [],
      },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(destacadosTecData(null));
    
    this.findDestacadosTec();
  }

  findDestacadosTec(){
     const self = this;
     HomeRest.findVideos().then((resp) => {
       self.setState({
         params: {
           destacados: (resp !== null && resp.data !== null) ? resp.data : [],
         },
       });
     });
  }

  render() {
    const { params } = this.state;
    return (
      <DestacadosImplVideo params={params} />
    );
  }
}

const mapStateToProps = (state) => ({
  destacadosTecProps: state.destacadosTecProps
});

export default connect(
  mapStateToProps,
)(DestacadosProviderVideo);
