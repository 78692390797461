import React from 'react';
import {
  Modal, ModalBody, ModalFooter,
} from '@ns-react/modal';
import { MDBCol, MDBRow, MDBContainer, MDBBtn } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ModalFavoritos extends Modal {

  cerrar() {
    super.closeModal();
  }

  render() {
    return (
      <Modal size="small">
        <ModalBody>
          <div className="modal-body" >
            <MDBContainer>
              <div className="row mb-3">
                <div className="col d-flex justify-content-center">
                  <FontAwesomeIcon icon={this.props.flagFavorito ? ["fas", "heart"] : ["far", "heart"]}
                    color={this.props.flagFavorito ? '#4A91E3' : '#999999' }
                    size="4x"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col" >
                  <h4 className={this.props.flagFavorito ? 
                                'modal-favorito-titulo-agregado'
                                : 'modal-favorito-titulo-quitado' } >
                    {
                      this.props.flagFavorito ? 
                                'Agregado correctamente'
                                : 'Quitado correctamente'
                    }
                  </h4>
                </div>
              </div>
            </MDBContainer>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="modal-footer float-none" >
            <MDBContainer>
              <MDBRow>
                <MDBCol className="d-flex justify-content-center">
                  <MDBBtn rounded className="btn-primario-modal-sm" onClick={this.cerrar}  >
                    Cerrar
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </ModalFooter>
      </Modal >
    );
  }
}

export default ModalFavoritos;
