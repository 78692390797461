import BaseService from './base/BaseService';

/**
 * @author Sinecio Bermúdez Jacque
 * @description Servicio de Ejemplo que hereda métodos
 * de la clase base `BaseService` y expone métodos relacionados
 * al recurso `/mail`.
 */
export default class MailService extends BaseService {

  

    static obtenerPorBandejaEntrada() {
      return super.get('/mail/mailUser');
    }

    static obtenerPorHiloMail(data) {
      return super.get('/mail/mailFromMail',data);
    }

    static guardarMensaje(data) {
      return super.post('/mail/mail', null, data);
    }

    static countPending(data) {
    return super.get('/mail/countPending', null, data);
    }

}
