import React, { Component } from 'react';
import MatchService from 'commons/services/MatchService';
import MisMatch from 'commons/components/match/MisMatch';
import LoginService from 'commons/services/LoginService';
import { connect } from 'react-redux';
import { matchData } from 'commons/redux/actions/matchAction';

class MisMatchProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {},
      flagVerResultados: false,
    };
  }

  componentDidMount() {
    this.getCarouselData();
    window.scrollTo(0, 0);
  }

  getCarouselData = () => {
    const self = this;
    const { dispatch } = this.props;

    this.setState({
      params: {
        configuracion: {},
        destacados: [],
      },
      flagVerResultados: false,
    });

    dispatch(matchData(null));

    LoginService.resfrescarTicket().then(function () {
      MatchService.hacerMatch().then(function (response) {
        self.setState({
          params: {
            configuracion: {},
            destacados: response.data.empresas,
            filtros: response.data.categorias,
            rating: response.data.rating
          },
          flagVerResultados: true,
        });
      })
    });
  }


  render() {
    const { params, flagVerResultados } = this.state;
    return (
      <MisMatch params={params} verResultados={flagVerResultados} actualizar={this.getCarouselData}  />
    );
  }
}

const mapStateToProps = (state) => ({
  matchProps: state.matchProps
});

export default connect(
  mapStateToProps,
)(MisMatchProvider);
