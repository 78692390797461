import React, { Component } from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import { connect } from 'react-redux';
import LabelMapper from 'commons/components/LabelMapper';

/**
 * @author Sinecio Bermúdez Jacque
 * @description Componente que define el footer de la aplicación
 */
class Footer extends Component {

  render() {
    let { labelMap } = this.props;
    let link = labelMap.LINK || {};

    return (
      <React.Fragment>
        <section className="container-fluid d-none d-md-block">
          <footer className="footer" >
            <section className="container-fluid">
              <MDBRow>
              <MDBCol md="4" className="d-flex justify-content-start">
                  <div>
                     <div className="text-white"><strong>ENLACES DE INTERES:</strong></div><br/>

                     <a target="_blank" rel="noopener noreferrer" href={link.TERMINOS} >
                     <div className="sub_text"><strong> <LabelMapper  className="text-white" label="REGISTRO.TERMINOSYCONDICIONES" /> </strong></div>
                    </a><br/>
                    <a target="_blank" rel="noopener noreferrer" href="http://www.subturismo.gob.cl/" >
                     <div className="sub_text"><span className="text-white" />Subsecretaría de Turismo</div>
                    </a><br/>
                    <a target="_blank" rel="noopener noreferrer" href={"https://www.sernatur.cl/"} >
                     <div className="sub_text"><span className="text-white"/>Sernatur.cl</div>
                    </a><br/>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.tdturismo.cl/" >
                     <div className="sub_text"><span className="text-white" />Transformación Digital en Turismo</div>
                    </a>
                 </div>
    
                </MDBCol>
                <MDBCol md="4" className="d-flex justify-content-center">
                  <div>
                    <img alt="Sernatur" src={link.LOGO_FOOTER} width="180" />
                  </div>
                </MDBCol>
                <MDBCol md="4" className="d-flex justify-content-end">
                  <div>
                    <div className="text-white"><strong>ENCUENTRANOS EN:</strong></div>
                    <a target="_blank" rel="noopener noreferrer" href={link.FACEBOOK} className="iconsRrss"><img src="imagen/Facebook/circle.png" alt="" /></a>
                    <a target="_blank" rel="noopener noreferrer" href={link.INSTAGRAM} className="iconsRrss"><img src="imagen/Instagram/circle.png" alt="" /></a>
                    
                    <a target="_blank" rel="noopener noreferrer" href={link.TWITTER} className="iconsRrss"><img src="imagen/Twitter/circle.png" alt="" /></a>
                  </div>
                </MDBCol>
              </MDBRow>
            </section>
          </footer>
        </section>
        <section className="container-fluid mt-5 d-md-none d-lg-none d-xl-none">
          <footer className="footer" >
            <section className="container-fluid">
              <MDBRow>
                <MDBCol md="4" className="d-flex justify-content-center">
                  <div>
                    <div className="text-white"><strong>Visítanos</strong></div>
                    <a target="_blank" rel="noopener noreferrer" href={link.INSTAGRAM} className="iconsRrss"><img src="imagen/Instagram/White.png" alt="" /></a>
                    <a target="_blank" rel="noopener noreferrer" href={link.FACEBOOK} className="iconsRrss"><img src="imagen/Facebook/White.png" alt="" /></a>
                    <a target="_blank" rel="noopener noreferrer" href={link.TWITTER} className="iconsRrss"><img src="imagen/Twitter/White.png" alt="" /></a>
                  </div>
                </MDBCol>
                <MDBCol md="4" className="d-flex justify-content-center" >
                </MDBCol>
                <MDBCol md="4" className="d-flex justify-content-center">
                  <div>
                    <img alt="Sernatur" src={link.LOGO_FOOTER} width="180" />
                  </div>
                </MDBCol>
              </MDBRow>
            </section>
          </footer>
        </section>
      </React.Fragment>
    );
  }
}

/**
 * @description establece las props para el componente LabelMapperProvider
 * de acuerdo al `state`
 */
const mapStateToProps = (state) => ({
  labelMap: state.labelMap,
});

export default connect(
  mapStateToProps,
)(Footer);