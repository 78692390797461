/**
 * @author Sinecio Bermúdez Jacque
 * @description Función que permite normalizar una url.
 * Normaliza //, /? /#, :/
 * @param {String} url string que respresenta una url
 */
export default function normalizeUrl(url) {
  return url
    .replace(/[/]+/g, '/')
    .replace(/\/\?/g, '?')
    .replace(/\/#/g, '#')
    .replace(/:\//g, '://');
}
