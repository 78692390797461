import React from 'react';
import { Modal, ModalHeader, ModalBody } from '@ns-react/modal';
import LabelMapper from 'commons/components/LabelMapper';
import { connect } from 'react-redux';

import IngresoUsuario from 'commons/components/sesion/registroUsuarios/IngresoUsuario';
import RegistroUsuario from 'commons/components/sesion/registroUsuarios/RegistroUsuario';

import TabButtons from 'commons/components/TabButtons';
import CloseButton from 'commons/components/CloseButton';

class LoginModal extends Modal {
  constructor(props) {
    super(props);
    this.state = {
      activo: 'login',
    };
    this.cancelar = this.cancelar.bind(this);
    this.tabLogin = this.tabLogin.bind(this);
    this.tabRegistro = this.tabRegistro.bind(this);
  }

  cancelar() {
    this.crear = false;
    super.closeModal();
  }

  tabLogin() {
    let { activo } = this.state;
    activo = 'login';
    this.setState({ activo });
  }

  tabRegistro() {
    let { activo } = this.state;
    activo = 'registro';
    this.setState({ activo });
  }

  crearEmpresa = () => {
    this.crear = true;
    super.closeModal();
  }

  componentWillUnmount() {
    if (this.crear) {
      this.props.crearEmpresa();
    }
  }

  render() {
    let { activo } = this.state;
    return (
      <Modal size="small" className="login-modal">
        <ModalHeader>
          <TabButtons>
            <button className={activo === 'login' ? 'active' : 'no-active'} onClick={this.tabLogin}>
              <LabelMapper label="COMUN.LOGIN" />
            </button>
            <button className={activo === 'registro' ? 'active' : 'no-active'} onClick={this.tabRegistro}>
              <LabelMapper label="COMUN.REGISTRO" />
            </button>
          </TabButtons>
          <CloseButton onClick={this.cancelar}></CloseButton>
        </ModalHeader>
        <ModalBody>
          {
            this.state.activo === 'login' ?
              <IngresoUsuario cancelar={this.cancelar} crearEmpresa={this.crearEmpresa} ></IngresoUsuario>
              :
              <RegistroUsuario cancelar={this.cancelar}></RegistroUsuario>
          }
        </ModalBody>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  labelMap: state.labelMap,
  loginProps: state.loginProps,
});

export default connect(
  mapStateToProps,
)(LoginModal);