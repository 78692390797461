import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./main/serviceWorker";
import App from "./main/App";

import { Provider } from "react-redux";
import store from "./commons/redux/store";
import Favicon from "react-favicon";
/**
 * configuración fontawesome
 */
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";

/**
 * configuración fontawesome react
 */
import "bootstrap/scss/bootstrap.scss";
import "mdbreact/dist/scss/mdb.scss";
import "./styles/main.scss";

library.add(fas, fab, far);

/**
 * configuración Tag Manager
 */
const tagManagerArgs = {
  gtmId: "GTM-KXVL33B",
  dataLayerName: "Sernatur",
};

TagManager.initialize(tagManagerArgs);

/**
 * configuración Hotjar
 */
hotjar.initialize("1601207", "6");

ReactDOM.render(
  <>
    <Favicon url="/resources/sernatur/img/home/favicon.png" />
    <Provider store={store}>
      <App />
    </Provider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
