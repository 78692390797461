import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import { MDBRow } from "mdbreact";
import { createNewID } from "commons/util";

/**
 * se modifica componenete react-responsive-carousel clase Carousel.js
 * liea 520 'if (!_this5.state.autoPlay || _react.Children.count(_this5.props.children) <= 1) ' a
 * '(!_this5.state.autoPlay )' y linea 153
 * 'if (this.state.autoPlay && _react.Children.count(this.props.children) > 1)' a
 * 'if (this.state.autoPlay)'*
 */
class CarouselImpl extends Component {
  constructor(props) {
    super(props);
    this.handleButton = this.handleButton.bind(this);
  }

  handleButton(link) {
    window.open(link, "_blank");
  }

  pintaLegend(o) {
    if (o.titulo == null) {
      o.titulo = "";
    }
    if (o.labelLink == null) {
      o.labelLink = "";
    }
    if (o.link == null) {
      o.link = "";
    }
    if (o.titulo.length > 0) {
      return (
        <MDBRow className="legend">
          <MDBRow>
            <h1>{o.titulo.length > 0 ? o.titulo : ""}</h1>
          </MDBRow>
          <MDBRow>
            {o.link.length > 0 ? (
              <button type="button" className="descripcion">
                {o.labelLink}
              </button>
            ) : (
              ""
            )}
          </MDBRow>
        </MDBRow>
      );
    }
    return null;
  }

  render() {
    const { imagen } = this.props;
    if (!imagen.configuracion) return null;
    return (
      <>
        <Carousel
          className="carousel-custom container-fluid d-none d-md-block"
          interval={imagen.configuracion.delay}
          stopOnHover
          autoPlay
          infiniteLoop
          showControls={false}
          showStatus={false}
          showThumbs={false}
        >
          {imagen.contenido.map((o, i) =>
            o.url.includes("youtube") === true ? (
              <div
                key={createNewID()}
                className="row d-flex justify-content-center"
              >
                <div className="col-md-6">
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      title={i}
                      src={o.url}
                      className="embed-responsive-item"
                      allowfullscreen
                    >
                      {this.pintaLegend(o)}
                    </iframe>
                  </div>
                </div>
              </div>
            ) : (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={o.link}
                key={createNewID()}
              >
                <div>
                  <img alt="Sernatur" className="imagen-carrusel" src={o.url} />
                  {this.pintaLegend(o)}
                </div>
              </a>
            )
          )}
        </Carousel>
        <Carousel
          className="carousel-custom container-fluid d-md-none d-lg-none d-xl-none"
          interval={imagen.configuracion.delay}
          stopOnHover
          autoPlay
          infiniteLoop
          showControls={false}
          showStatus={false}
          showThumbs={false}
        >
          <div key={createNewID()}>
            <img
              alt="Sernatur"
              className="imagen-carrusel"
              src="/imagen/slider/small/slider_01.jpg"
            />
          </div>
          <div key={createNewID()}>
            <img
              alt="Sernatur"
              className="imagen-carrusel"
              src="/imagen/slider/small/slider_02.jpg"
            />
          </div>
          <div key={createNewID()}>
            <img
              alt="Sernatur"
              className="imagen-carrusel"
              src="/imagen/slider/small/slider_03.jpg"
            />
          </div>
          {/* <div key={createNewID()}>
            <img alt="Sernatur" className="imagen-carrusel" src="/imagen/slider/small/slider_04.jpg" />
          </div> */}
        </Carousel>
      </>
    );
  }
}

export default CarouselImpl;
