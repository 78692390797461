import React, { Component } from 'react';
import HomeRest from 'commons/services/HomeRest';
import MisMegusta from 'commons/components/megusta/MisMegusta';
import LoginService from 'commons/services/LoginService';

class MiMegustaProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {

      },
    };
  }


  componentDidMount() {
    this.getCarouselData();
  }

  getCarouselData() {
    const self = this;
    LoginService.resfrescarTicket().then(function () {
      HomeRest.findDestacadosTec().then((destacados) => {
        self.setState({
          params: {
            configuracion: destacados.data.configuracion,
            destacados: destacados.data.contenido,
          },
        });
      });
    });
  }


  render() {
    const { params } = this.state;
    return (
      <MisMegusta params={params} />
    );
  }
}

export default MiMegustaProvider;
