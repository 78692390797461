import React from "react";
import { MDBRow, MDBInput } from "mdbreact";
import LabelMapper from "commons/components/LabelMapper";

export const TypeCompanyForm = ({ onChange, typeSelected }) => {
  function handleTipoEmpresa(e) {
    onChange(e.target.value);
  }

  return (
    <>
      <MDBRow>
        <h5 className="titulo-dashboard py-3">Información de la Empresa</h5>
      </MDBRow>
      <MDBRow>
        <span className="campos mt-2">
          <LabelMapper label="REGISTROEMPRESA.TIPO" />
          <strong className="lbl-obligatorio pl-1">*</strong>
        </span>
      </MDBRow>
      <MDBRow>
        <MDBInput
          onChange={handleTipoEmpresa}
          checked={typeSelected === "TUR"}
          label="Turismo"
          value="TUR"
          type="radio"
          id="radio1"
          className="form-control"
        />
        <MDBInput
          onChange={handleTipoEmpresa}
          checked={typeSelected === "TEC"}
          value="TEC"
          label="Tecnología"
          type="radio"
          id="radio2"
          className="form-control"
        />
      </MDBRow>
    </>
  );
};
