import React from 'react';
import {
  Modal, ModalBody, ModalFooter,
} from '@ns-react/modal';
import { MDBCol, MDBRow, MDBContainer, MDBBtn } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ModalConfirmacionSolicitud extends Modal {

  cerrar() {
    super.closeModal();
  }

  render() {
    return (
      <Modal size="medium">
        <ModalBody>
          <div className="modal-body" >
            <MDBContainer>
              <div className="row mb-3">
                <div className="col d-flex justify-content-center">
                  <FontAwesomeIcon icon={["far", "check-circle"]}
                    color="#4A91E3"
                    size="4x"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col" >
                  <h4 className="text-center modal-confirmacion-titulo" >¡Tu mensaje ha sido enviado correctamente!</h4>
                </div>
              </div>
              <div className="row" >
                <div className="col">
                  <p className="text-center modal-confirmacion-descripcion" >
                    Nos pondremos en contacto contigo lo antes posible para facilitar la información solicitada.
                  </p>
                </div>
              </div>
            </MDBContainer>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="modal-footer float-none" >
            <MDBContainer>
              <MDBRow>
                <MDBCol className="d-flex justify-content-center">
                  <MDBBtn rounded className="btn-primario-modal-sm" onClick={this.cerrar}  >
                    Cerrar
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </ModalFooter>
      </Modal >
    );
  }
}

export default ModalConfirmacionSolicitud;
