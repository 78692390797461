export const GUARDADO_EXITOSO = 'Operación realizada con éxito';
export const MSJE_ERROR = 'La plataforma no ha podido realizar la acción debido a un problema en sistema. Lo solucionaremos a la brevedad.';
export const EMPTY_ERROR = 'Este campo no puede quedar vacio';

let VAR_MAX_DESC_LARGA_EMP = 600;
let VAR_MAX_DESC_BREVE_EMP = 150;
let VAR_MAX_NOMBRE_EMP = 30;
let VAR_MAX_DIRECC_EMP = 50;
let VAR_MAX_TELEFO_EMP = 30;
let VAR_MAX_SITIOW_EMP = 200;
let VAR_MIN_DESC = 20;

export const MAX_DESC_LARGA_EMP = VAR_MAX_DESC_LARGA_EMP;
export const MAX_DESC_BREVE_EMP = VAR_MAX_DESC_BREVE_EMP;
export const MAX_NOMBRE_EMP = VAR_MAX_NOMBRE_EMP;
export const MAX_DIRECC_EMP = VAR_MAX_DIRECC_EMP;
export const MAX_TELEFO_EMP = VAR_MAX_TELEFO_EMP;
export const MAX_SITIOW_EMP = VAR_MAX_SITIOW_EMP;

export const MSG_NOMBRE_EMP = 'El nombre de la empresa no puede superar los ' + VAR_MAX_NOMBRE_EMP +' caracteres'
export const MSG_DESC_LARGA_EMP = 'La descripcion de la empresa no puede superar los ' + VAR_MAX_DESC_LARGA_EMP + ' caracteres';
export const MSG_DESC_MIN_LARGA_EMP = 'La descripcion de la empresa no puede ser inferior a ' + VAR_MIN_DESC + ' caracteres';
export const MSG_DESC_BREVE_EMP = 'La descripcion breve de la empresa no puede superar los ' + VAR_MAX_DESC_BREVE_EMP + ' caracteres';
export const MSG_DIRECC_EMP = 'La dirección de la empresa no puede superar los ' + VAR_MAX_DIRECC_EMP + ' caracteres';
export const MSG_TELEFO_EMP = 'El Teléfono de contacto no puede superar los '+ VAR_MAX_TELEFO_EMP + ' caracteres';
export const MSG_EMAIL_EMP = 'Email no tiene el formato correcto';
export const MSG_SITIOWEB_EMP = 'Sitio web no tiene el formato correcto, debe iniciar con http:// o https://';
export const MSG_SITIOW_EMP = 'El Sitio web no puede superar los '+ VAR_MAX_SITIOW_EMP + ' caracteres';
export const MSG_EXTENSION_IMG = 'Extensión de archivo no es permitido.';
export const MSG_TAMANO_IMG = 'Tamaño del archivo supera el limite permitido.';
export const MAX_IMG = 'Cantidad máxima de imagenes no puede superar a ';