import React, { Component } from 'react';
import { MDBBtn, MDBContainer, MDBCol, MDBRow } from 'mdbreact';
import { modal, alertV2 } from 'commons/util';
import ModalMatch from './ModalMatch';
import LoginModal from 'commons/components/sesion/LoginModal';
import { AUTH_KEY, FLAG_EMPRESA } from 'commons/constants';
import { MisMatch } from 'commons/constants';
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    labelMap: state.labelMap,
  };
};

class DescubreMatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagen: {},
    };
  }

  match = () => {
    let token = sessionStorage.getItem(AUTH_KEY);
    let flagEmpresa = sessionStorage.getItem(FLAG_EMPRESA);
    if (token !== null && token !== undefined) {
      if (flagEmpresa === "true") {
        modal(ModalMatch, {
          verMatch: this.verMatch
        });
      } else {
        alertV2('Aviso', 'Registra tu Empresa y podrás utilizar MATCH');
      }
    } else {
      modal(LoginModal);
    }
  }

  verMatch = () => {
    this.props.history.push(MisMatch);
  }

  render() {
    const { labelMap } = this.props;
    let link = labelMap.LINK || {};

    return (
      <div className="descubreMatch" >
        <div className="row" >
          <div className="col-md-12 text-center" >
            <img alt='Infografia' src={link.INFOGRAFIA} className="img-fluid"  ></img>
          </div>
          <MDBContainer>
            <MDBRow>
              <MDBCol className="text-center" >
                <MDBBtn rounded className="btn-descubre-match" onClick={this.match} >Comenzar</MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
    );
  }
}

//export default DescubreMatch;
export default connect(mapStateToProps)(DescubreMatch);
