import React, { Component } from 'react';
import Moment from 'react-moment';
import AvatarMensaje from 'commons/components/avatar/AvatarMensaje';
import { MDBCol } from 'mdbreact';

export default class MensajeriaBandeja extends Component {

    handleClick = id => e => {
        const { handleClick } = this.props
        handleClick(id)
    }

    render() {

        const { notificaciones } = this.props
        
        return (

            <div className="col-md-12 listado-mensajes pt-2" >
                <div className="row mensajeBandeja py-1" >
                    <div className="col-md-10 pl-4" >
                        {
                            notificaciones === undefined || notificaciones.length <= 0 ?
                                <strong className="titulo-Bandeja" >Sin mensajes</strong>
                                :
                                <strong className="titulo-Bandeja" >Mensajes</strong>
                        }
                        <p></p>
                    </div>
                </div>

                {
                    notificaciones === undefined || notificaciones.length <= 0 ?
                        ""
                        :
                        notificaciones.map((notificacion, index) => (
                            <div id={notificacion.id} className="row mensaje py-1" key={index}
                                onClick={this.handleClick(notificacion.id)}>
                                <div className="col-xs-2" >
                                    <AvatarMensaje params={notificacion.imgUser} icono="" clase="rounded-circle shadow usuarioSesion fotoUsuarioEnSesion avatarBandeja" ></AvatarMensaje>
                                </div>

                                <MDBCol className="pl-2">
                                    <div defaultValue={notificacion.id} className="row container-md">
                                        <MDBCol size="10" md="8" className="pl-0 pr-0">
                                            <strong className="titulo-msje" >{notificacion.empresaNombre}</strong>
                                        </MDBCol>
                                        <MDBCol size="2" md="4" className="pl-0 pr-0 text-center">
                                                {
                                                    Boolean(notificacion.pendientesLeer) > 0 ?
                                                    <mark className="pendiente pl-1 pr-1" id={notificacion.id +"-pendientes"}>
                                                        {notificacion.pendientesLeer}
                                                    </mark>
                                                    :
                                                    <mark className="pendiente pl-1 pr-1 d-none" id={notificacion.id +"-pendientes"}>
                                                        {notificacion.pendientesLeer}
                                                    </mark>
                                                }
                                        </MDBCol>
                                    </div>
                                    <p className="detalle" id={notificacion.id + "-detalle-mensaje"}>{notificacion.mensaje}</p>
                                    <p className="fecha" id={notificacion.id + "-fecha-mensaje"}><Moment format="DD/MM/YYYY HH:mm:ss">{notificacion.fecha}</Moment></p>
                                </MDBCol>
                            </div>
                        ))
                }


            </div>
        )

    }

}