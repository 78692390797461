import {
    AVATAR,
} from '../actionTypes';

/**
 * @description define el estado para el componente `avatar` de acuerdo
 * a la acción notificada.
 */
export default (state = {}, action) => {
    switch (action.type) {
        case AVATAR:
            {
                /**
                 * no hay condiciones, sólo informa el nuevo valor
                 * desde `action.payload.map`
                 */
                return action.payload.data;
            }

        default:
            return state;
    }
};