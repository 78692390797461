import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from '@ns-react/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default class ModalConfirm extends Modal {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
  }

  componentWillUnmount() {
    if (this.esAceptar) {
      if (typeof this.props.onClose === 'function') this.props.onClose();
    } else if (typeof this.props.onDismiss === 'function') this.props.onDismiss();
  }

  close(esAceptar) {
    this.esAceptar = esAceptar;
    super.closeAlert();
  }

  render() {
    return (
      <Modal>
        <ModalHeader>
          <FontAwesomeIcon icon="question-circle" />
          <span>Confirme</span>
        </ModalHeader>
        <ModalBody>
          {this.props.message}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-primary" onClick={() => this.close(true)}>Aceptar</button>
          <button type="button" className="btn btn-secondary" onClick={() => this.close(false)}>Cancelar</button>
        </ModalFooter>
      </Modal>
    );
  }
}
