import React, { Component } from 'react';
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBRow, MDBCol} from 'mdbreact';

class CarouselDetalleItemImpl extends Component {


  obtenerImagenesAgrupadas() {
    const grupos = [];
    const { imagen } = this.props;
    const contenido = [].concat(imagen.contenido);
    const esResponsive = window.innerWidth <= 766;
    if (!contenido || contenido.length <= 0) return grupos;
    while (contenido[0]) {
      grupos.push(contenido.splice(0, esResponsive ? 1 : 3));
    }
    return grupos;
  }

  render() {
    const grupos = this.obtenerImagenesAgrupadas();
    const { imagen } = this.props;

    return (
          <MDBCarousel
            className="carousel-custom-detalle container-fluid" 
            interval={imagen.configuracion.delay} 
            multiItem            
            activeItem={1}
            length={grupos.length}
            slide
            showControls={true}
            showIndicators={false}
            >
            <MDBCarouselInner>
                {
                  grupos.map((imagen, i) => (
                    <MDBCarouselItem itemId={i + 1}>
                    <MDBRow>
                      {
                        imagen.map((o) => (
                          <MDBCol md="4">
                              <div>
                                <img alt="" className="img-responsive img-detalle" src={o.url} style={{ objectFit: "cover" }} />
                              </div>
                          </MDBCol>
                        ))
                       }
                      </MDBRow>                    
                    </MDBCarouselItem>
                  ))
                }
            </MDBCarouselInner>
          </MDBCarousel>
    );
  }
}

export default CarouselDetalleItemImpl;
