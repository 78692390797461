import React from 'react';
import { Route } from 'react-router-dom';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';

/**
 * @description Define la estructura principal de la aplicación.
 * Nodos y clases principales como el header y el footer
 */
export default () => (
  <>
    <Route path="/" component={Header} />
    <section className="container-fluid">
      <Body />
    </section>
    <Route path="/" component={Footer} />
  </>
);
