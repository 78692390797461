import {
  LOGIN,
} from '../actionTypes';

/**
 * @description define el estado para el componente `login` de acuerdo
 * a la acción notificada.
 */
export default (state = {}, action) => {
  switch (action.type) {
    case LOGIN:
    {
      /**  console.log(action.payload.data);
                 * no hay condiciones, sólo informa el nuevo valor
                 * desde `action.payload.isFetching`
                 */
      return action.payload.key;
    }

    default:
      return state;
  }
};
