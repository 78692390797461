import React, { Component } from 'react';
import MailService from 'commons/services/MailService';
import moment from 'moment';
/* Componentes */
import MensajeriaBandeja from './MensajeriaBandeja'
import MensajeriaConversacion from './MensajeriaConversacion'
import { mensajesData, msgBandejaData } from 'commons/redux/actions/mensajesAction';
import { connect } from 'react-redux';

class MiMensajeria extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            hiloConversacion: [],
            idHilo: null,
            nvoMensaje: "",
            miInterval: undefined,
            intervals: []
        };
        this.listaHiloConversacion = this.listaHiloConversacion.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    removeIntervals(){

        this.state.intervals.forEach(
            function logArrayElements(element, index, array) {
                clearInterval(element);
            }
        )
    }
    
    handleClick = id => {
        this.setState({
            idHilo: id,
        })
        this.listaHiloConversacion(id)
    }   

    getBandejaData() {
        const { dispatch } = this.props;
        const self = this;    
        MailService.obtenerPorBandejaEntrada().then((notificaciones) => {
        let data = notificaciones === null ? [] : notificaciones.data;

          const  params = {
            notificaciones: data
          };
          dispatch(msgBandejaData(params));
          self.setState({ success: true });
        });
    }

    actualizaMsgTotal() {
        let { dispatch } = this.props;
        MailService.countPending().then((resp) => {
            if (resp.status === 200) {
                let cantidadMensajes = isNaN(parseInt(resp.data)) ? 0 : resp.data;
                dispatch(mensajesData(cantidadMensajes));
            }
        });    
    }

    actualizaMsgPadre(idPadre, mensajes) {

        if (mensajes !== null && mensajes.length > 0 && document.getElementById(idPadre + "-detalle-mensaje") !== null) {
            let formattedDate = moment(new Date(mensajes[mensajes.length - 1].fecha).getTime()).format("DD/MM/YYYY HH:mm:ss");
            document.getElementById(idPadre + "-detalle-mensaje").innerHTML = mensajes[mensajes.length - 1].mensaje;
            document.getElementById(idPadre + "-fecha-mensaje").innerHTML = formattedDate;
            this.getBandejaData();
            this.actualizaMsgTotal();
        }
    }

    listaHiloConversacion(id) {
        var list = document.getElementsByClassName('row mensaje py-1');
        for (var i = 0; i < list.length; i++) {
            list[i].style.backgroundColor = "#FFFFFF";
        }

        document.getElementById(id).style.backgroundColor = "#bcd7f5";

        let miInterval = this.state.miInterval;
        const self = this;
        const data = {
            idMail: id
        }

        this.removeIntervals();

        if (this.state.idHilo !== id) {
            clearInterval(miInterval);
        }

        let apiCall = (id) => {
            MailService.obtenerPorHiloMail(data).then((hiloConversacion) => {
                let data = hiloConversacion === null ? [] : hiloConversacion.data;
                if (this.state.idHilo === id) {
                    self.setState({ hiloConversacion: data });
                    this.actualizaMsgPadre(id, data);
                }
            });
        }

        apiCall(id);
        self.setState({ idHilo: id });
        miInterval = setInterval(() => {
            if (window.location.pathname.toString().includes("mensajeria")) {
                apiCall(id)
            }
        }, 1000 * 30);

        this.state.intervals.push(miInterval);

        self.setState({
            miInterval: miInterval
        });
    }

    setPrimerMsgProps(){
        if(this.props.msgBandejaProps !== null && 
            this.props.msgBandejaProps.notificaciones !== undefined &&
            this.props.msgBandejaProps.notificaciones.length > 0){
                this.listaHiloConversacion(this.props.msgBandejaProps.notificaciones[0].id);
        }
    }

    componentDidMount() {
        this.getBandejaData();
        this.setPrimerMsgProps();
    }

    shouldComponentUpdate(nextProps, nextState){

        if(!this.state.success && nextState.success){
             this.setPrimerMsgProps();
         }

        return nextState;
    }

    componentWillUnmount() {
        this.removeIntervals();
        clearInterval(this.state.miInterval);
        this.setState({  miInterval: undefined });
        this.setState({ success: false });
        this.setState({ intervals: [] });
    }

    render() {
       // const { notificaciones } = this.props.params
       let {notificaciones} = this.props.msgBandejaProps;

       if(this.props.msgBandejaProps !== null && this.props.msgBandejaProps.notificaciones !== undefined){
           notificaciones  = this.props.msgBandejaProps.notificaciones;
       }

       const { hiloConversacion, idHilo } = this.state

        return (
            <div className="mensajeria">
                <div className="row" >
                    <div className="col-md-4 listado-mensajes pt-2" >
                        <MensajeriaBandeja notificaciones={notificaciones} handleClick={this.handleClick} />  
                    </div>
                    {
                        this.state.hiloConversacion === undefined || this.state.hiloConversacion.length <= 0 ?
                            ""
                            :
                            <MensajeriaConversacion idHilo={idHilo} hiloConversacion={hiloConversacion} notificaciones={notificaciones} handleClick={this.handleClick} />
                    }

                </div>
            </div>
        );
    }
}

//export default MiMensajeria;

const mapStateToProps = (state) => ({
    msgBandejaProps: state.msgBandejaProps
  });
  
  export default connect(
    mapStateToProps,
  )(MiMensajeria);