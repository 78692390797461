import BaseService from "./base/BaseService";

export default class CompanyServices extends BaseService {
  static guardarEmpresa(param, data) {
    return super.post("/company/saveCompany", param, data);
  }
  static getEmpresa(param, data) {
    return super.get("/company/companyByUser", param, data);
  }
  static updateEmpresa(param) {
    return super.put("/company/update/{id}", { id: param.id }, param);
  }
  static getPostulacion(param, data) {
    return super.get("/company", param, data);
  }
  static guardarEmpresaArchivos(param, data) {
    return super.post("/company/saveCompanyFile", param, data);
  }
}
