import {
    LOGIN,
} from '../actionTypes';

/**
 * @description Envía al store la data `map` de los labels
 * obtenidos
 */
export const loginData = (resp) => ({
    type: LOGIN,
    payload: {
        key: resp,
        data: resp.data,
        status: resp.status,
    },
    error: resp.status > 299,
});