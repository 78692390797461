import React from 'react';
import { connect } from 'react-redux';
import { AUTH_IMAGEN_ICON } from 'commons/constants';
import { avatarData } from 'commons/redux/actions/avatarAction';
/**
 * @description establece las props para el componente LoadingIcon
 * de acuerdo al `state`
 */
const mapStateToProps = (state) => ({
  isFetchingData: state.isFetchingData  
});

/**
 * @author Falamos
 * @description Componente que agrega se encarga del control de dispatch al cargar el sitio
 */
class DispatchComponent extends React.Component {
  /**
   * @description encargado de el control de dispatch para el manejo del avatar
   */
  handleDispatchAvatar() {
    const { dispatch } = this.props;
    if (sessionStorage.getItem(AUTH_IMAGEN_ICON) != null) {
      let data = {
        avatar: sessionStorage.getItem(AUTH_IMAGEN_ICON)
      }
      dispatch(avatarData(data));
    }
  }


  render() {
    this.handleDispatchAvatar();
    return this.props.children || null;
  }
}

export default connect(
  mapStateToProps,
)(DispatchComponent);
