import React, { Component } from "react";
import Select from "react-select";
import InputText from "commons/components/InputText";
import FileUpload from "commons/components/FileUpload";
import { alertV2, success, alertObligatorio } from "commons/util";
import { formateaRut } from "commons/util";
import LabelMapper from "commons/components/LabelMapper";
import ParameterService from "commons/services/ParameterService";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import {
  AUTH_ID_USER,
  FLAG_EMPRESA,
  MAX_DESC_LARGA_EMP,
  MAX_DESC_BREVE_EMP,
  MAX_NOMBRE_EMP,
  MAX_DIRECC_EMP,
  MSG_SITIOWEB_EMP,
  MAX_SITIOW_EMP,
  MSG_NOMBRE_EMP,
  MSG_DESC_LARGA_EMP,
  MSG_DESC_BREVE_EMP,
  MSG_DIRECC_EMP,
  MSG_EMAIL_EMP,
  MSG_SITIOW_EMP,
  MAX_TELEFO_EMP,
  MSG_TELEFO_EMP,
  MSG_EXTENSION_IMG,
  MSG_TAMANO_IMG,
  MAX_IMG,
  JPEG,
  JPG,
  PNG,
} from "commons/constants";
import { parsePath, joinUrl } from "commons/util";
import { BASE_URL } from "commons/constants";
import { AUTH_KEY } from "commons/constants";
import LabelService from "commons/services/LabelService";
import LoginService from "commons/services/LoginService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validator from "validator";

class RegistroEmpresa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      nombreEmpresa: "",
      rutEmpresa: "",
      descripconEmpresa: "",
      descripconBreve: "",
      regionEmpresa: [],
      categoriaEmpresa: [],
      servicioEmpresa: [],
      subCategoriaEmpresa: [],
      inPutCategoriaEmpresa: [],
      inPutServicioEmpresa: [],
      inPutSubCategoriaEmpresa: [],
      otraCategoria: "",
      inPutRegionEmpresa: "",
      comunaEmpresa: [],
      inPutComunaEmpresa: "",
      direccionEmpresa: "",
      telefonoEmpresa: "",
      emailEmpresa: "",
      radio: "",
      sitioWebEmpresa: "",
      guardando: false,
      imagenes: [],
      mapEmpresa: {},
      mapValores: {},
      flagOtraCategoria: false,
      flagDestacada: false,
      ContadorDescripcionLarga: 600,
      ContadorDescripcionBreve: 150,
    };
    this.handleInputNombreEmpresa = this.handleInputNombreEmpresa.bind(this);
    this.handleInputRutEmpresa = this.handleInputRutEmpresa.bind(this);
    this.handleInputDescripconEmpresa = this.handleInputDescripconEmpresa.bind(
      this
    );
    this.handleInputDescripconBreve = this.handleInputDescripconBreve.bind(
      this
    );
    this.handleInputDireccionEmpresa = this.handleInputDireccionEmpresa.bind(
      this
    );
    this.handleInputTelefonoEmpresa = this.handleInputTelefonoEmpresa.bind(
      this
    );
    this.handleInputEmailEmpresa = this.handleInputEmailEmpresa.bind(this);
    this.handleInputSitioWebEmpresa = this.handleInputSitioWebEmpresa.bind(
      this
    );
    this.handleInputRegion = this.handleInputRegion.bind(this);
    this.handleInputComuna = this.handleInputComuna.bind(this);
    this.handleInputCategoriaEmpresa = this.handleInputCategoriaEmpresa.bind(
      this
    );
    this.handleInputServicioEmpresa = this.handleInputServicioEmpresa.bind(
      this
    );
    this.handleInputOtraCategoria = this.handleInputOtraCategoria.bind(this);
    this.handleInputSubCategoriaEmpresa = this.handleInputSubCategoriaEmpresa.bind(
      this
    );
    this.validacionNombre = this.validacionNombre.bind(this);
    this.formateaRut = this.formateaRut.bind(this);
    this.validacionDescripconEmpresa = this.validacionDescripconEmpresa.bind(
      this
    );
    this.validacionDescripconBreve = this.validacionDescripconBreve.bind(this);
    this.validacionDireccionEmpresa = this.validacionDireccionEmpresa.bind(
      this
    );
    this.validacionSitioWebEmpresa = this.validacionSitioWebEmpresa.bind(this);
    this.validaEmail = this.validaEmail.bind(this);
    this.guardarEmpresa = this.guardarEmpresa.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.handleTypoEmpresa = this.handleTypoEmpresa.bind(this);
    this.completarEmpresa = this.completarEmpresa.bind(this);
    this.recuperaCategoria = this.recuperaCategoria.bind(this);
    this.recuperaServicio = this.recuperaServicio.bind(this);
    this.recuperaComuna = this.recuperaComuna.bind(this);
    this.eliminarImagen = this.eliminarImagen.bind(this);
    this.eliminarImagenNoGuardada = this.eliminarImagenNoGuardada.bind(this);
  }

  validaEmail2() {
    let { emailEmpresa } = this.state;
    emailEmpresa = emailEmpresa.toLowerCase();
    this.setState({ emailEmpresa });

    let reg = /^[a-z0-9][a-z0-9-_.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9]).[a-z0-9]{2,10}(?:.[a-z]{2,10})?$/;
    if (!reg.test(emailEmpresa)) {
      // alertV2('Aviso', 'Email no tiene el formato correcto');
      return false;
    } else {
      return true;
    }
  }

  validaEmail() {
    if (!this.validaEmail2()) {
      alertV2("Aviso", MSG_EMAIL_EMP);
    }
  }

  validar() {
    let {
      nombreEmpresa,
      rutEmpresa,
      descripconEmpresa,
      descripconBreve,
      telefonoEmpresa,
      inPutRegionEmpresa,
      inPutComunaEmpresa,
      direccionEmpresa,
      emailEmpresa,
      sitioWebEmpresa,
      flagOtraCategoria,
      otraCategoria,
      files,
      imagenes,
      inPutServicioEmpresa,
    } = this.state;
    let items = [];

    if (nombreEmpresa === undefined || nombreEmpresa.trim() === "")
      items.push("Nombre Empresa");
    if (rutEmpresa === undefined || rutEmpresa.trim() === "")
      items.push("RUT Empresa");
    // if (descripconEmpresa === undefined || descripconEmpresa.trim() === "")
    //   items.push("Descripción Larga");
    // if (descripconBreve === undefined || descripconBreve.trim() === "")
    //   items.push("Descripción Breve");
    if (
      flagOtraCategoria === true &&
      (otraCategoria == null || otraCategoria.length === 0)
    ) {
      items.push("Otros");
    }
    if (inPutServicioEmpresa == null || inPutServicioEmpresa.length === 0) items.push('Servicio');
    if ((files === undefined || files.length === 0) && imagenes.length === 0)
      items.push("Agregar imágenes");
    if (inPutRegionEmpresa === undefined || inPutRegionEmpresa === "")
      items.push("Región");
    if (inPutComunaEmpresa === undefined || inPutComunaEmpresa === "")
      items.push("Comuna");
    // if (direccionEmpresa === undefined || direccionEmpresa.trim() === "")
    //   items.push("Dirección");
    if (emailEmpresa === undefined || emailEmpresa.trim() === "")
      items.push("Email");

    if (nombreEmpresa.length > MAX_NOMBRE_EMP) {
      items.push(MSG_NOMBRE_EMP);
    }

    if (descripconEmpresa.length > MAX_DESC_LARGA_EMP) {
      items.push(MSG_DESC_LARGA_EMP);
    }

    if (`${descripconBreve} || ""`.length > MAX_DESC_BREVE_EMP) {
      items.push(MSG_DESC_BREVE_EMP);
    }

    if (`${direccionEmpresa} || ""`.length > MAX_DIRECC_EMP) {
      items.push(MSG_DIRECC_EMP);
    }

    if (`${telefonoEmpresa} || ""`.length > MAX_TELEFO_EMP) {
      items.push(MSG_TELEFO_EMP);
    }

    if (!this.validaEmail2()) {
      items.push(MSG_EMAIL_EMP);
    }

    if (`${sitioWebEmpresa} || ""`.length > MAX_SITIOW_EMP) {
      items.push(MSG_SITIOW_EMP);
    }

    if (files !== undefined && files.length > 0 && imagenes.length > 0) {
      var cantidadTotalImg = files.length + imagenes.length;
      if (cantidadTotalImg > 6) {
        var cant = this.state.mapValores["MAX_FILE_EMPRESA"];
        items.push(MAX_IMG + cant);
      }
    }

    if (items.length > 0) {
      alertObligatorio("Campos obligatorios", items);
      return false;
    } else {
      this.setState({ guardando: true });
      return true;
    }
  }

  guardarEmpresa() {
    if (this.state.guardando) return;
    if (this.validar()) {
      let self = this;
      const params = {};
      let {
        id,
        nombreEmpresa,
        rutEmpresa,
        descripconEmpresa,
        descripconBreve,
        inPutRegionEmpresa,
        inPutComunaEmpresa,
        direccionEmpresa,
        telefonoEmpresa,
        emailEmpresa,
        sitioWebEmpresa,
        radio,
        inPutCategoriaEmpresa,
        inPutSubCategoriaEmpresa,
        flagOtraCategoria,
        otraCategoria,
        imagenes,
        inPutServicioEmpresa,
      } = this.state;

      let cat = inPutCategoriaEmpresa.map((item) => {
        return {
          id: item.value,
        };
      });

      let subCat = inPutSubCategoriaEmpresa.map((item) => {
        return {
          id: item.id,
        };
      });

      let serv;
      let reg;
      if (radio === "TUR") {
        reg = inPutServicioEmpresa.map((item) => {
          return {
            id: item.id,
          };
        });
      } else if (radio === "TEC") {
        serv = inPutServicioEmpresa.map((item) => {
          return {
            id: item.id,
          };
        });
      }



      const data = {
        id: id,
        nombre: nombreEmpresa,
        rut: rutEmpresa,
        descripcion: descripconEmpresa,
        descripcionBreve: descripconBreve,
        codRegion: inPutRegionEmpresa,
        codComuna: inPutComunaEmpresa,
        direccion: direccionEmpresa,
        telefono: telefonoEmpresa,
        mail: emailEmpresa,
        sitioWeb: sitioWebEmpresa,
        idUsuario: sessionStorage.getItem(AUTH_ID_USER),
        categorias: cat,
        subCategorias: subCat,
        categoria: flagOtraCategoria ? otraCategoria : "",
        tipoEmpresa: radio,
        imagenes: imagenes,
        servicios: serv,
        regiones: reg
      };

      let { files } = this.state;
      const request = new XMLHttpRequest();
      request.responseType = "text";
      request.open(
        "POST",
        joinUrl(BASE_URL, parsePath("company/saveCompanyFile", params))
      );
      request.setRequestHeader(
        "Authorization",
        "Bearer " + sessionStorage.getItem(AUTH_KEY)
      );

      request.onreadystatechange = function (resp) {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          if (id === "" || id === 0) {
            let empresa = JSON.parse(resp.target.response);
            sessionStorage.setItem(FLAG_EMPRESA, true);
            self.setState({ id: empresa.id });
            success("Aviso", "Empresa creada correctamente.", function () {
              self.completarEmpresa(self.props.empresa);
              self.setState({ files: [] });
            });
          } else {
            success("Aviso", "Empresa actualizada correctamente.", function () {
              self.completarEmpresa(self.props.empresa);
              self.setState({ files: [] });
            });
          }
          self.props.onActualizarEmpresa();
        } else {
          if (this.readyState === 4) {
            alertV2("Aviso", resp.target.response);
          }
        }
        self.setState({ guardando: false });
      };
      request.send(this.getDataUpload(files, data));
    }
  }

  getDataUpload(files, data2) {
    const data = new FormData();
    for (let i = 0; files != null && i < files.length; i++) {
      data.append("file", files[i], files[i].name);
    }
    var blob = new Blob([JSON.stringify(data2)], { type: "application/json" });
    data.append("empresa", blob);
    return data;
  }

  getFiles(newfiles) {
    if (newfiles !== undefined) {
      const archivo = newfiles[0].name;
      var extension = archivo.slice(((archivo.lastIndexOf(".") - 1) >>> 0) + 2);

      if (
        extension.toLowerCase() === JPEG ||
        extension.toLowerCase() === JPG ||
        extension.toLowerCase() === PNG
      ) {
        var sizeEmpresa = this.state.mapValores["IMG_SIZE_EMPRESA"];
        var fileSize = newfiles[0].size;
        var sizekiloByte = parseInt(fileSize / 1024);
        var sizeMaxkb = 1024 * sizeEmpresa;

        if (sizekiloByte < sizeMaxkb) {
          let { files } = this.state;
          let allFiles;
          if (files != null) {
            allFiles = newfiles.concat(files);
          } else {
            allFiles = newfiles;
          }

          this.setState({
            files: allFiles,
          });
        } else {
          alertV2("Aviso", MSG_TAMANO_IMG);
        }
      } else {
        alertV2("Aviso", MSG_EXTENSION_IMG);
      }
    }
  }

  formateaRut(e) {
    let rutPuntos = formateaRut(e.target.value);
    if (rutPuntos.length < 1) {
      alertV2("Aviso", "RUT inválido");
    }
    let { rutEmpresa } = this.state;
    rutEmpresa = rutPuntos;
    this.setState({ rutEmpresa });
  }

  validacionNombre(e) {
    let { nombreEmpresa } = this.state;
    if (e.target.value.length > MAX_NOMBRE_EMP) {
      alertV2("Aviso", MSG_NOMBRE_EMP);
      this.setState({ nombreEmpresa });
    }
  }

  handleInputNombreEmpresa(e) {
    let { nombreEmpresa } = this.state;
    nombreEmpresa = e.target.value;
    this.setState({ nombreEmpresa });
  }

  handleInputRutEmpresa(e) {
    let { rutEmpresa } = this.state;
    rutEmpresa = e.target.value;
    this.setState({ rutEmpresa });
  }

  validacionDescripconEmpresa(e) {
    let { descripconEmpresa } = this.state;
    if (e.target.value.length > MAX_DESC_LARGA_EMP) {
      alertV2("Aviso", MSG_DESC_LARGA_EMP);
      this.setState({ descripconEmpresa });
    }
  }

  handleInputDescripconEmpresa(e) {
    let { descripconEmpresa } = this.state;
    descripconEmpresa = e.target.value;
    let { ContadorDescripcionLarga } = this.state;
    ContadorDescripcionLarga = 600 - descripconEmpresa.length;
    this.setState({ descripconEmpresa, ContadorDescripcionLarga });
  }

  validacionDescripconBreve(e) {
    let { descripconBreve } = this.state;
    if (e.target.value.length > MAX_DESC_BREVE_EMP) {
      alertV2("Aviso", MSG_DESC_BREVE_EMP);
      this.setState({ descripconBreve });
    }
  }

  handleInputDescripconBreve(e) {
    let { descripconBreve } = this.state;
    descripconBreve = e.target.value;
    let { ContadorDescripcionBreve } = this.state;
    ContadorDescripcionBreve = 150 - e.target.value.length;
    this.setState({ descripconBreve, ContadorDescripcionBreve });
  }

  validacionDireccionEmpresa(e) {
    let { descripconEmpresa } = this.state;
    if (e.target.value.length > MAX_DIRECC_EMP) {
      alertV2("Aviso", MSG_DIRECC_EMP);
      this.setState({ descripconEmpresa });
    }
  }

  handleInputDireccionEmpresa(e) {
    let { direccionEmpresa } = this.state;
    direccionEmpresa = e.target.value;
    this.setState({ direccionEmpresa });
  }

  handleInputTelefonoEmpresa(e) {
    let { telefonoEmpresa } = this.state;
    telefonoEmpresa = e.target.value;
    this.setState({ telefonoEmpresa });
  }

  handleInputEmailEmpresa(e) {
    let { emailEmpresa } = this.state;
    emailEmpresa = e.target.value;
    this.setState({ emailEmpresa });
  }

  handleInputSitioWebEmpresa(e) {
    let { sitioWebEmpresa } = this.state;
    sitioWebEmpresa = e.target.value;
    this.setState({ sitioWebEmpresa });
  }

  validacionSitioWebEmpresa(e) {
    let { sitioWebEmpresa } = this.state;
    if (e.target.value.length > 0) {
      if (!validator.isURL(e.target.value, { require_protocol: true })) {
        alertV2("Aviso", MSG_SITIOWEB_EMP);
        this.setState({ sitioWebEmpresa });
      }
    }
  }

  handleInputRegion(event) {
    let idRegion = event === null ? "" : event.value;
    this.setState({
      inPutRegionEmpresa: idRegion,
    });
    this.recuperaComuna(idRegion, false);
  }

  handleInputComuna(event) {
    let idComuna = event === null ? "" : event.value;
    this.setState({
      inPutComunaEmpresa: idComuna,
    });
  }

  handleInputCategoriaEmpresa(event) {
    let {
      flagOtraCategoria,
      inPutSubCategoriaEmpresa,
      categoriaEmpresa,
    } = this.state;

    flagOtraCategoria = false;
    if (event !== null && event.length !== 0) {
      for (let i = 0; i < event.length; i++) {
        if (event[i].tipo === "OTRO") {
          flagOtraCategoria = true;
        }
      }
    } else {
      inPutSubCategoriaEmpresa = [];
    }

    this.setState({
      flagOtraCategoria,
      inPutCategoriaEmpresa: event,
    });
    this.cargarSubCategoria(
      JSON.parse(JSON.stringify(categoriaEmpresa)),
      event,
      inPutSubCategoriaEmpresa
    );
  }

  handleInputServicioEmpresa(event) {
    this.setState({
      inPutServicioEmpresa: event,
    });
  }

  handleInputOtraCategoria(e) {
    let { otraCategoria } = this.state;
    otraCategoria = e.target.value;
    this.setState({ otraCategoria });
  }

  handleInputSubCategoriaEmpresa(event) {
    this.setState({
      inPutSubCategoriaEmpresa: event,
    });
  }

  componentDidMount() {
    if (this.props.empresa !== null && this.props.nombreEmpresa !== "") {
      this.completarEmpresa(this.props.empresa);
    }
  }

  componentWillMount() {
    this.recuperaProperties();
    this.recuperaRegion();
    //this.recuperaServicio(true);
  }

  recuperaRegion() {
    const self = this;
    ParameterService.getRegion().then((parametros) => {
      if (parametros.status === 200) {
        self.setState({
          regionEmpresa: parametros.data,
        });
      }
    });
  }

  recuperaProperties() {
    let self = this;
    LabelService.obtener().then((resp) => {
      self.setState({
        mapEmpresa: resp.data["REGISTROEMPRESA"],
        mapValores: resp.data["VALORES"],
      });
    });
  }

  recuperaCategoria(
    e,
    esCargaInicial,
    categoriaSeleccionada,
    subCategoriaSeleccionada
  ) {
    let self = this;
    let data = {
      tipo: e,
    };

    if (esCargaInicial) {
      ParameterService.getCategorias(data).then((parametros) => {
        if (parametros.status === 200) {
          self.setState({
            categoriaEmpresa: parametros.data,
          });
          self.cargarSubCategoria(
            JSON.parse(JSON.stringify(parametros.data)),
            categoriaSeleccionada,
            subCategoriaSeleccionada
          );

          if (e === "TUR") {

            ParameterService.getRegion().then((ServicioReg) => {
              if (ServicioReg.status === 200) {
                self.setState({
                  servicioEmpresa: ServicioReg.data,
                });
              }
            })
          }
        }
      });
    } else {
      LoginService.resfrescarTicket().then(function () {
        ParameterService.getCategorias(data).then((parametros) => {
          if (parametros.status === 200) {
            self.setState({
              categoriaEmpresa: parametros.data,
              subCategoriaEmpresa: [],
              inPutCategoriaEmpresa: [],
              inPutSubCategoriaEmpresa: [],
            });
          }
        });
      });
    }
  }

  recuperaServicio(esCargaInicial) {
    let self = this;
    if (esCargaInicial) {
      ParameterService.getServicios().then((parametros) => {
        if (parametros.status === 200) {
          self.setState({
            servicioEmpresa: parametros.data,
          });
        }
      });
    } else {
      LoginService.resfrescarTicket().then(function () {
        ParameterService.getServicios().then((parametros) => {
          if (parametros.status === 200) {
            self.setState({
              servicioEmpresa: parametros.data,
            });
          }
        });
      });
    }
  }

  cargarSubCategoria = (
    lstCategorias,
    categoriasSeleccionadas,
    subCategoriasSeleccionadas
  ) => {
    let lstSubCategorias = [];
    let inPutSubCategoriaEmpresa = [];

    if (categoriasSeleccionadas !== null) {
      lstCategorias = lstCategorias.filter(function (cat) {
        for (let i = 0; i < categoriasSeleccionadas.length; i++) {
          if (cat.value === categoriasSeleccionadas[i].value) {
            return true;
          }
        }
        return false;
      });

      if (
        categoriasSeleccionadas !== null &&
        categoriasSeleccionadas.length !== 0
      ) {
        for (let i = 0; i < lstCategorias.length; i++) {
          for (let x = 0; x < lstCategorias[i].subCategorias.length; x++) {
            lstCategorias[i].subCategorias[x].descripcion =
              lstCategorias[i].text +
              " - " +
              lstCategorias[i].subCategorias[x].descripcion;
          }
          lstSubCategorias = lstSubCategorias.concat(
            lstCategorias[i].subCategorias
          );
        }
      }

      inPutSubCategoriaEmpresa = lstSubCategorias.filter(function (sub) {
        for (let i = 0; i < subCategoriasSeleccionadas.length; i++) {
          if (sub.id === subCategoriasSeleccionadas[i].id) {
            return true;
          }
        }
        return false;
      });
    }

    this.setState({
      subCategoriaEmpresa: lstSubCategorias,
      inPutSubCategoriaEmpresa: inPutSubCategoriaEmpresa,
    });
  };

  handleTypoEmpresa(e) {
    this.recuperaCategoria(e.target.value, false);
    this.setState({
      radio: e.target.value,
    });
  }

  eliminarImagen(id) {
    let { imagenes } = this.state;

    for (let i = 0; i < imagenes.length; i++) {
      if (imagenes[i].id === id) {
        imagenes.splice(i, 1);
      }
    }

    this.setState({
      imagenes,
    });
  }

  eliminarImagenNoGuardada(img) {
    let { files } = this.state;

    for (let i = 0; i < files.length; i++) {
      if (files[i] === img) {
        files.splice(i, 1);
      }
    }

    this.setState({
      files,
    });
  }

  recuperaComuna(event, esCargaInicial) {
    const self = this;
    let data = {
      codRegion: event,
    };
    if (event !== undefined) {
      if (esCargaInicial) {
        ParameterService.getComuna(data).then((parametros) => {
          if (parametros.status === 200) {
            self.setState({
              comunaEmpresa: parametros.data,
            });
          }
        });
      } else {
        LoginService.resfrescarTicket().then(function () {
          ParameterService.getComuna(data).then((parametros) => {
            if (parametros.status === 200) {
              self.setState({
                comunaEmpresa: parametros.data,
              });
            }
          });
        });
      }
    }
  }

  completarEmpresa(empresa) {
    let id = empresa.id;
    let nombreEmpresa = empresa.nombre;
    let rutEmpresa = empresa.rut;
    let descripconEmpresa = empresa.descripcion;
    let descripconBreve = empresa.descripcionBreve;
    let direccionEmpresa = empresa.direccion;
    let telefonoEmpresa = empresa.telefono;
    let emailEmpresa = empresa.mail;
    let sitioWebEmpresa = empresa.sitioWeb;
    let tipo = empresa.tipoEmpresa;
    let radio = tipo;
    let inPutRegionEmpresa = empresa.codRegion;
    let inPutComunaEmpresa = empresa.codComuna ? empresa.codComuna : "";
    let flagOtraCategoria = false;
    let flagDestacada = false;
    let ContadorDescripcionLarga = 600 - `${empresa.descripcion} || ""`.length;
    let ContadorDescripcionBreve =
      150 - `${empresa.descripcionBreve} || ""`.length;

    for (let i = 0; i < empresa.categorias.length; i++) {
      if (empresa.categorias[i].tipo === "OTRO") {
        flagOtraCategoria = true;
      }
    }
    let inPutCategoriaEmpresa = empresa.categorias.map((cat) => ({
      value: cat.id,
      text: cat.descripcion,
      tipo: cat.tipo,
      subCategorias: cat.subCategorias,
    }));

    let inPutServicioEmpresa = []
    if (radio === "TUR") {
      if (empresa.regiones) {
        inPutServicioEmpresa = empresa.regiones.map((reg) => ({
          id: reg.id,
          text: reg.text,
        }));
      }
    } else if (radio === "TEC") {
      inPutServicioEmpresa = empresa.servicios.map((serv) => ({
        id: serv.id,
        descripcion: serv.descripcion,
      }));
    }


    this.recuperaCategoria(
      tipo,
      true,
      inPutCategoriaEmpresa,
      empresa.subCategorias
    );
    this.recuperaComuna(inPutRegionEmpresa, true);

    for (let i = 0; i < empresa.imagenes.length; i++) {
      if (empresa.imagenes[i].destacada === true) {
        flagDestacada = true;
        break;
      }
    }

    this.setState({
      id,
      nombreEmpresa,
      rutEmpresa,
      descripconEmpresa,
      descripconBreve,
      direccionEmpresa,
      telefonoEmpresa,
      emailEmpresa,
      sitioWebEmpresa,
      tipo,
      radio,
      inPutRegionEmpresa,
      inPutComunaEmpresa,
      inPutCategoriaEmpresa,
      inPutServicioEmpresa,
      imagenes: empresa.imagenes,
      otraCategoria: empresa.categoria,
      flagOtraCategoria,
      flagDestacada,
      ContadorDescripcionLarga,
      ContadorDescripcionBreve,
    });
  }

  destacarImagen = (id) => {
    let { imagenes } = this.state;

    for (let i = 0; i < imagenes.length; i++) {
      if (imagenes[i].id === id) {
        imagenes[i].destacada = true;
      } else {
        imagenes[i].destacada = false;
      }
    }

    this.setState({
      imagenes,
      flagDestacada: true,
    });
  };

  render() {
    const {
      handleInputNombreEmpresa,
      handleInputRutEmpresa,
      handleInputDescripconEmpresa,
      handleInputDescripconBreve,
      handleInputDireccionEmpresa,
      handleInputEmailEmpresa,
      handleInputSitioWebEmpresa,
      validacionNombre,
      formateaRut,
      validacionDescripconEmpresa,
      validacionDescripconBreve,
      validacionDireccionEmpresa,
      validacionSitioWebEmpresa,
      handleInputTelefonoEmpresa,
    } = this;
    const {
      nombreEmpresa,
      rutEmpresa,
      descripconEmpresa,
      descripconBreve,
      direccionEmpresa,
      telefonoEmpresa,
      emailEmpresa,
      sitioWebEmpresa,
      files,
      ContadorDescripcionLarga,
      ContadorDescripcionBreve,
      radio,
    } = this.state;

    return (
      <>
        <MDBRow>
          <span className="campos mt-3">
            <LabelMapper label="REGISTROEMPRESA.NOMBRE" />
            <strong className="lbl-obligatorio pl-1">*</strong>
          </span>
        </MDBRow>
        <MDBRow>
          <InputText
            value={nombreEmpresa}
            onChange={handleInputNombreEmpresa}
            onBlur={validacionNombre}
            className="form-control"
            autoComplete="off"
            maxLength="30"
          />
        </MDBRow>
        <MDBRow>
          <span className="campos mt-3">
            {/* <LabelMapper label="REGISTROEMPRESA.RUT" className="text-uppercase" /> */}
            RUT Empresa
            <strong className="lbl-obligatorio pl-1">*</strong>
          </span>
        </MDBRow>
        <MDBRow>
          <InputText
            value={rutEmpresa}
            onChange={handleInputRutEmpresa}
            onBlur={formateaRut}
            className="form-control"
            autoComplete="off"
            placeholder="Ejemplo: 12345678-9"
          />
        </MDBRow>
        <MDBRow>
          <span className="campos mt-3">
            Descripción Breve
            <strong className="lbl-obligatorio pl-1">*</strong>
          </span>
        </MDBRow>
        <MDBRow>
          <textarea
            value={descripconBreve || ""}
            onChange={handleInputDescripconBreve}
            onBlur={validacionDescripconBreve}
            rows="5"
            className="form-control"
            placeholder="Máximo 150 caracteres"
            maxLength="150"
          />
        </MDBRow>
        <label className="contadorCaracter">
          Caracteres restantes: <span>{ContadorDescripcionBreve}</span>
        </label>
        <MDBRow>
          <span className="campos mt-3">
            Descripción Larga
            <strong className="lbl-obligatorio pl-1">*</strong>
          </span>
        </MDBRow>
        <MDBRow>
          <textarea
            value={descripconEmpresa || ""}
            onChange={handleInputDescripconEmpresa}
            onBlur={validacionDescripconEmpresa}
            rows="10"
            className="form-control"
            placeholder="Máximo 600 caracteres"
            maxLength="600"
          />
        </MDBRow>
        <label className="contadorCaracter">
          Caracteres restantes: <span>{ContadorDescripcionLarga}</span>
        </label>
        <MDBRow>
          <span className="campos mt-3">
            <LabelMapper label="REGISTROEMPRESA.CATEGORIAS" />
          </span>
        </MDBRow>
        <MDBRow>
          <div className="w-100">
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Seleccione"
              isClearable="true"
              isSearchable="true"
              isMulti
              getOptionLabel={(option) => option.text}
              getOptionValue={(option) => option.value}
              value={this.state.inPutCategoriaEmpresa}
              options={this.state.categoriaEmpresa}
              onChange={this.handleInputCategoriaEmpresa}
            />
          </div>
        </MDBRow>
        {this.state.flagOtraCategoria ? (
          <React.Fragment>
            <MDBRow>
              <span className="campos mt-3">
                Otros<strong className="lbl-obligatorio pl-1">*</strong>
              </span>
            </MDBRow>
            <MDBRow>
              <InputText
                value={this.state.otraCategoria}
                onChange={this.handleInputOtraCategoria}
                className="form-control"
                autoComplete="off"
                maxLength="50"
              />
            </MDBRow>
          </React.Fragment>
        ) : null}

        {radio === 'TEC' ?
          <React.Fragment>
            {/* Listar Servicios */}
            <MDBRow>
              <span className="campos mt-3">Servicio
              <strong className="lbl-obligatorio pl-1" >*</strong></span>
            </MDBRow>
            <MDBRow>
              <div className="w-100" >
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione"
                  isClearable="true"
                  isSearchable="true"
                  isMulti
                  getOptionLabel={(option) => option.text}
                  getOptionValue={(option) => option.id}
                  value={this.state.inPutServicioEmpresa}
                  options={this.state.servicioEmpresa}
                  onChange={this.handleInputServicioEmpresa}
                />
              </div>
            </MDBRow>
          </React.Fragment>
          :
          <React.Fragment>
            <MDBRow>
              <span className="campos mt-3">Sub-Categoría</span>
            </MDBRow>
            <MDBRow>
              <div className="w-100">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Seleccione"
                  isClearable="true"
                  isSearchable="true"
                  isMulti
                  getOptionLabel={(option) => option.descripcion}
                  getOptionValue={(option) => option.id}
                  value={this.state.inPutSubCategoriaEmpresa}
                  options={this.state.subCategoriaEmpresa}
                  onChange={this.handleInputSubCategoriaEmpresa}
                />
              </div>
            </MDBRow>
          </React.Fragment>

        }


        {/* Listar Servicios */}
        <MDBRow>
          <span className="campos mt-3">Región donde presta servicios
              <strong className="lbl-obligatorio pl-1" >*</strong></span>
        </MDBRow>
        <MDBRow>
          <div className="w-100" >
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Seleccione"
              isClearable="true"
              isSearchable="true"
              isMulti
              getOptionLabel={(option) => option.text}
              getOptionValue={(option) => option.id}
              value={this.state.inPutServicioEmpresa}
              options={this.state.servicioEmpresa}
              onChange={this.handleInputServicioEmpresa}
            />
          </div>
        </MDBRow>

        <MDBRow>
          <span className="campos mt-3">
            <LabelMapper label="REGISTROEMPRESA.IMGEMPRESA" />
            <strong className="lbl-obligatorio pl-1">*</strong>
          </span>
        </MDBRow>
        <MDBRow>
          <FileUpload
            endpoint="loadImgCarrusel"
            params=""
            data=""
            isAutomaticUpload={false}
            onSelectFiles={this.getFiles}
            onInvalidFile={this.handleInvalidFile}
            multiple="true"
          ></FileUpload>
          <div className="notasImagen col-12">Peso limite: 1mb.</div>
          <div className="notasImagen col-12">
            Extensiones permitida: jpeg, jpg, png.
          </div>
          <div className="notasImagen col-12">
            Tamaño recomendado: 359×300 pixels.
          </div>
          <div className="notasImagen col-12">
            Cantidad máxima de imagenes:{" "}
            <LabelMapper label="VALORES.MAX_FILE_EMPRESA" />
          </div>
        </MDBRow>
        {this.state.imagenes.length > 0 ? (
          <div className="row mt-2">
            {this.state.flagDestacada === true ? (
              <div className="col texto-imagen-temp">
                * La imagen seleccionada es la que será destacada
              </div>
            ) : (
                <div className="col texto-imagen-temp">
                  * Selecciona la imagen que será destacada
                </div>
              )}
          </div>
        ) : null}
        <div className="row">
          {this.state.imagenes != null
            ? this.state.imagenes.map((img) => (
              <div className="col" key={img.id}>
                <img
                  src={img.url}
                  height={120}
                  width={170}
                  alt=""
                  className={
                    img.destacada === true
                      ? "mt-4 img-impresa-destacada"
                      : "mt-4 img-impresa"
                  }
                  onClick={() => this.destacarImagen(img.id)}
                ></img>
                <FontAwesomeIcon
                  icon={["fas", "times-circle"]}
                  color="#E04143"
                  size="2x"
                  className="pl-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.eliminarImagen(img.id)}
                />
              </div>
            ))
            : ""}
        </div>
        {files != null ? (
          <React.Fragment>
            <hr></hr>
            <div className="row">
              <div className="col texto-imagen-temp mb-2">
                * Haz click en PUBLICAR para guardar las imágenes
              </div>
            </div>
          </React.Fragment>
        ) : null}
        <div className="row my-2">
          {files != null
            ? files.map((img) => (
              <div className="col">
                <img
                  src={URL.createObjectURL(img)}
                  height={120}
                  width={170}
                  alt=""
                  className="mb-4 img-impresa"
                ></img>
                <FontAwesomeIcon
                  icon={["fas", "times-circle"]}
                  color="#E04143"
                  size="2x"
                  className="pl-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.eliminarImagenNoGuardada(img)}
                />
              </div>
            ))
            : ""}
        </div>
        <MDBContainer className="mt-5">
          <MDBRow>
            <h5 className="titulo-dashboard py-3">
              {" "}
              <LabelMapper label="REGISTROEMPRESA.UBICACION" />
            </h5>
          </MDBRow>
          <MDBRow>
            <span className="campos mt-2">
              <LabelMapper label="REGISTROEMPRESA.REGION" />
              <strong className="lbl-obligatorio pl-1">*</strong>
            </span>
          </MDBRow>
          <MDBRow>
            <div className="w-100">
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Seleccione"
                isClearable="true"
                isSearchable="true"
                getOptionLabel={(option) => option.text}
                getOptionValue={(option) => option.value}
                value={this.state.regionEmpresa.filter(
                  (option) => option.value === this.state.inPutRegionEmpresa
                )}
                options={this.state.regionEmpresa}
                onChange={this.handleInputRegion}
              />
            </div>
          </MDBRow>
          <MDBRow>
            <span className="campos mt-3">
              <LabelMapper label="REGISTROEMPRESA.COMUNA" />
              <strong className="lbl-obligatorio pl-1">*</strong>
            </span>
          </MDBRow>
          <MDBRow>
            <div className="w-100">
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Seleccione"
                isClearable="true"
                isSearchable="true"
                getOptionLabel={(option) => option.text}
                getOptionValue={(option) => option.value}
                value={this.state.comunaEmpresa.filter(
                  (option) =>
                    option.value === this.state.inPutComunaEmpresa.toString()
                )}
                options={this.state.comunaEmpresa}
                onChange={this.handleInputComuna}
              />
            </div>
          </MDBRow>
          <MDBRow>
            <span className="campos mt-3">
              <LabelMapper label="REGISTROEMPRESA.DIRECCION" />
              <strong className="lbl-obligatorio pl-1">*</strong>
            </span>
          </MDBRow>
          <MDBRow>
            <InputText
              name="firstName"
              value={direccionEmpresa}
              onChange={handleInputDireccionEmpresa}
              onBlur={validacionDireccionEmpresa}
              className="form-control"
              autoComplete="off"
              maxLength="50"
            />
          </MDBRow>
        </MDBContainer>
        <MDBContainer className="mt-5">
          <MDBRow>
            <h5 className="titulo-dashboard py-3">
              {/* <LabelMapper label="REGISTROEMPRESA.CONTACTO" /> */}
              Datos de Contacto
            </h5>
          </MDBRow>
          <MDBRow>
            <span className="campos mt-2">
              <LabelMapper label="REGISTROEMPRESA.SITIOWEB" />
            </span>
          </MDBRow>
          <MDBRow>
            <InputText
              value={sitioWebEmpresa}
              onChange={handleInputSitioWebEmpresa}
              className="form-control"
              onBlur={validacionSitioWebEmpresa}
              autoComplete="off"
              maxLength="100"
            />
          </MDBRow>
          <MDBRow>
            <span className="campos mt-3">
              <LabelMapper label="REGISTROEMPRESA.EMAIL" />
              <strong className="lbl-obligatorio pl-1">*</strong>
            </span>
          </MDBRow>
          <MDBRow>
            <InputText
              value={emailEmpresa}
              onChange={handleInputEmailEmpresa}
              className="form-control"
              onBlur={this.validaEmail}
              autoComplete="off"
              maxLength="50"
            />
          </MDBRow>
          <MDBRow>
            <span className="campos mt-3">
              <LabelMapper label="REGISTROEMPRESA.TELEFONO" />
            </span>
          </MDBRow>
          <MDBRow>
            <InputText
              value={telefonoEmpresa}
              onChange={handleInputTelefonoEmpresa}
              className="form-control"
              autoComplete="off"
              maxLength="30"
            />
          </MDBRow>
        </MDBContainer>
        <MDBContainer>
          <div className="contenedorButton mt-5">
            <MDBRow className="text-center">
              <MDBCol size="12">
                <MDBBtn
                  rounded
                  className="btn-primario"
                  onClick={this.guardarEmpresa}
                  disabled={this.state.guardando}
                >
                  <LabelMapper label="REGISTROEMPRESA.REGISTRAR" />
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBContainer>
      </>
    );
  }
}

export default RegistroEmpresa;
