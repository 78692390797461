import React, { Component } from "react";
import { MDBContainer } from "mdbreact";
import CompanyService from "commons/services/CompanyServices";
import RegistroEmpresa from "commons/components/empresas/RegistroEmpresa";
import RegistroEmpresaTec from "commons/components/empresas/RegistroEmpresaTec";
import LoginService from "commons/services/LoginService";
import { TypeCompanyForm } from "commons/components/empresas/TypeCompanyForm";
import { PostulationForm } from "commons/components/empresas/PostulationForm";

class MiEmpresaProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      empresa: {
        categoria: "",
        categorias: [],
        codComuna: "",
        codRegion: 0,
        descripcion: "",
        descripcionBreve: "",
        direccion: "",
        esNueva: false,
        id: 0,
        idUsuario: 0,
        imagenes: [],
        mail: "",
        nombre: "",
        rut: "",
        servicios: [],
        sitioWeb: "",
        subCategorias: [],
        telefono: "",
        tipoEmpresa: "",
        vigencia: true,
      },
      nuevo: true,
      tipoTur: false,
    };
    this.recuperarEmpresa = this.recuperarEmpresa.bind(this);
    this.handleTypeCompany = this.handleTypeCompany.bind(this);
  }

  componentDidMount() {
    this.recuperarEmpresa();
  }

  recuperarEmpresa() {
    const self = this;
    this.setState({ loading: true });
    let data = {
      idUsuario: 0,
    };
    const tipoEmpresa = "TEC";

    LoginService.resfrescarTicket().then(() => {
      CompanyService.getEmpresa(data).then((empresas) => {
        const tipoTur = empresas.data && empresas.data.tipoEmpresa === "TUR";
        self.setState({
          empresa: empresas.data || this.state.empresa,
          loading: false,
          nuevo: empresas.data === null,
          tipoTur,
        });
      });
    });

    CompanyService.getPostulacion({ tipoEmpresa }).then((postulation) => {
      const statusCode =
        postulation.status === 417
          ? 2
          : postulation.data.codigoEstadoPostulacion;

      self.setState({
        postulationStatus: statusCode,
      });
    });
  }

  handleTypeCompany(type) {
    this.setState({
      empresa: {
        ...this.state.empresa,
        tipoEmpresa: type,
      },
      nuevo: false,
      tipoTur: type === "TUR",
    });
  }

  render() {
    const { empresa } = this.state;
    const registroEmpresa =
      (!this.state.nuevo && this.state.tipoTur) ||
      this.state.postulationStatus === 1;

    if (this.state.loading) {
      return <h4>Cargando...</h4>;
    }

    if (this.state.postulationStatus === 2) {
      return (
        <h3 className="mt-5">
          Ud. ya ha postulado. Por favor, espere la resolución
        </h3>
      );
    }

    if (this.state.postulationStatus === 3) {
      return <h3 className="mt-5">Su postulación a sido rechazada</h3>;
    }

    return (
      <>
        <MDBContainer className="float-left margin-dashboard">
          <div className="col-12 col-md-10 col-lg-8 col-xl-6 p-0">
            <MDBContainer className="p-0">
              <TypeCompanyForm
                onChange={this.handleTypeCompany}
                typeSelected={this.state.empresa.tipoEmpresa}
              />
              {/*{registroEmpresa && (
                <RegistroEmpresa
                  empresa={empresa}
                  onActualizarEmpresa={this.recuperarEmpresa}
                />
              )}*/}
              {registroEmpresa && (
                <React.Fragment>
                  {empresa.tipoEmpresa === 'TUR' ?
                    <RegistroEmpresa
                      empresa={empresa}
                      onActualizarEmpresa={this.recuperarEmpresa}
                    /> 
                    :
                    <RegistroEmpresaTec
                      empresa={empresa}
                      onActualizarEmpresa={this.recuperarEmpresa}
                    /> 
                  }
                </React.Fragment>
              )

              }
              {!this.state.nuevo && !this.state.tipoTur && !registroEmpresa && (
                <PostulationForm empresa={empresa} />
              )}
            </MDBContainer>
          </div>
        </MDBContainer>
      </>
    );
  }
}

export default MiEmpresaProvider;
