import React, { Component } from 'react';
import { MDBBtn } from 'mdbreact';
import MailRest from 'commons/services/MailService';
import { alertObligatorio } from 'commons/util';
import { AUTH_ID_USER, AUTH_NOMBRE } from 'commons/constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class MensajeriaConversacion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hiloConversacion: this.props.hiloConversacion,
            idHilo: this.props.idHilo,
            nvoMensaje: "",
            miInterval: undefined
        };
        this.enviarMensaje = this.enviarMensaje.bind(this);
        this.keyPressedEnviar = this.keyPressedEnviar.bind(this);
        this.newMensaje = this.newMensaje.bind(this);      
        
        
    }

    enviarMensaje() {
        let items = [];

        let { nvoMensaje } = this.state;

        if (nvoMensaje === undefined || nvoMensaje.trim() === '') items.push('Ingrese Mensaje');

        if (nvoMensaje.trim().length > 100) {
            items.push('El mensaje no puede superar los 100 caracteres');
        }

        if (items.length > 0) {
            alertObligatorio('Campos obligatorios', items);
            return;
        }

        const data = {
            idPadre: this.props.idHilo,
            mensaje: nvoMensaje.trim()
        }
        MailRest.guardarMensaje(data).then(() => {
            this.setState({
                nvoMensaje: ""
            });

            const { handleClick } = this.props
            handleClick(this.props.idHilo)        
        });
    }

    keyPressedEnviar (e) {
        console.log('111')
        let { nvoMensaje } = this.state;

        if (e.key === "Enter" && nvoMensaje !== undefined && nvoMensaje.trim().length > 0) {
            this.enviarMensaje();
        }
    }

    newMensaje(e) {
        this.setState({
            nvoMensaje: e.target.value
        });
    }

    render() {

        const { hiloConversacion } = this.props

        return (

            <div className="col-md-8 pt-2">
                <div className="row mb-3 conversacion">
                    <div className=" col-md-12 " >
                        {
                            hiloConversacion.map((hilo, index) => {
                                return (

                                    String(hilo.idUsuario) === String(sessionStorage.getItem(AUTH_ID_USER)) ?

                                        <div className="row mb-3" key={index}>
                                            <div className="col-md-4" ></div>
                                            <div className="col-md-8 panel text-right background-owner" >
                                                <strong className="titulo-msje" >
                                                    {sessionStorage.getItem(AUTH_NOMBRE)}
                                                </strong>
                                                <hr className="mt-0 mb-1" ></hr>
                                                <p className="detalle" >
                                                    {hilo.mensaje}
                                                </p>

                                                <div>
                                                    {
                                                        Boolean(hilo.leido) === false ?
                                                            ""
                                                            :
                                                            <strong className="titulo-msje" >
                                                                <FontAwesomeIcon icon={["fas", "check-circle"]} />
                                                            </strong>
                                                    }
                                                </div>
                                            </div>

                                        </div>

                                        :
                                        <div className="row mb-3" key={index}>
                                            <div className="col-md-8 panel">
                                                <strong className="titulo-msje" >{hilo.empresaNombre}</strong>
                                                <hr className="mt-0 mb-1" ></hr>
                                                <p className="detalle" >
                                                    {hilo.mensaje}
                                                </p>
                                            </div>
                                            <div className="col-md-2" ></div>
                                        </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="row responder" >
                        <div className="col-md-9" >
                            <input className="form-control"
                                value={this.state.nvoMensaje}
                                onChange={this.newMensaje}
                                onKeyPress={this.keyPressedEnviar}>
                            </input>
                        </div>
                        <div className="col" >
                            <MDBBtn rounded className="btn-responder" onClick={this.enviarMensaje} >
                                Enviar
                            </MDBBtn>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}