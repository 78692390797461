import React, { Component } from "react";
import CarouselDetalleItemImpl from "./CarouselDetalleItemImpl";
import CategoriasImpl from "./CategoriasImpl";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBBtn,
} from "mdbreact";
import LabelMapper from "commons/components/LabelMapper";
import HomeService from "commons/services/HomeRest";
import LoginService from "commons/services/LoginService";
import { AUTH_KEY } from "commons/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import ModalSolicitudContacto from "./ModalSolicitudContacto";
import ModalFavoritos from "./ModalFavoritos";
import { modal } from "commons/util";
import parse from "html-react-parser";
/* import ServiciosImpl from "./ServiciosImpl"; */

class DestacadoDetalle extends Component {
  constructor(props) {
    super(props);

    const params = queryString.parse(props.location.search);
    this.state = {
      imagen: {},
      empresa: {},
      origen: params === undefined ? "" : params.origen,
      flagFavorito: false,
    };

    this.guardarFavorito = this.guardarFavorito.bind(this);
    this.contactar = this.contactar.bind(this);
    this.volver = this.volver.bind(this);
  }

  componentDidMount() {
    this.getComment();
  }

  getComment() {
    const data = {
      idEmpresa: this.props.match.params.idEmpresa,
    };

    const self = this;
    LoginService.resfrescarTicket().then(() => {
      HomeService.empresaDestacada(data).then((empresa) => {
        if (empresa.data == null) {
          self.props.history.push("/error/general/");
          return;
        }

        self.setState({
          imagen: {
            configuracion: empresa.data.configuracion,
            contenido: empresa.data.imagenes,
          },
          empresa: empresa.data,
        });
      });
    });
  }

  guardarFavorito() {
    let { flagFavorito } = this.state;
    if (flagFavorito) {
      flagFavorito = false;
    } else {
      flagFavorito = true;
    }

    modal(ModalFavoritos, {
      idEmpresa: this.props.match.params.idEmpresa,
      flagFavorito: flagFavorito,
    });

    this.setState({
      flagFavorito: flagFavorito,
    });
  }

  volver() {
    let { origen } = this.state;

    if (origen === undefined || origen === "") {
      origen = window.location.origin;
    }

    this.props.history.push(origen);
  }

  contactar() {
    modal(ModalSolicitudContacto, {
      idEmpresa: this.props.match.params.idEmpresa,
      origen: this.props.location.search.includes("match")
        ? "Match"
        : "Destacado",
    });
  }

  render() {
    const session = sessionStorage.getItem(AUTH_KEY) != null ? true : false;
    const { imagen } = this.state;
    const { empresa } = this.state;

    if (empresa.nombre == null || empresa.nombre.length <= 0) return null;

    return (
      <div className="mt-4 mx-5 margin-dashboard">
        <MDBRow>
          <CarouselDetalleItemImpl imagen={imagen} />
        </MDBRow>
        <div className="mt-3">
          <div className="row">
            <div className="col-md-8">
              <h5 className="titulo-empresa-detalle">{empresa.nombre}</h5>
              <div className="row">
                {empresa.direccion === undefined ||
                empresa.direccion.length <= 0 ? (
                  ""
                ) : (
                  <div className="empresa-detalle-datos">
                    <FontAwesomeIcon
                      icon={["fas", "map-marker-alt"]}
                      color="#4A91E3"
                      size="sm"
                    />
                    &nbsp; {empresa.direccion}, {empresa.descComuna},{" "}
                    {empresa.descRegion}. &nbsp;
                  </div>
                )}
                {empresa.telefono === undefined ||
                empresa.telefono.length <= 0 ? (
                  ""
                ) : (
                  <div className="empresa-detalle-datos">
                    <FontAwesomeIcon
                      icon={["fas", "phone-alt"]}
                      color="#4A91E3"
                      size="sm"
                    />
                    &nbsp; {empresa.telefono}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4 pr-0 d-none d-md-block">
              {session && (
                <MDBCol className="d-flex justify-content-end">
                  <MDBBtn
                    rounded
                    className="btn-primario"
                    onClick={this.contactar}
                  >
                    <LabelMapper label="DESTACADOSDETALLE.BUTTONCONTACTAR" />
                  </MDBBtn>
                </MDBCol>
              )}
            </div>
            <div className="col-md-4 mt-3 d-md-none d-lg-none d-xl-none">
              {session && (
                <div className="row text-center">
                  <div className="col-sm-12">
                    <FontAwesomeIcon
                      icon={["far", "comments"]}
                      size="2x"
                      color="#4A91E3"
                      onClick={this.contactar}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mb-5 mt-4">
          <MDBRow>
            <MDBCol md="7" className="pr-2">
              <section className="pb-4">
                <p className="font-weight my-2 text-justify">
                  {parse(empresa.descripcion.split("\n").join("<br />"))}
                </p>
              </section>
              <section className="pb-2">
                <p className="font-weight my-2 text-justify empresa-informacion">
                  {empresa.sitioWeb === undefined ||
                  empresa.sitioWeb.length <= 0 ? (
                    ""
                  ) : (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={empresa.sitioWeb}
                    >
                      Visitar Sitio Web
                    </a>
                  )}
                </p>
              </section>
            </MDBCol>
            <MDBCol md="5">
              <MDBCard className="empresa-categorias">
                <MDBCardBody>
                  <MDBCardTitle>
                    <h6 className="font-weight my-2 titulo-item">
                      {empresa.categoriaDesSeccion}
                    </h6>
                  </MDBCardTitle>
                  <MDBCardText>
                    <CategoriasImpl empresa={empresa} />
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-5 d-none d-md-block">
            <MDBCol className="d-flex justify-content-end">
              <MDBBtn
                rounded
                outline
                className="btn-secundario"
                onClick={this.volver}
              >
                <LabelMapper label="COMUN.VOLVER" />
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-5 d-md-none d-lg-none d-xl-none text-center">
            <MDBCol>
              <MDBBtn
                rounded
                outline
                className="btn-secundario"
                onClick={this.volver}
              >
                <LabelMapper label="COMUN.VOLVER" />
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </div>
      </div>
    );
  }
}

export default DestacadoDetalle;
