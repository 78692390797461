import {
    MATCH,
} from '../actionTypes';

/**
 * @description Envía al store la data `map` de los labels
 * obtenidos
 */
export const matchData = (data) => ({
    type: MATCH,
    payload: {
        data: data
    },
    error: false,
});