import { combineReducers } from 'redux';

import loadingIcon from './loadingIcon';
import labelMap from './labelMap';
import login from './login';
import avatar from './avatar';
import mensajes from './mensajes';
import msg_bandeja from './msgBandeja';
import destacadosTur from './destacadosTur';
import destacadosTec from './destacadosTec';
import match from './match';

/**
 * @description Combina los `reducers` para establecer un único objeto `state`
 * el cual será envíado a cada función callback `mapStateToProps` definida
 * en los `containers`
 */
export default combineReducers({
    isFetchingData: loadingIcon,
    labelMap,
    loginProps: login,
    avatarProps: avatar,
    mensajesProps: mensajes,
    msgBandejaProps: msg_bandeja,
    destacadosTurProps: destacadosTur,
    destacadosTecProps: destacadosTec,
    matchProps: match
});