import React, { Component } from 'react';
import HomeRest from 'commons/services/HomeRest';
import Testimonios from 'commons/components/testimonios/Testimonios';

class TestimoniosProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        testimonios: []
      },
    };
  }

  componentDidMount() {
    this.getCarouselData();
  }

  getCarouselData() {
    const self = this;
    HomeRest.findTestimonios().then((testimonios) => {
      let data = testimonios === null ? [] : testimonios.data;
      self.setState({
        params: {
          testimonios: data
        },
      });
    });
  }


  render() {
    const { params } = this.state;
    return (
      <Testimonios params={params} />
    );
  }
}

export default TestimoniosProvider;
