import React, { Component } from 'react';
import InputText from 'commons/components/InputText';
import LoginService from 'commons/services/LoginService';
import { modal, alertV2, alertObligatorio } from 'commons/util';
import LabelMapper from 'commons/components/LabelMapper';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { connect } from 'react-redux';
import {
  AUTH_KEY, AUTH_IMAGEN_ICON, AUTH_NOMBRE, AUTH_ID_USER, FLAG_EMPRESA, AUTH_REFRESH_KEY,
  KEY_EXPIRE, KEY_REFRESH_EXPIRE, REVISION_SESSION_INTERVALO, AUTH_EMAIL, MAIL_PENDIENTE, FILTRO_REGION
} from 'commons/constants';
import ModalRecuperaPassword from 'commons/components/sesion/recuperaPassword/ModalRecuperaPassword';
import ModalBienvenida from 'commons/components/sesion/ModalBienvenida';
import { loginData } from 'commons/redux/actions/loginActions';
import ModalRenovarSesion from 'commons/components/sesion/ModalRenovarSesion';
import TagManager from 'react-gtm-module';

class RegistroUsuario extends Component {

  constructor() {
    super();
    this.state = {
      token: '',
      status: '',
      usuario: '',
      password: '',
      redireccionar: false
    };
    this.getPassword = this.getPassword.bind(this);
    this.getUsuario = this.getUsuario.bind(this);
    this.getToken = this.getToken.bind(this);
    this.openRecuperarPassword = this.openRecuperarPassword.bind(this);
  }

  openRecuperarPassword() {
    this.props.cancelar();
    modal(ModalRecuperaPassword);
  }

  validar() {
    let { usuario, password } = this.state;
    let items = [];

    if (usuario === undefined || usuario.trim() === '') items.push('Usuario');
    if (password === undefined || password.trim() === '') items.push('Contraseña');

    if (items.length > 0) {
      alertObligatorio('Campos obligatorios', items);
      return false;
    } else {
      return true;
    }
  }

  renovarSesion = () => {
    setInterval(() => {
      let modalRenovar = sessionStorage.getItem('ModalRenovar');
      modalRenovar = modalRenovar === null ? 0 : parseInt(modalRenovar);
      let time = sessionStorage.getItem('Time');
      time = time === null ? 0 : parseInt(time);

      let keyExpire = parseInt(sessionStorage.getItem(KEY_EXPIRE));

      time = time + REVISION_SESSION_INTERVALO;
      if (!modalRenovar) {
        if (time >= keyExpire) {
          sessionStorage.setItem('ModalRenovar', 1);
          modal(ModalRenovarSesion);
        }
      }
      sessionStorage.setItem('Time', time);

    }, REVISION_SESSION_INTERVALO);
  }

  getToken() {
    if (this.validar()) {
      const self = this;
      const { dispatch } = this.props;
      let param = '';
      let data = {
        username: this.state.usuario,
        password: this.state.password
      }

      LoginService.login(param, data).then((returnData) => {

        sessionStorage.clear();
        sessionStorage.setItem(AUTH_KEY, returnData.data.access_token);
        sessionStorage.setItem(KEY_EXPIRE, returnData.data.expires_in * 1000);
        sessionStorage.setItem(AUTH_REFRESH_KEY, returnData.data.refresh_token);
        sessionStorage.setItem(KEY_REFRESH_EXPIRE, returnData.data.refresh_expires_in * 1000);
        if (returnData.status === 200) {
            LoginService.recuperaUsuario().then((usuarios) => {
                this.registarAutenticacion(usuarios.data.id, usuarios.data.mail);
                sessionStorage.setItem(AUTH_ID_USER, usuarios.data.id);
                sessionStorage.setItem(AUTH_IMAGEN_ICON, usuarios.data.urlImg);
                sessionStorage.setItem(AUTH_NOMBRE, usuarios.data.nombre);
                sessionStorage.setItem(FLAG_EMPRESA, usuarios.data.tieneEmpresa);
                sessionStorage.setItem(AUTH_EMAIL, usuarios.data.mail);
                sessionStorage.setItem(MAIL_PENDIENTE, usuarios.data.mailPendientes);
                sessionStorage.setItem(FILTRO_REGION, usuarios.data.filtro.regionEmpresa);
                dispatch(loginData(sessionStorage.getItem(AUTH_KEY)));
                self.renovarSesion();
                if (usuarios.data.tieneEmpresa) {
                  self.props.cancelar();
                  window.location.reload();
                } else {
                  modal(ModalBienvenida, {
                    nombre: usuarios.data.nombre,
                    crearEmpresa: self.crearEmpresa
                  });
                }
            });
          } else {
            alertV2('Aviso', 'Usuario o Contraseña inválidos');
            sessionStorage.clear();
          }
      });
    }
  }

  crearEmpresa = () => {
    this.props.crearEmpresa();
  }

  registarAutenticacion = (id, email) => {
    let tagManagerArgs = {
      dataLayer: {
        'event': 'InicioSesion',
        'category': 'Usuario',
        'action': 'Autenticacion',
        'var_etiqueta': email,
        'var_valor': id,
      },
      dataLayerName: 'Sernatur'
    }
    TagManager.dataLayer(tagManagerArgs);
  }

  getUsuario(e) {
    this.setState({
      usuario: e.target.value.trim(),
    });
  }

  getPassword(e) {
    this.setState({
      password: e.target.value.trim(),
    });
  }

  render() {
    return (
      <MDBContainer className="p-4">
        <MDBRow>
          <MDBCol>
            <label htmlFor="usuario" className="mb-0" >
              Usuario
              </label>
            <InputText className="form-control mb-3" id="usuario" value={this.state.usuario} onChange={this.getUsuario} placeholder="Email" autocomplete="off" />
            <label htmlFor="password" className="mb-0" >
              Contraseña
              </label>
            <input className="form-control mb-4" name="password" id="password" type="password" value={this.state.password} onChange={this.getPassword} placeholder="Contraseña" autocomplete="off" />
            <div className="text-center mx-4">
              <button type="button" className="btn btn-sm btn-primary btn-rounded m-0 mb-2 mt-4 w-100 btn-principal" onClick={this.getToken}>
                <LabelMapper label="COMUN.LOGIN" />
              </button>
              <button className="button-link" onClick={this.openRecuperarPassword}>
                <LabelMapper label="COMUN.OLVIDEPASSWORD" />
              </button>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  labelMap: state.labelMap
});


export default connect(
  mapStateToProps,
)(RegistroUsuario);



