import React from "react";
import { MDBListGroup, MDBListGroupItem, MDBCol, MDBRow } from "mdbreact";
import MiperfilProvider from "home/components/dashboard/MiperfilProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import MiEmpresaProvider from "home/components/dashboard/MiEmpresaProvider";
import MisMatchProvider from "home/components/dashboard/MisMatchProvider";
import MiMegustaProvider from "home/components/dashboard/MiMegustaProvider";
import Mensajeria from "commons/components/mensajeria/MiMensajeria";
import {
  AUTH_IMAGEN_ICON,
  AUTH_ID_USER,
  AUTH_NOMBRE,
  FLAG_EMPRESA,
  MiPerfil,
  MiEmpresa,
  MisMatch,
  MiMensajeria,
  MAIL_PENDIENTE,
  FILTRO_REGION,
} from "commons/constants";
import CargaImagenModal from "commons/components/CargaImagenModal";
import { modal } from "commons/util";
import { avatarData } from "commons/redux/actions/avatarAction";
import AvatarImpl from "commons/components/avatar/AvatarImpl";
import LoginService from "commons/services/LoginService";
import LabelMapper from "commons/components/LabelMapper";

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active:
        this.props.match.params === undefined
          ? 0
          : parseInt(this.props.match.params.id),
    };

    this.handleOnClick = this.handleOnClick.bind(this);
    this.openCargaImagen = this.openCargaImagen.bind(this);
    this.recarga = this.recarga.bind(this);
    this.volverAlHome = this.volverAlHome.bind(this);
  }

  openCargaImagen() {
    modal(CargaImagenModal, {
      url: "user/updateUserFile",
      id: sessionStorage.getItem(AUTH_ID_USER),
      recarga: this.recarga,
    });
  }

  recarga() {
    const { dispatch } = this.props;
    LoginService.recuperaUsuario().then((usuarios) => {
      if (usuarios.status === 200) {
        let data = {
          avatar: usuarios.data.urlImg,
        };
        sessionStorage.setItem(AUTH_IMAGEN_ICON, usuarios.data.urlImg);
        sessionStorage.setItem(FLAG_EMPRESA, usuarios.data.tieneEmpresa);
        sessionStorage.setItem(MAIL_PENDIENTE, usuarios.data.mailPendientes);
        sessionStorage.setItem(
          FILTRO_REGION,
          usuarios.data.filtro.regionEmpresa
        );

        dispatch(avatarData(data));
      }
    });
  }

  handleOnClick(index) {
    if (index > 4) {
      this.volverAlHome();
    }

    let menu = "";
    switch (index) {
      case 0:
        menu = MiPerfil;
        break;
      case 1:
        menu = MiEmpresa;
        break;
      case 2:
        menu = MisMatch;
        break;
      case 4:
        menu = MiMensajeria;
        break;
      default:
        break;
    }
    this.props.history.push(menu);
  }

  volverAlHome() {
    window.location.href = window.location.origin;
  }

  render() {
    let index = parseInt(this.props.match.params.id);

    const { loginProps } = this.props;

    if (Object.keys(loginProps).length === 0) return null;
    return (
      <MDBRow className="margen-superior-dashboard dashboardContainer pt-5">
        <MDBCol className="col-3 col-xl-2 px-0 d-none d-md-block">
          <MDBRow className="avatarBox py-5">
            <MDBCol className="px-0 text-center">
              <AvatarImpl
                click={this.openCargaImagen}
                clase="rounded-circle usuarioSesion fotoUsuarioEnSesion avatar shadow"
              ></AvatarImpl>
              <span className="lbl-nombre-usuario">
                {sessionStorage.getItem(AUTH_NOMBRE)}
              </span>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="menu px-0">
              <MDBListGroup>
                <MDBListGroupItem
                  onClick={() => this.handleOnClick(0)}
                  active={index === 0}
                >
                  <MDBRow>
                    <MDBCol md="1">
                      <FontAwesomeIcon
                        className="dashboardContainer"
                        icon={["far", "user"]}
                      />
                    </MDBCol>
                    <MDBCol>
                      <LabelMapper label="DASHBOARD.MIPERFIL"></LabelMapper>
                    </MDBCol>
                  </MDBRow>
                </MDBListGroupItem>

                <MDBListGroupItem
                  onClick={() => this.handleOnClick(1)}
                  active={index === 1}
                >
                  <MDBRow>
                    <MDBCol md="1">
                      <FontAwesomeIcon
                        className="dashboardContainer"
                        icon={["far", "building"]}
                      />
                    </MDBCol>
                    <MDBCol>
                      <LabelMapper label="DASHBOARD.MIPEMPRESA"></LabelMapper>
                    </MDBCol>
                  </MDBRow>
                </MDBListGroupItem>

                <MDBListGroupItem
                  onClick={() => this.handleOnClick(2)}
                  active={index === 2}
                >
                  <MDBRow>
                    <MDBCol md="1">
                      <FontAwesomeIcon
                        className="dashboardContainer"
                        icon={["fas", "link"]}
                        transform={{ rotate: 45 }}
                      />
                    </MDBCol>
                    <MDBCol>
                      <LabelMapper label="DASHBOARD.MIMACHT"></LabelMapper>
                    </MDBCol>
                  </MDBRow>
                </MDBListGroupItem>
                {/*
                                    <MDBListGroupItem onClick={() => this.handleOnClick(3)} active={index === 3}>
                                        <MDBRow>
                                            <MDBCol md="1">
                                                <FontAwesomeIcon className="dashboardContainer" icon={["far", "heart"]} />
                                            </MDBCol>
                                            <MDBCol>
                                                <LabelMapper label="DASHBOARD.MIMEGUSTA"></LabelMapper>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBListGroupItem> */}
                <MDBListGroupItem
                  onClick={() => this.handleOnClick(4)}
                  active={index === 4}
                >
                  <MDBRow>
                    <MDBCol md="1">
                      <FontAwesomeIcon
                        className="dashboardContainer"
                        icon={["far", "comments"]}
                      />
                    </MDBCol>
                    <MDBCol>Mensajes</MDBCol>
                  </MDBRow>
                </MDBListGroupItem>
                <MDBListGroupItem
                  onClick={() => this.handleOnClick(5)}
                  active={index === 5}
                >
                  <MDBRow>
                    <MDBCol md="1">
                      <FontAwesomeIcon
                        className="dashboardContainer"
                        icon={["fas", "home"]}
                      />
                    </MDBCol>
                    <MDBCol>Volver al Inicio</MDBCol>
                  </MDBRow>
                </MDBListGroupItem>
              </MDBListGroup>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol className="col-md-9 col-xl-10 body-dashboard py-4">
          {(function () {
            switch (index) {
              case 0:
                return <MiperfilProvider />;
              case 1:
                return <MiEmpresaProvider />;
              case 2:
                return <MisMatchProvider />;
              case 3:
                return <MiMegustaProvider />;
              case 4:
                return <Mensajeria />;
              default:
                return null;
            }
          })()}
        </MDBCol>
      </MDBRow>
    );
  }
}
const mapStateToProps = (state) => ({
  loginProps: state.loginProps,
  avatarProps: state.avatarProps,
});

export default connect(mapStateToProps)(DashboardContainer);
