import React from 'react';

/**
 * @author Sinecio Bermúdez
 * @description Componente que envuelve los botones de un header
 * hace las veces de un tabbuttons
 */
export default (props) => {
    let cssClass = (props.className || '').split(' ');
    cssClass.push('tab-buttons');
    return <div {...props} className={cssClass.join(' ').trim()}>{props.children}</div>
}