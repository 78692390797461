/**
 * @description URL Base para las peticiones
 */
export const BASE_URL = process.env.REACT_APP_API_URL;

/**
 * @description Modo de inclusión de credenciales
 */
export const CREDENTIALS = process.env.REACT_APP_CREDENTIALS;

/**
 * @description Key para almacenar el token de autenticación
 */
export const AUTH_KEY = process.env.REACT_APP_SESSION_TOKEN_STORAGE_KEY;
/**
 * @description Key para almacenar el avatar del usaurio
 */
export const AUTH_IMAGEN_ICON = 'AUTH_IMAGEN_ICON';
/**
 * @description Key para almacenar el id en BD del usuario
 */
export const AUTH_ID_USER = 'AUTH_ID_USER';
/**
 * @description Key para almacenar el nombre del usuario
 */
export const AUTH_NOMBRE = 'AUTH_NOMBRE';

export const PREFIX_TELEFONO_PAIS = '+56 9';
export const PREFIX_TELEFONO = '+56';
export const FLAG_EMPRESA = 'FLAG_EMPRESA';
export const AUTH_EMAIL = 'AUTH_EMAIL';
export const ID_TIPO_EMPRESA = 'ID_TIPO_EMPRESA';
export const AUTH_REFRESH_KEY = 'AUTH_REFRESH_KEY';
export const KEY_EXPIRE = 'KEY_EXPIRE';
export const KEY_REFRESH_EXPIRE = 'KEY_REFRESH_EXPIRE';
export const REVISION_SESSION_INTERVALO = 30000;
export const DURACION_MODAL_RENOVACION = 60000;
export const MAIL_PENDIENTE = 'MAIL_PENDIENTE';
export const FILTRO_REGION = 'FILTRO_REGION';
