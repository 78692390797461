import React from 'react';
import { openAlert, openModal } from '@ns-react/modal';
import { Provider } from 'react-redux';
import store from '../redux/store';

import ModalAlert from '../components/alerts/ModalAlert';
import ModalAlertV2 from '../components/alerts/ModalAlertV2';
import ModalAlertObligatorio from '../components/alerts/ModalAlertObligatorio';
import ModalConfirm from '../components/alerts/ModalConfirm';
import ModalSuccess from '../components/alerts/ModalSuccess';

/**
 * @author Sinecio Bermúdez Jacque
 * @description Permite renderizar una modal en forma de alerta con un solo
 * botón `Aceptar`.
 * @param {String} message Mensaje a mostrar en la ventana de alerta
 * @param {Function} onClose función callback a ejecutar al cerrar la alerta
 */
export function alert(message, onClose) {
  return openAlert(<Provider store={store}><ModalAlert {...{message, onClose}}/></Provider>);
}

/**
 * @author 
 * @description Permite renderizar una modal en forma de alerta de tipo error
 * botón `Aceptar`.
 * @param {String} title Titulo que se muestra en el header
 * @param {String} message Mensaje a mostrar en la ventana de alerta
 * @param {Function} onClose función callback a ejecutar al cerrar la alerta
 */
export function alertV2(title, mensaje, onClose){
  return openAlert(ModalAlertV2, {title, mensaje, onClose});
}

/**
 * @author 
 * @description Permite renderizar una modal en forma de tipo success
 * botón `Aceptar`.
 * @param {String} title Titulo que se muestra en el header
 * @param {String} message Mensaje a mostrar en la ventana de alerta
 * @param {Function} onClose función callback a ejecutar al cerrar la alerta
 */
export function success(title, mensaje, onClose){
  return openAlert(ModalSuccess, {title, mensaje, onClose});
}

/**
 * @author 
 * @description Permite renderizar una modal en forma de alerta de tipo error
 * botón `Aceptar`.
 * @param {String} title Titulo que se muestra en el header
 * @param {String} message Mensaje a mostrar en la ventana de alerta
 * @param {Function} onClose función callback a ejecutar al cerrar la alerta
 */
export function alertObligatorio(title, items, onClose){
  return openAlert(ModalAlertObligatorio, {title, items, onClose});
}

/**
 * @author Sinecio Bermúdez Jacque
 * @description Permite renderizar una modal en forma de alerta de confirmación
 * con botón `Aceptar` y `Cancelar`
 * @param {String} message Mensaje a mostrar en la ventana de alerta
 * @param {Function} onClose función callback a ejecutar al aceptar la alerta
 * @param {Function} onDismiss función callback a ejecutar al cancelar la alerta
 */
export function confirm(message, onClose, onDismiss) {
  return openAlert(<Provider store={store}><ModalConfirm {...{message, onClose, onDismiss}}/></Provider>);
}

/**
 * @author Sinecio Bermúdez Jacque
 * @param {React.Component} Component componente a renderizar como modal conectada al store de Redux
 * @param {Object} props objeto literal con props para el componente.
 */
export function modal(Component, props = {}) {
  return openModal(<Provider store={store}><Component {...props} /></Provider>);
}
