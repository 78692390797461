import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BASE_URL } from "../constants";
import { parsePath, joinUrl } from "../util";
import { AUTH_KEY } from "commons/constants";

/**
 * @description Endpoint por defecto para cargar archivos
 */
const DEFAULT_ENDPOINT = "/upload";

/**
 * @author Sinecio Bermúdez Jacque
 * @description Componente que permite realizar subida de archivos con drag and drop
 * @param {String} endpoint String que representa el endpoint que maneja la subida de archivos en el backend
 * @param {Boolean} multiple configura el componente para subir multiples archivos
 * @param {Array|String} type string o array para validar el tipo archivo permitido
 * @param {Object} data objeto plano de datos adicionales a enviar como formData
 * @param {Object} params objeto plano de datos adicionales para utilizar como queryString y parsear la url endpoint
 * @param {Function} onComplete handler ejecutado al completarse la subida de archivos
 * @param {Function} onInvalidFile handler ejecutado al encontrarse uno o más archivos inválidos
 */
export default class FileUpload extends React.Component {
  constructor(props) {
    super(props);

    this.inputFile = React.createRef();

    this.handleClick = this.handleClick.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);

    this.state = {
      progress: 0, // mantiene el valor del porcentaje de subida (0-100)
      dragOver: false, // determina si actualmente existe un drag sobre el área disponible.
    };

    this.invalid = []; // colección de archivos invalidos

    /**
     * deshabilitar drag and drop en body para evitar
     * que se abra un archivo al soltarlo fuera del área permitida
     */
    document.body.ondragover = (e) => false;
    document.body.ondrop = (e) => false;
  }

  componentDidMount() {
    this.inputFile.current.addEventListener("change", (e) =>
      this.upload(e.target.files)
    );
  }

  /**
   * @description Realiza validación utilizando la colección de archivos inválidos `this.invalid`
   * y notifica al padre de lo ocurrido.
   */
  validate() {
    if (this.invalid.length <= 0) return true;
    if (typeof this.props.onInvalidFile === "function")
      this.props.onInvalidFile(this.invalid);
    this.invalid = [];
    return false;
  }

  /**
   * @description Función que permite realizar la subida de los archivos seleccionados
   * @param {FileList|Array} files colección de archivos
   */
  upload(files) {
    const filesToUpload = this.getFilesToUpload(files);
    const request = new XMLHttpRequest();
    const endpoint = this.props.endpoint || DEFAULT_ENDPOINT;

    let { isAutomaticUpload } = this.props;
    let isAutomatic =
      typeof isAutomaticUpload === "undefined"
        ? true
        : this.bool(isAutomaticUpload);

    if (!this.validate()) {
      this.inputFile.current.value = "";
      return;
    }

    if (!isAutomatic) {
      if (typeof this.props.onSelectFiles == "function") {
        this.props.onSelectFiles(filesToUpload, this.inputFile.current.name);
      }
      return;
    }

    request.addEventListener("load", (e) => {
      this.inputFile.current.value = "";
      if (typeof this.props.onComplete === "function")
        this.props.onComplete(e.currentTarget);
      setTimeout(
        () =>
          this.setState({
            progress: 0,
          }),
        2000
      );
    });

    request.upload.addEventListener("progress", (e) =>
      this.setState({
        progress: (e.loaded / e.total) * 100,
      })
    );

    request.responseType = "text";
    request.open(
      "post",
      joinUrl(BASE_URL, parsePath(endpoint, this.props.params))
    );
    request.setRequestHeader(
      "Authorization",
      "Bearer " + sessionStorage.getItem(AUTH_KEY)
    );
    request.send(this.getDataUpload(filesToUpload));
  }
  /**
   * @description Genera un objeto FormData con parámetros obtenidos dede `this.props.data`
   * y los archivos obtenidos del `drag and drop` o el dialogo de selección de archivos.
   * @param {Array} files archivos para ser adjuntados en un FormData
   */
  getDataUpload(files) {
    const data = new FormData();
    const params = this.props.data || {};
    for (let i = 0; i < files.length; i++)
      data.append("file", files[i], files[i].name);
    Object.keys(params).forEach((v) => data.append(v, params[v]));
    return data;
  }

  /**
   * @description Función que permite obtener los archivos validos a ser subidos
   * @param {FileList|Array} files colección de archivos
   */
  getFilesToUpload(files) {
    return ((files) =>
      this.bool(this.props.multiple) ? files : files.slice(0, 1))(
      Array.from(files).filter((file) => this.isValid(file))
    );
  }

  /**
   * @description Función que permite validar un archivo de acuerdo a la lista
   * de tipos permitidos `this.props.type`, almacena los archivos invalidos en `this.invalid` para ahorrar
   * iteración y posteriormente notificar archivos inválidos
   * @param {File} file archivo
   */
  isValid(file) {
    const types = this.getTypes();
    if (types.length <= 0) return true;
    const valid =
      types.filter((t) => String(t).toLowerCase() === file.type.toLowerCase())
        .length > 0;
    if (!valid) this.invalid.push(file);
    return valid;
  }

  /**
   * @description Retorna un array de MIME-types definidos en `props.type`
   */
  getTypes() {
    if (typeof this.props.type === "undefined") return [];
    return Array.isArray(this.props.type)
      ? this.props.type
      : String(this.props.type).split(",");
  }

  /**
   * @description event handler del botón cargar
   * @param {Event} e
   */
  handleClick(e) {
    this.inputFile.current.click();
  }

  /**
   * @description event handler que manipula los archivos soltados en la región
   * dispuesta para ello.
   * @param {Event} e
   */
  handleDrop(e) {
    e.preventDefault();
    this.setState({
      dragOver: false,
    });
    this.upload(e.dataTransfer.files);
  }

  /**
   * @description event handler para cuando el usuario arrastra archivos
   * sobre la región dispuesta para ello.
   * En esta función sólo establecemos un nuevo estado indicando que hay
   * un `dragOver` y así aplicar estilo.
   * @param {Event} e
   */
  handleDragOver(e) {
    e.preventDefault();
    this.setState({
      dragOver: true,
    });
  }

  /**
   * @description event handler para cuando el usuario arrastra archivos
   * saliendo de la región dispuesta para ello.
   * En esta función sólo establecemos un nuevo estado indicando que ya no hay
   *  `dragOver` y así quitar estilo.
   * @param {Event} e
   */
  handleDragLeave(e) {
    e.preventDefault();
    this.setState({
      dragOver: false,
    });
  }

  /**
   * @description Función que permite generar una colección limpia de archivos.
   * @param {Array} entries colección de colecciones y/o archivos resueltos.
   */
  mapEntries(entries) {
    let files = [];

    if (!Array.isArray(entries)) {
      files.push(entries);
    } else {
      for (let i = 0; i < entries.length; i++) {
        /**
         * recursión si la entrada i es un array
         */
        if (Array.isArray(entries[i]))
          files = files.concat(this.mapEntries(entries[i]));
        else files.push(entries[i]);
      }
    }

    return files;
  }

  /**
   * @description Transforma un valor en tipo Boolean
   * @param {*} input valor que representa un boolean
   */
  bool(input) {
    if (typeof input === "boolean") return input;
    if (input == null) return false;
    return parseInt(input) > 0 || String(input).toLowerCase() === "true";
  }

  /**
   * @description Función que permite obtener la/las clases para el contenedor `fileupload`
   */
  getClasses() {
    const classes = ["fileupload"];
    if (this.state.dragOver) classes.push("drag-over");
    if (this.state.progress > 0) classes.push("uploading");
    return classes.join(" ");
  }

  render() {
    const progressWidth = {
      width: `${this.state.progress}%`,
    };

    return (
      <div
        className={this.getClasses()}
        onDrop={this.handleDrop}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
      >
        <input
          type="file"
          name={this.props.name || ""}
          ref={this.inputFile}
          multiple={this.bool(this.props.multiple)}
          accept={this.getTypes().join(",")}
        />

        <div className="fileupload-progress">
          <div style={progressWidth} />
        </div>

        <div className="fileupload-footer">
          <FontAwesomeIcon
            icon="file-upload"
            size="3x"
            className="icono-file-upload"
          />
          <label>Arrastrar y soltar</label>
          <label>o</label>
          {this.state.progress === 0 ? (
            <button type="button" onClick={this.handleClick}>
              Cargar
            </button>
          ) : (
            <span className="text-cargando-imagen mt-2">
              Espera, por favor...
            </span>
          )}
        </div>
      </div>
    );
  }
}
