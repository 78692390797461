import React, { Component } from 'react';
import HomeRest from 'commons/services/HomeRest';
import DestacadosImplTec from 'commons/components/destacados/DestacadosImplTec';
import {FILTRO_REGION} from 'commons/constants';
import { connect } from 'react-redux';
import { destacadosTecData } from 'commons/redux/actions/destacadosAction';

class DestacadosProviderTec extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        configuracion: {},
        destacados: [],
      },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(destacadosTecData(null));

    if(sessionStorage.getItem(FILTRO_REGION) != null && sessionStorage.getItem(FILTRO_REGION) === 'true') {
      this.findDestacadosTecFiltro();
     }else{
      this.findDestacadosTec();
    }
  }

  findDestacadosTecFiltro(){
    const self = this;
    const data = {
      region: sessionStorage.getItem(FILTRO_REGION)
    };
    HomeRest.findDestacadosTecFiltro(data).then((destacados) => {
      self.setState({
        params: {
          configuracion: (destacados !== null && destacados.data !== null) ? destacados.data.configuracion : {},
          destacados: (destacados !== null && destacados.data !== null) ? destacados.data.contenido : [],
        },
      });
    });
  }

  findDestacadosTec(){
     const self = this;
     HomeRest.findDestacadosTec().then((destacados) => {
       self.setState({
         params: {
           configuracion: (destacados !== null && destacados.data !== null) ? destacados.data.configuracion : {},
           destacados: (destacados !== null && destacados.data !== null) ? destacados.data.contenido : [],
         },
       });
     });
  }

  render() {
    const { params } = this.state;
    return (
      <DestacadosImplTec params={params} />
    );
  }
}

const mapStateToProps = (state) => ({
  destacadosTecProps: state.destacadosTecProps
});

export default connect(
  mapStateToProps,
)(DestacadosProviderTec);
