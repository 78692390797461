import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import LabelMapperProvider from 'commons/components/LabelMapperProvider';
import DispatchComponent from 'commons/components/DispatchComponent';
import MainContainer from './MainContainer';

export default () =>
    <LabelMapperProvider>
        <DispatchComponent />
        <BrowserRouter >
            <MainContainer />
        </BrowserRouter>
    </LabelMapperProvider>
