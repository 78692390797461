import BaseService from "./base/BaseService";

export default class ParameterService extends BaseService {
  static getRegion(param, data) {
    return super.get("/parameter/getRegiones", param, data);
  }
  static getComuna(param, data) {
    return super.get("/parameter/getComunas", param, data);
  }
  static getCategorias(param, data) {
    return super.get("/parameter/getCategorias", param, data);
  }
  static getServicios(param, data) {
    return super.get("/parameter/getServicios", param, data);
  }
}
