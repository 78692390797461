import React, { Component } from 'react';
import { MDBContainer, MDBCol, MDBTestimonial, MDBAvatar } from 'mdbreact';
import LabelMapper from 'commons/components/LabelMapper';
import { DestacadoDetalle, Inicio } from 'commons/constants';
import { Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

class Testimonios extends Component {

  render() {
    const itemsResponsive = {
      0: {
        items: 1,
      },
      1024: {
        items: 4
      }
    };
    if (this.props.params.testimonios.contenido === undefined || this.props.params.testimonios.contenido.length <= 0) return null;
    return (
      <MDBContainer className="pb-5" >
        <section className="text-center pt-3 pb-5">
          <h4 className="titulo mb-5"><LabelMapper label="TESTIMONIOS.TITULO" /></h4>
          <AliceCarousel mouseTrackingEnabled responsive={itemsResponsive} buttonsDisabled={true} >
            {
              this.props.params.testimonios.contenido.map((img, key) => (
                <MDBCol key={key}>
                  <MDBTestimonial>
                    <MDBAvatar className="mx-auto d-flex justify-content-center">
                      <div className="rounded-circle fotoCardTestimonios shadow" style={{ backgroundImage: `url(${img.url})` }} />
                    </MDBAvatar>
                    <Link to={{
                      pathname: DestacadoDetalle + img.idEmpresa,
                      search: "?" + new URLSearchParams({
                        origen: Inicio,
                      }).toString()
                    }} className="link" >
                      <h5 className="titulo-testimonio pt-3" > {img.titulo} </h5>
                    </Link>
                    <h6 className="nombre-creador-testimonio pt-1 pb-2"> {img.userName} </h6>
                    <h6 className="descripcion-testimonio my-2">"{img.descripcion}"</h6>
                    <p className="descripcion-testimonio">
                      {/* <MDBIcon icon="quote-left" className="pr-2" /> */}
                      {`${img.comentarios != null ? img.comentarios[0].descripcion : ''}`}
                    </p>
                  </MDBTestimonial>
                </MDBCol>
              ))
            }
          </AliceCarousel>
        </section>
      </MDBContainer>
    );
  }
}

export default Testimonios;
