import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from '@ns-react/modal';
import LoginService from 'commons/services/LoginService';
import HomeRest from 'commons/services/HomeRest';
import { MDBCol, MDBRow, MDBContainer, MDBBtn } from "mdbreact";
import CloseButton from 'commons/components/CloseButton';
import { alertObligatorio, modal, alertV2 } from 'commons/util';
import { AUTH_NOMBRE, AUTH_EMAIL, MSJE_ERROR } from 'commons/constants';
import ModalConfirmacionSolicitud from './ModalConfirmacionSolicitud';
import TagManager from 'react-gtm-module';

class ModalSolicitudContacto extends Modal {
  constructor() {
    super();
    this.state = {
      nombreCompleto: sessionStorage.getItem(AUTH_NOMBRE),
      email: sessionStorage.getItem(AUTH_EMAIL),
      asunto: '',
      comentarios: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.enviar = this.enviar.bind(this);
    this.cancelar = this.cancelar.bind(this);
  }

  cancelar() {
    super.closeModal();
  }

  validaEmail(email) {
    let reg = /^[a-z0-9][a-z0-9-_.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9]).[a-z0-9]{2,10}(?:.[a-z]{2,10})?$/;
    return reg.test(email);
  }

  validar() {
    let { comentarios } = this.state;
    let items = [];

    // if (asunto === undefined || asunto.trim() === '') items.push('Asunto');
    if (comentarios === undefined || comentarios.trim() === '') items.push('Escriba el mensaje ');

    if (items.length > 0) {
      alertObligatorio('Campos obligatorios', items);
      return false;
    } else {
      return true;
    }
  }

  enviar() {
    let self = this;
    if (this.validar()) {

      let { nombreCompleto, email, asunto, comentarios } = this.state;
      let data = {
        nombre: nombreCompleto,
        mail: email,
        asunto: asunto,
        comentario: comentarios,
        idEmpresa: this.props.idEmpresa
      } 

      LoginService.resfrescarTicket().then(function () {
        HomeRest.solicitudContacto(data).then(function (response) {
            if (response.status === 200){
              self.registrarContacto(self.props.origen, self.props.idEmpresa);
              self.cancelar();
              modal(ModalConfirmacionSolicitud);
            }else {
              alertV2('Aviso', MSJE_ERROR);
            }
        });
      });

    }
  }

  registrarContacto = (origen, idEmpresa) => {
    let tagManagerArgs = {
      dataLayer: {
        'event': 'Contacto',
        'category': 'Contacto',
        'action': 'Envio',
        'var_etiqueta': origen,
        'var_valor': idEmpresa,
      },
      dataLayerName: 'Sernatur'
    }
    TagManager.dataLayer(tagManagerArgs);
  }

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <Modal size="small">
        <ModalHeader>
          <CloseButton onClick={this.cancelar}></CloseButton>
        </ModalHeader>
        <ModalBody>
          <div className="modal-body" >
            <MDBContainer>
                <div className="row mb-4" >
                  <div className="col" >
                    <h5 className="modal-contactar-titulo" >Contactar</h5>
                    <span className="modal-contactar-descripcion" >Escribe el mensaje a enviar</span>
                  </div>
                </div>
                {/* <div className="row" >
                  <div className="col-md-12" >
                    <div className="form-group" >
                      <label className="mb-0" >Asunto</label><strong className="lbl-obligatorio pl-1" >*</strong>
                      <input
                        type="text"
                        name="asunto"
                        className="form-control"
                        value={this.state.asunto}
                        onChange={this.handleChange}
                      ></input>
                    </div>
                  </div>
                </div> */}
                <div className="row" >
                  <div className="col-md-12" >
                    <div className="form-group" >
                      {/* <label className="mb-0" >Comentarios</label><strong className="lbl-obligatorio pl-1" >*</strong> */}
                      <textarea className="form-control" name="comentarios" rows="6" 
                      value={this.state.comentarios} onChange={this.handleChange} ></textarea>
                    </div>
                  </div>
                </div>
            </MDBContainer>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="modal-footer" >
            <MDBContainer className="d-flex justify-content-center">
              <MDBRow>
                <MDBCol className="d-flex justify-content-center">
                  <MDBBtn outline rounded className="btn-secundario-modal-sm" onClick={this.cancelar}  >
                    Cancelar
                  </MDBBtn>
                  <MDBBtn rounded className="btn-primario-modal-sm" onClick={this.enviar} >
                    Enviar
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </ModalFooter>
      </Modal >
    );
  }
}

export default ModalSolicitudContacto;
