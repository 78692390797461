import React, { Component } from 'react';
import HomeRest from 'commons/services/HomeRest';
import DestacadosImplTur from 'commons/components/destacados/DestacadosImplTur';
import {FILTRO_REGION} from 'commons/constants';
import { connect } from 'react-redux';
import { destacadosTurData } from 'commons/redux/actions/destacadosAction';

class DestacadosProviderTur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        configuracion: {},
        destacados: [],
      },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(destacadosTurData(null));

    if(sessionStorage.getItem(FILTRO_REGION) != null && sessionStorage.getItem(FILTRO_REGION) === 'true') {
      this.findDestacadosTurFiltro();
     }else{
      this.findDestacadosTur();
    }
  }

  findDestacadosTurFiltro(){
    const self = this;
    const data = {
      region: sessionStorage.getItem(FILTRO_REGION)
    };  
    HomeRest.findDestacadosTurFiltro(data).then((destacados) => {
      self.setState({
        params : {
          configuracion: (destacados !== null && destacados.data !== null) ? destacados.data.configuracion : {},
          destacados: (destacados !== null && destacados.data !== null) ? destacados.data.contenido : [],
        }
     });
   });
  }

  findDestacadosTur(){
     const self = this;
     HomeRest.findDestacadosTur().then((destacados) => {
      self.setState({
        params : {
          configuracion: (destacados !== null && destacados.data !== null) ? destacados.data.configuracion : {},
          destacados: (destacados !== null && destacados.data !== null) ? destacados.data.contenido : [],
        }
     });
     });
  }

  render() {
    const { params } = this.state;
    return (
      <DestacadosImplTur params={params} />
    );
  }
}

const mapStateToProps = (state) => ({
  destacadosTurProps: state.destacadosTurProps
});

export default connect(
  mapStateToProps,
)(DestacadosProviderTur);

