import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "@ns-react/modal";
import LoginService from "commons/services/LoginService";
import { MDBCol, MDBRow, MDBContainer, MDBBtn } from "mdbreact";
import CloseButton from "commons/components/CloseButton";
import { alertV2, alertObligatorio } from "commons/util";
import { MSJE_ERROR } from "commons/constants";
import { modal } from "commons/util";
import LoginModal from "commons/components/sesion/LoginModal";

class ModalRecuperaPassword extends Modal {
  constructor() {
    super();
    this.state = {
      activo: "turismo",
      usuario: "",
    };
    this.handleInputUsuario = this.handleInputUsuario.bind(this);
    this.recuperar = this.recuperar.bind(this);
    this.cancelar = this.cancelar.bind(this);
  }

  cancelar() {
    super.closeModal();
  }

  handleInputUsuario(e) {
    let { usuario } = this.state;
    usuario = e.target.value.trim();
    this.setState({ usuario });
  }

  validar() {
    let { usuario } = this.state;
    let items = [];

    if (usuario === undefined || usuario.trim() === "") items.push("Usuario");

    if (items.length > 0) {
      alertObligatorio("Campos obligatorios", items);
      return false;
    } else {
      return true;
    }
  }

  recuperar() {
    let self = this;
    if (this.validar()) {
      let { usuario } = this.state;
      let params = {};
      let data = {
        username: usuario,
      };

      LoginService.recuperaPassword(params, data).then(function (returnData) {
        if (returnData.status === 200) {
          self.cancelar();
        } else if (returnData.status === 409) {
          const error = returnData.data.mensaje;
          alertV2("Aviso", error);
        } else {
          alertV2("Aviso", MSJE_ERROR);
        }
      });
    }
  }

  modalLogin = () => {
    this.cancelar();
    modal(LoginModal, { crearEmpresa: this.crearEmpresa });
  };

  render() {
    return (
      <Modal size="small">
        <ModalHeader>
          <div className="modal-header">
            <h5>Recuperar contraseña</h5>
          </div>
          <CloseButton onClick={this.cancelar}></CloseButton>
        </ModalHeader>
        <ModalBody>
          <div className="modal-body">
            <MDBContainer>
              <MDBRow>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Usuario</label>
                    <strong className="lbl-obligatorio pl-1">*</strong>
                    <input
                      type="text"
                      name="userName"
                      className="form-control"
                      value={this.state.usuario}
                      onChange={this.handleInputUsuario}
                    ></input>
                  </div>
                </div>
              </MDBRow>
            </MDBContainer>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="modal-footer">
            <MDBContainer>
              <MDBRow>
                <MDBCol className="d-flex justify-content-between">
                  <button
                    className="button-link volverLogin"
                    onClick={this.modalLogin}
                  >
                    Volver a inicio de sesión
                  </button>
                  <MDBBtn
                    rounded
                    className="btn-primario-modal-sm"
                    onClick={this.recuperar}
                  >
                    {/* <LabelMapper label="COMUN.OLVIDEPASSWORD" /> */}{" "}
                    Recuperar
                  </MDBBtn>
                  {/* <MDBBtn rounded className="btn-primario-modal-sm" onClick={this.cancelar} >
                    <LabelMapper label="COMUN.CANCELAR" />
                  </MDBBtn> */}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalRecuperaPassword;
