import {
  LOAD_MAPPER_MAP,
} from '../actionTypes';

/**
 * @description Envía al store la data `map` de los labels
 * obtenidos
 */
export const loadMapperMap = (resp) => ({
  type: LOAD_MAPPER_MAP,
  payload: {
    map: resp.data,
    status: resp.status,
  },
  error: resp.status > 299,
});
