import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * @author Sinecio Bermúdez
 * @description Componente botón a usar en el header de las alertas y modales
 */
export default (props) => {
    let cssClass = (props.className || '').split(' ');
    cssClass.push('btn-close');
    return <button {...props} className={cssClass.join(' ').trim()}>
        <FontAwesomeIcon icon="times"/>
    </button>
}
