import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createNewID } from 'commons/util';

const CategoriasImpl = ({ empresa }) => (
  <section className="empresa-categorias__list">
    {
      empresa.categorias.map((cat) => (
        <div className="list__item" key={createNewID()}>
          <FontAwesomeIcon
            icon={['far', 'check-circle']}
            color="#4A91E3"
            size="lg"
          />
          <span className="ml-2">
            {' '}
            {cat.descripcion}
            {' '}
          </span>
          <ul className="pl-5">
            {cat.subCategorias.map((sub) => <li>{sub.descripcion}</li>)}
          </ul>
        </div>
      ))
    }
  </section>
);


export default CategoriasImpl;
