import React from 'react';
import { Route } from 'react-router-dom';
import CarouselHome from 'home/components/CarouselHome';
import TestimoniosProvider from 'home/components/TestimoniosProvider';
import DestacadosProviderTur from 'home/components/DestacadosProviderTur';
import DestacadosProviderTec from 'home/components/DestacadosProviderTec';
import DestacadosProviderVideo from 'home/components/DestacadosProviderVideo';
import DestacadoDetalle from 'commons/components/detalle/DestacadoDetalle';
import DashboardContainer from 'home/components/dashboard/DashboardContainer';
import DescubreMatch from 'home/components/DescubreMatch';
import ErrorApp from 'commons/components/error/ErrorApp';

/**
 * @author Sinecio Bermúdez Jacque
 * @description Componente que define el body de la aplicación
 */
export default () => (
  <React.Fragment>
      <Route path="/dashboard/:id/:content" exact component={DashboardContainer} />
      <Route path="/" exact component={CarouselHome} />
      <Route path="/" exact component={DescubreMatch} />
      <Route path="/" exact component={DestacadosProviderVideo} />
      <Route path="/" exact component={DestacadosProviderTur} />
      <Route path="/" exact component={DestacadosProviderTec} />      
      <Route path="/" exact component={TestimoniosProvider} />
      <Route path="/destacado/detalle/:idEmpresa" exact component={DestacadoDetalle} />
      <Route path="/error/general/" exact component={ErrorApp} />
  </React.Fragment>
);

