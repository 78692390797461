import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from '@ns-react/modal';
import { MDBCol, MDBRow, MDBContainer, MDBBtn, MDBStep, MDBStepper } from "mdbreact";
import CloseButton from 'commons/components/CloseButton';
import { alertObligatorio } from 'commons/util';
import Select from 'react-select';
import MatchService from '../../commons/services/MatchService';
import LoginService from '../../commons/services/LoginService';
import ParameterService from '../../commons/services/ParameterService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class ModalRecuperaPassword extends Modal {
  constructor() {
    super();
    this.state = {
      categoria: [],
      subCategoria: [],
      lstCategorias: [],
      lstSubCategorias: [],
      preguntaCategoria: '',
      preguntaSubcategoria: '',
      preguntaServicio: '',
      servicio: [],
      tipoEmpresa: '',
      lstServicios: [],
      formActivePanel: 1,
      flagMostrarVolver: false,
      idPreguntaCategoria: 0,
      idPreguntaSubCategoria: 0,
      idPreguntaServicio: 0,
      lstRespuestas: [],
      lstRespuestasServicio: []
    };
    this.handleCategoria = this.handleCategoria.bind(this);
    this.handleSubCategoria = this.handleSubCategoria.bind(this);
    this.handleServicio = this.handleServicio.bind(this);
    this.volverPasoAnterior = this.volverPasoAnterior.bind(this);
    this.pasoSiguiente = this.pasoSiguiente.bind(this);
    this.obtenerMatch = this.obtenerMatch.bind(this);
    this.hacerMatch = this.hacerMatch.bind(this);
    this.cancelar = this.cancelar.bind(this);
  }

  componentDidMount() {
    this.obtenerMatch();
  }

  obtenerMatch() {
    let self = this;
    let { idPreguntaCategoria, preguntaCategoria, lstCategorias,
      idPreguntaSubCategoria, preguntaSubcategoria,
      idPreguntaServicio, preguntaServicio, tipoEmpresa } = this.state;
    LoginService.resfrescarTicket().then(function () {

      MatchService.preguntasMatch().then(function (response) {
        let { data } = response;

        if (data.length > 0) {
          idPreguntaCategoria = data[0].id;
          preguntaCategoria = data[0].descripcion;
          lstCategorias = data[0].categorias;
          idPreguntaSubCategoria = data.length > 1 ? data[1].id : '';
          preguntaSubcategoria = data.length > 1 ? data[1].descripcion : '';
          idPreguntaServicio = data.length > 2 ? data[2].id : '';
          preguntaServicio = data.length > 2 ? data[2].descripcion : '';
          tipoEmpresa = data[0].tipoEmpresa;

          self.setState({
            idPreguntaCategoria,
            preguntaCategoria,
            lstCategorias,
            idPreguntaSubCategoria,
            preguntaSubcategoria,
            idPreguntaServicio,
            preguntaServicio,
            tipoEmpresa
          })

          if (tipoEmpresa === 'TEC') {
            ParameterService.getRegion().then((ServicioReg) => {
              if (ServicioReg.status === 200) {
                self.setState({
                  lstServicios: ServicioReg.data,
                });
              }
            })
          }
        }
      });

    })

  }

  cancelar() {
    this.guardar = false;
    super.closeModal();
  }

  componentWillUnmount() {
    if (this.guardar) {
      this.props.verMatch();
    }
  }

  handleCategoria(event) {
    let { categoria } = this.state;
    categoria = event === null ? '' : event;

    this.setState({ categoria, subCategoria: [] });
  }

  handleSubCategoria(event) {
    let { lstRespuestas } = this.state;
    let subCategoria = event === null ? '' : event;

    if (lstRespuestas !== null && lstRespuestas.length > 0) {
      lstRespuestas = [];
    }

    this.setState({ subCategoria, lstRespuestas });
  }

  handleServicio(event) {
    let { lstRespuestasServicio } = this.state;
    let servicio = event === null ? '' : event;

    if (lstRespuestasServicio !== null && lstRespuestasServicio.length > 0) {
      lstRespuestasServicio = [];
    }

    this.setState({ servicio, lstRespuestasServicio });
  }

  irAlPaso(nroPaso) {
    let { formActivePanel } = this.state;
    switch (nroPaso) {
      case 1:
        this.setState({
          formActivePanel: nroPaso,
          flagMostrarVolver: false
        });
        break;
      case 2:
        if (nroPaso !== formActivePanel) {
          if (this.validarPaso1()) {
            this.cargarPasoSubcategorias();
            this.guardarPaso(formActivePanel);
          }
        }
        break;
      case 3:
        if (nroPaso !== formActivePanel) {
          if (this.validarPaso2()) {
            this.cargarTercerPaso();
            this.guardarPaso(formActivePanel);
          }
        }
        break;
      default:
        break;
    }
  }

  cargarPasoSubcategorias() {
    let { lstCategorias, categoria, lstSubCategorias, tipoEmpresa } = this.state;

    if (tipoEmpresa === 'TEC') {
      lstSubCategorias = [];
      for (let x = 0; x < categoria.length; x++) {
        for (let i = 0; i < lstCategorias.length; i++) {
          if (lstCategorias[i].id === categoria[x].id) {
            lstSubCategorias = lstSubCategorias.concat(lstCategorias[i].subCategorias);
          }
        }
      }
    } else if (tipoEmpresa === 'TUR') {
      lstSubCategorias = [];
      for (let x = 0; x < categoria.length; x++) {
        for (let i = 0; i < lstCategorias.length; i++) {
          if (lstCategorias[i].id === categoria[x].id) {
            lstSubCategorias = lstSubCategorias.concat(lstCategorias[i].servicios);
          }
        }
      }
    }

    this.setState({
      formActivePanel: 2,
      lstSubCategorias,
      flagMostrarVolver: true
    });
  }

  cargarTercerPaso() {
    let { lstSubCategorias, subCategoria, lstServicios, tipoEmpresa } = this.state;

    if (tipoEmpresa === 'TUR') {
      lstServicios = [];
      for (let x = 0; x < subCategoria.length; x++) {
        for (let i = 0; i < lstSubCategorias.length; i++) {
          if (lstSubCategorias[i].id === subCategoria[x].id) {
            lstServicios = lstServicios.concat(lstSubCategorias[i].subCategorias);
          }
        }
      }
    }

    this.setState({
      formActivePanel: 3,
      lstServicios,
      flagMostrarVolver: true
    });
  }

  volverPasoAnterior() {
    let { formActivePanel, flagMostrarVolver } = this.state;
    let nroPaso = formActivePanel - 1;

    if (nroPaso === 1) {
      flagMostrarVolver = false;
    }

    this.setState({
      formActivePanel: nroPaso,
      flagMostrarVolver
    });
  }

  pasoSiguiente() {
    let { formActivePanel } = this.state;

    switch (formActivePanel) {
      case 1:
        if (this.validarPaso1()) {
          this.cargarPasoSubcategorias();
          this.guardarPaso(formActivePanel);
          formActivePanel = formActivePanel + 1;
        }
        break;
      case 2:
        //if (this.validarPaso2()) {
          this.cargarTercerPaso()
          this.guardarPaso(formActivePanel);
          formActivePanel = formActivePanel + 1;
        //}
        break;
      case 3:
        //if (this.validarPaso3()) {
          this.guardarPaso(formActivePanel);
          formActivePanel = formActivePanel + 1;
        //}
        break;
      default:
        break;
    }

    this.setState({
      formActivePanel: formActivePanel,
    });
  }

  validarPaso1() {
    let { categoria, preguntaCategoria } = this.state;
    let items = [];

    if (categoria === undefined || categoria.length === 0) items.push('Pregunta: ' + preguntaCategoria);

    if (items.length > 0) {
      alertObligatorio('Campos obligatorios', items);
      return false;
    } else {
      return true;
    }
  }

  validarPaso2() {
    let { subCategoria, preguntaSubcategoria } = this.state;
    let items = [];

    if (subCategoria === undefined || subCategoria.length === 0) items.push('Pregunta: ' + preguntaSubcategoria);

    if (items.length > 0) {
      alertObligatorio('Campos obligatorios', items);
      return false;
    } else {
      return true;
    }
  }

  validarPaso3() {
    let { servicio, preguntaServicio } = this.state;
    let items = [];

    if (servicio === undefined || servicio.length === 0) items.push('Pregunta: ' + preguntaServicio);

    if (items.length > 0) {
      alertObligatorio('Campos obligatorios', items);
      return false;
    } else {
      return true;
    }
  }

  guardarPaso(nroPaso) {

    let self = this;
    let { idPreguntaCategoria, categoria, idPreguntaSubCategoria, subCategoria,
      idPreguntaServicio, servicio } = this.state;
    let data = [];

    switch (nroPaso) {
      case 1:
        for (let i = 0; i < categoria.length; i++) {
          data.push({
            'idPregunta': idPreguntaCategoria,
            'opcion': categoria[i].id
          })
        }
        LoginService.resfrescarTicket().then(function () {
          MatchService.guadarMatch(data);
        });
        break;
      case 2:
        for (let i = 0; i < subCategoria.length; i++) {
          data.push({
            'idPregunta': idPreguntaSubCategoria,
            'opcion': subCategoria[i].id
          })
        }
        LoginService.resfrescarTicket().then(function () {
          MatchService.guadarMatch(data);
        });
        break;
      case 3:
        for (let i = 0; i < servicio.length; i++) {
          data.push({
            'idPregunta': idPreguntaServicio,
            'opcion': servicio[i].id
          })
        }
        LoginService.resfrescarTicket().then(function () {
          MatchService.guadarMatch(data).then(function () {
            self.hacerMatch();
          });
        });
        break;
      default:
        break;
    }
  }

  hacerMatch() {
    this.guardar = true;
    super.closeModal();
  }

  render() {
    return (
      <Modal size="medium" className="wizardMatch" >
        <ModalHeader>
          <CloseButton onClick={this.cancelar}></CloseButton>
        </ModalHeader>
        <ModalBody>
          <div className="modal-body" >
            <MDBContainer>
              <MDBRow className="mb-5" >
                {/* <MDBCol className="col-md-2 d-none d-md-block" /> */}
                <MDBCol>
                  <MDBStepper form className="d-flex justify-content-center" >
                    <MDBStep form >
                      <a href="/" onClick={e => { e.preventDefault(); this.irAlPaso(1) }} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} >
                        <MDBBtn color={this.state.formActivePanel >= 1 ? "step" : "disable"} circle>
                          {
                            this.state.formActivePanel === 1
                              ? "1"
                              : <FontAwesomeIcon icon={["fas", "check"]}
                                color="#ffffff"
                                size="lg"
                              />
                          }
                        </MDBBtn>
                      </a>
                      <p className={this.state.formActivePanel >= 1 ? "step-txt-state-on" : ""}>Categoría</p>
                    </MDBStep>
                    <MDBStep form>
                      <a href="/" onClick={e => { e.preventDefault(); this.irAlPaso(2) }} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} >
                        <MDBBtn color={this.state.formActivePanel >= 2 ? "step" : "disable"} circle>
                          {
                            this.state.formActivePanel < 3
                              ? "2"
                              : <FontAwesomeIcon icon={["fas", "check"]}
                                color="#ffffff"
                                size="lg"
                              />
                          }
                        </MDBBtn>
                      </a>
                      {
                        this.state.tipoEmpresa === 'TEC'
                          ? <p className={this.state.formActivePanel >= 2 ? "step-txt-state-on" : ""}>Sub-Categoría</p>
                          : <p className={this.state.formActivePanel >= 2 ? "step-txt-state-on" : ""}>Servicio</p>
                      }

                    </MDBStep>
                    <MDBStep form>
                      <a href="/" onClick={e => { e.preventDefault(); this.irAlPaso(3) }} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} >
                        <MDBBtn color={this.state.formActivePanel >= 3 ? "step" : "disable"} circle>
                          {
                            this.state.formActivePanel < 4
                              ? "3"
                              : <FontAwesomeIcon icon={["fas", "check"]}
                                color="#ffffff"
                                size="lg"
                              />
                          }
                        </MDBBtn>
                      </a>
                      {
                        this.state.tipoEmpresa === 'TEC'
                          ? <p className={this.state.formActivePanel >= 2 ? "step-txt-state-on" : ""}>Servicio</p>
                          : <p className={this.state.formActivePanel >= 2 ? "step-txt-state-on" : ""}>Sub-Categoría</p>
                      }
                    </MDBStep>
                  </MDBStepper>
                </MDBCol>
                {/* <MDBCol className="col-md-2 d-none d-md-block" /> */}
              </MDBRow>
              <form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} >
                <MDBRow className="d-flex justify-content-center" >
                  {this.state.formActivePanel === 1 && (
                    <div className="col-md-10" >
                      <div className="form-group" >
                        <label className="lbl-group-match" >{this.state.preguntaCategoria}<strong className="lbl-obligatorio pl-1" >*</strong></label>
                        <Select
                          isMulti
                          className="basic-single mb-2"
                          classNamePrefix="select"
                          placeholder="Seleccione"
                          isClearable="true"
                          isSearchable="true"
                          getOptionLabel={(option) => option.descripcion}
                          getOptionValue={(option) => option.id}
                          value={this.state.categoria}
                          // value={this.state.lstCategorias.filter(option => option.id === this.state.idCategoria)}
                          options={this.state.lstCategorias}
                          onChange={this.handleCategoria}
                        />
                        <sup>* Puedes elegir más de una categoría</sup>
                      </div>
                    </div>
                  )}
                  {this.state.formActivePanel === 2 && (
                    <div className="col-md-10" >
                      <div className="form-group" >
                        <label className="lbl-group-match" >{this.state.preguntaSubcategoria}<strong className="lbl-obligatorio pl-1" >*</strong></label>
                        <Select
                          isMulti
                          className="basic-single mb-2"
                          classNamePrefix="select"
                          placeholder="Seleccione"
                          isClearable="true"
                          isSearchable="true"
                          getOptionLabel={(option) => option.descripcion}
                          getOptionValue={(option) => option.id}
                          value={this.state.subCategoria}
                          options={this.state.lstSubCategorias}
                          onChange={this.handleSubCategoria}
                        />
                        {
                          this.state.tipoEmpresa === 'TEC'
                            ? <sup>* Puedes elegir más de una sub-categoría</sup>
                            : <sup>* Puedes elegir más de un servicio</sup>
                        }
                      </div>
                    </div>
                  )}
                  {this.state.formActivePanel === 3 && (
                    <div className="col-md-10" >
                      <div className="form-group" >
                        <label className="lbl-group-match" >{this.state.preguntaServicio}<strong className="lbl-obligatorio pl-1" >*</strong></label>
                        {
                          this.state.tipoEmpresa === 'TEC'
                            ?
                            <React.Fragment>
                              <Select
                                isMulti
                                className="basic-single mb-2"
                                classNamePrefix="select"
                                placeholder="Seleccione"
                                isClearable="true"
                                isSearchable="true"
                                getOptionLabel={(option) => option.text}
                                getOptionValue={(option) => option.id}
                                value={this.state.servicio}
                                options={this.state.lstServicios}
                                onChange={this.handleServicio}
                              />
                              <sup>* Puedes elegir más de un servicio</sup>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <Select
                                isMulti
                                className="basic-single mb-2"
                                classNamePrefix="select"
                                placeholder="Seleccione"
                                isClearable="true"
                                isSearchable="true"
                                getOptionLabel={(option) => option.descripcion}
                                getOptionValue={(option) => option.id}
                                value={this.state.servicio}
                                options={this.state.lstServicios}
                                onChange={this.handleServicio}
                              />
                              <sup>* Puedes elegir más de una sub-categoría</sup>
                            </React.Fragment>

                        }
                      </div>
                    </div>
                  )}
                </MDBRow>
              </form>
            </MDBContainer>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="modal-footer w-100 mt-4" >
            <MDBContainer className="p-0" >
              <MDBRow>
                <MDBCol className="d-flex justify-content-start">
                  {
                    this.state.flagMostrarVolver ?
                      <MDBBtn outline rounded className="btn-secundario-modal-sm" onClick={e => { e.preventDefault(); this.volverPasoAnterior() }}  >
                        Anterior
                      </MDBBtn>
                      :
                      null
                  }
                </MDBCol>
                <MDBCol className="d-flex justify-content-end">
                  {
                    this.state.formActivePanel < 3 ?
                      <MDBBtn rounded className="btn-primario-modal-sm" onClick={e => { e.preventDefault(); this.pasoSiguiente() }}  >
                        Siguiente
                      </MDBBtn>
                      :
                      <MDBBtn rounded className="btn-primario-modal-sm" onClick={e => { e.preventDefault(); this.pasoSiguiente() }}  >
                        Hacer Match
                      </MDBBtn>
                  }
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </ModalFooter>
      </Modal >
    );
  }
}

export default ModalRecuperaPassword;
