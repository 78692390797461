import fetchIntercept from 'fetch-intercept';
import { AUTH_KEY } from 'commons/constants';
import store from 'commons/redux/store';
import { fetchingData, fetchingDataComplete } from 'commons/redux/actions/loadingIconActions';

/**
 * @description cantidad de peticiones
 */
let count = 0;

/**
 * @author Sinecio Bermúdez Jacque
 * @description Interceptor para peticiones utilizando fetch.
 * Permite configurar una petición antes de realizarla.
 */
const unregister = fetchIntercept.register({
  request,
  requestError,
  response,
  responseError,
});

/**
 * @description Configura una petición antes de que sea enviada al backend
 * @param {String} url endpoint al cual se realiza una petición
 * @param {Object} config objeto literal con la configuración de la petición
 */
function request(url, config) {
  if (count <= 0) store.dispatch(fetchingData());
  count++;
  const defaults = {
    headers: new Headers({
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_KEY) === null ? '' : sessionStorage.getItem(AUTH_KEY) }`,
    }),
  };
  Object.assign(defaults, config);
  return [url, defaults];
}

/**
 * @description Realiza acción al recibir un error de una petición.
 * @param {Object} error Objeto de respuesta
 */
function requestError(error) {
  if (count > 0) count--;
  if (count <= 0) store.dispatch(fetchingDataComplete());
  return Promise.reject(error);
}

/**
 * @description Permite modificar una respuesta recibida de una Petición
 * @param {Object} response Objeto de respuesta.
 */
function response(response) {
  if (count > 0) count--;
  if (count <= 0) store.dispatch(fetchingDataComplete());
  return response;
}

/**
 * @description Permite manipular un error
 * @param {Object} error  Objeto de respuesta.
 */
function responseError(error) {
  if (count > 0) count--;
  if (count <= 0) store.dispatch(fetchingDataComplete());
  return Promise.reject(error);
}


export default unregister;
