import React from 'react';

export default class InputText extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.value = '';
  }

  componentWillReceiveProps(props) {
    this.value = this.getFormatedValue(props.value == null ? this.value : props.value);
  }

  /**
     * @description función que permite formatear un valor de un input de texto
     * para representar números con decimales si es indicado por props.
     * @param {String} input valor del input a formatear
     */
  getFormatedValue(input) {
    return input;
  }

  /**
     * @description Event handler del input de texto.
     * @param {Event} event
     */
  handleChange(event) {
    this.value = event.currentTarget.value = this.getFormatedValue(event.currentTarget.value);
    if (typeof this.props.onChange !== 'function') return;
    this.props.onChange(event);
  }

  render() {
    return (
      <input {...this.props} type="text" value={this.value} onChange={this.handleChange} />
    );
  }
}
