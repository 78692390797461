import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from '@ns-react/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default class ModalAlert extends Modal {
  constructor(props) {
    super(props);

    this.aceptar = this.aceptar.bind(this);
  }


  componentWillUnmount() {
    if (typeof this.props.onClose == 'function') this.props.onClose();
  }

  aceptar() {
    super.closeAlert();
  }
  render() {
    return (
      <Modal>
        <ModalHeader>
          <div className="modal-header modal-header-error" >
            <FontAwesomeIcon icon="exclamation-circle" />&nbsp;
              <span>{this.props.title === undefined ? 'Mensaje' : this.props.title}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="modal-body" >
            {this.props.mensaje}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="modal-footer modal-footer-alert" >
            <button className="btn btn-md btn-modal-primary" onClick={this.aceptar}>Aceptar</button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
