import BaseService from './base/BaseService';
import { BASE_URL, CREDENTIALS, AUTH_KEY, AUTH_REFRESH_KEY, KEY_EXPIRE, KEY_REFRESH_EXPIRE } from 'commons/constants';
import { parsePath, joinUrl } from 'commons/util';

export default class LoginService extends BaseService {
    
    static login(param, data) {
        return super.post('/home/login', param, data);
    }

    static resfrescarTicket() {
        let data = {
            refresh_token: sessionStorage.getItem(AUTH_REFRESH_KEY)
        }

        return super.post('/home/refreshToken', null, data).then((response) => {
             if (response.status === 200) {
                 sessionStorage.setItem(AUTH_KEY, response.data.access_token);
                 sessionStorage.setItem(KEY_EXPIRE, response.data.expires_in * 1000);
                 sessionStorage.setItem(AUTH_REFRESH_KEY, response.data.refresh_token);
                 sessionStorage.setItem(KEY_REFRESH_EXPIRE, response.data.refresh_expires_in * 1000);
                 return response;
             }else {
                 sessionStorage.clear();
                 window.location.href = origin;
             }
        });
    }

    static guardarUsuario(param, data) {
        return super.post('/user/createUser', param, data);
    }

    static actualizaUsuario(param, data) {
        return super.put('/user/updateUser', param, data);
    }

    static recuperaUsuario(param, data) {
        return super.get('/user/getUser', param, data);
    }

    static recuperaPassword(param, data) {
        return super.post('/user/resetPassword', param, data);
    }
    
    static saveFilter(data) {
        return super.post('/user/saveFilter', null, data);
    }

    static guardarUsuarioTxt(params, data) {
        var url = '/user/createUser';
        return fetch(joinUrl(BASE_URL, parsePath(url, params)), {
            credentials: CREDENTIALS,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                 Authorization: `Bearer ${sessionStorage.getItem(AUTH_KEY)}`,
                'Access-Control-Expose-Headers': "msg"
            },
        }).then(response => {
            return response.text().then(resp => {
                return {
                    status: response.status,
                    msg: response.msg,
                    data: resp
                }
            }).catch(() => {
                return {
                    status: response.status,
                    data: response,
                }
            })

        });
    }
}