import React, { useState } from "react";
import { alertV2, success } from "commons/util";
import FileUpload from "commons/components/FileUpload";
import {
  MSG_NOMBRE_EMP,
  MSG_DESC_LARGA_EMP,
  MSG_DESC_MIN_LARGA_EMP,
  MSG_SITIOWEB_EMP,
  EMPTY_ERROR,
  MSG_TAMANO_IMG,
  MSG_EXTENSION_IMG,
  JPEG,
  JPG,
  PNG,
  PDF,
  ZIP,
} from "commons/constants";
import PostulationServices from "commons/services/PostulationServices";
import { MDBRow, MDBBtn, MDBInput, MDBCol } from "mdbreact";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { rutValidate, rutFormat } from "rut-helpers";
import * as Yup from "yup";
import validator from "validator";

const Validation = Yup.object().shape({
  nombreEmpresa: Yup.string().max(30, MSG_NOMBRE_EMP).required(EMPTY_ERROR),
  rutEmpresa: Yup.string().required(EMPTY_ERROR),
  descripconEmpresa: Yup.string()
    .min(20, MSG_DESC_MIN_LARGA_EMP)
    .max(600, MSG_DESC_LARGA_EMP)
    .required(EMPTY_ERROR),
  preguntasRespuestas: Yup.array().of(
    Yup.object().shape({
      promotionSite: Yup.string().required(EMPTY_ERROR),
      experiences: Yup.string().required(EMPTY_ERROR),
      firstCompanyName: Yup.string().when("experiences", {
        is: "si",
        then: Yup.string().required(EMPTY_ERROR),
        otherwise: Yup.string().notRequired(),
      }),
      firstCompanyPhone: Yup.number().when("experiences", {
        is: "si",
        then: Yup.number().required(EMPTY_ERROR),
        otherwise: Yup.number().notRequired(),
      }),
      firstCompanyMail: Yup.string().when("experiences", {
        is: "si",
        then: Yup.string().email("Correo invalido").required(EMPTY_ERROR),
        otherwise: Yup.string().notRequired(),
      }),
      secondCompanyPhone: Yup.number().when("experiences", {
        is: "si",
        then: Yup.number().notRequired(),
        otherwise: Yup.number().notRequired(),
      }),
      secondCompanyMail: Yup.string().when("experiences", {
        is: "si",
        then: Yup.string().email("Correo invalido"),
        otherwise: Yup.string().notRequired(),
      }),
      thirdCompanyPhone: Yup.number().when("experiences", {
        is: "si",
        then: Yup.number().notRequired(),
        otherwise: Yup.number().notRequired(),
      }),
      thirdCompanyMail: Yup.string().when("experiences", {
        is: "si",
        then: Yup.string().email("Correo invalido"),
        otherwise: Yup.string().notRequired(),
      }),
      textAreaSi: Yup.string().when("experiences", {
        is: "si",
        then: Yup.string().required(EMPTY_ERROR),
        otherwise: Yup.string().notRequired(),
      }),
      textAreaNo: Yup.string().when("experiences", {
        is: "no",
        then: Yup.string().required(EMPTY_ERROR),
        otherwise: Yup.string().notRequired(),
      }),
      validatedBy: Yup.array().required(EMPTY_ERROR),
      knowHow: Yup.array().required(EMPTY_ERROR),
    })
  ),
  // postulationFile: Yup.array().required(EMPTY_ERROR),
  // .required(EMPTY_ERROR)
  // .test(
  //   "fileSize",
  //   "El archivo excede el tamaño",
  //   (value) => value && value.size <= FILE_SIZE
  // )
  // .test(
  //   "fileFormat",
  //   "Formato incorrecto",
  //   (value) => value && SUPPORTED_FORMATS.includes(value.type)
  // ),
});

export const PostulationForm = () => {
  const [experience, setExperience] = useState("");
  const [other, setOther] = useState(false);
  const [countDescripconEmpresa, setCountDescripconEmpresa] = useState(0);
  const [countTextAreaNo, setCountTextAreaNo] = useState(0);
  const [countTextAreaSi, setCountTextAreaSi] = useState(0);
  const [countOthers, setCountOthers] = useState(0);
  const [storePostulationFile, setStorePostulationFile] = useState([]);
  const [storeEmpresa01, setStoreEmpresa01] = useState([]);
  const [storeEmpresa02, setStoreEmpresa02] = useState([]);
  const [storeEmpresa03, setStoreEmpresa03] = useState([]);

  const handleOnSubmit = (values) => {
    console.log("handleOnSubmit -> values", values);
    const formValues = new FormData();

    if (storePostulationFile.constructor === File) {
      formValues.append(
        "storePostulationFile",
        storePostulationFile,
        storePostulationFile.name
      );
    }
    if (storeEmpresa01.constructor === File) {
      formValues.append("storeEmpresa01", storeEmpresa01, storeEmpresa01.name);
    }
    if (storeEmpresa02.constructor === File) {
      formValues.append("storeEmpresa02", storeEmpresa02, storeEmpresa02.name);
    }
    if (storeEmpresa03.constructor === File) {
      formValues.append("storeEmpresa03", storeEmpresa03, storeEmpresa03.name);
    }
    let blob = new Blob([JSON.stringify(values)], { type: "application/json" });
    formValues.append("empresa", blob);

    PostulationServices.guardarPostulacion("", formValues)
      .then((result) => {
        console.log("handleOnSubmit -> result", result);
        if (result.status === 201) {
          success("Aviso", "Postulación enviada correctamente");
        } else if (result.status === 417) {
          alertV2("Aviso", result.data.mensaje);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getFiles(newfiles, inputName) {
    if (newfiles !== undefined) {
      const archivo = newfiles[0].name;
      var extension = archivo.slice(((archivo.lastIndexOf(".") - 1) >>> 0) + 2);

      if (
        extension.toLowerCase() === JPEG ||
        extension.toLowerCase() === JPG ||
        extension.toLowerCase() === PNG ||
        extension.toLowerCase() === PDF ||
        extension.toLowerCase() === ZIP
      ) {
        var fileSize = newfiles[0].size;
        var sizekiloByte = parseInt(fileSize / 1024);
        var sizeMaxkb = 2 * 1024 * 1024;

        if (sizekiloByte < sizeMaxkb) {
          switch (inputName) {
            case "preguntasRespuestas[0].postulationFile":
              return setStorePostulationFile(newfiles[0]);
            case "preguntasRespuestas[0].empresa01":
              return setStoreEmpresa01(newfiles[0]);
            case "preguntasRespuestas[0].empresa02":
              return setStoreEmpresa02(newfiles[0]);
            case "preguntasRespuestas[0].empresa03":
              return setStoreEmpresa03(newfiles[0]);
            default:
              return "";
          }
        } else {
          alertV2("Aviso", MSG_TAMANO_IMG);
        }
      } else {
        alertV2("Aviso", MSG_EXTENSION_IMG);
      }
    }
  }

  function validacionSitioWebEmpresa(e) {
    if (e.target.value.length > 0) {
      if (!validator.isURL(e.target.value, { require_protocol: true })) {
        alertV2("Aviso", MSG_SITIOWEB_EMP);
      }
    }
  }

  function validateRut(value) {
    if (!rutValidate(value)) {
      alertV2("Aviso", "RUT inválido");
      return true;
    }
    return false;
  }

  return (
    <Formik
      initialValues={{
        nombreEmpresa: "",
        rutEmpresa: "",
        descripconEmpresa: "",
        preguntasRespuestas: [
          {
            experiences: "",
            firstCompanyName: "",
            firstCompanyPhone: "",
            firstCompanyMail: "",
            secondCompanyName: "",
            secondCompanyPhone: "",
            secondCompanyMail: "",
            thirdCompanyName: "",
            thirdCompanyPhone: "",
            thirdCompanyMail: "",
            textAreaNo: "",
            textAreaSi: "",
            validatedBy: [],
            knowHow: [],
            others: "",
            promotionSite: "",
          },
        ],
      }}
      validateOnChange={false}
      validationSchema={Validation}
      onSubmit={handleOnSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
          <MDBRow className="mt-3">
            <label htmlFor="rutEmpresa">
              RUT Empresa
              <strong className="lbl-obligatorio pl-1">*</strong>
            </label>
            <Field
              name="rutEmpresa"
              validate={validateRut}
              onChange={(e) =>
                setFieldValue("rutEmpresa", rutFormat(e.target.value))
              }
              className="form-control"
            />
            <ErrorMessage
              component="span"
              className="text-danger"
              name="rutEmpresa"
            />
          </MDBRow>
          <MDBRow className="mt-3">
            <label htmlFor="nombreEmpresa">
              Nombre Empresa<strong className="lbl-obligatorio pl-1">*</strong>
            </label>
            <Field className="form-control" name="nombreEmpresa" type="text" />
            <ErrorMessage
              component="span"
              className="text-danger"
              name="nombreEmpresa"
            />
          </MDBRow>
          <MDBRow className="mt-3">
            <MDBCol size="12" className="px-0">
              <label htmlFor="descripconEmpresa">
                Descripción<strong className="lbl-obligatorio pl-1">*</strong>
              </label>
              <Field
                className="form-control"
                name="descripconEmpresa"
                component="textarea"
                rows="10"
                placeholder="Máximo 600 caracteres"
                maxLength="600"
                onChange={(e) => {
                  setCountDescripconEmpresa(e.target.value.length);
                  setFieldValue("descripconEmpresa", e.target.value);
                }}
              />
            </MDBCol>
            <MDBCol size="12" className="px-0">
              <span className="contadorCaracter">
                Caracteres restantes:{" "}
                <span>{600 - countDescripconEmpresa}</span>
              </span>
            </MDBCol>
            <MDBCol size="12" className="px-0">
              <ErrorMessage
                component="span"
                className="text-danger"
                name="descripconEmpresa"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <span className="campos mt-3">
              ¿Tiene experiencia previa ofertando al sector turistico?
              <strong className="lbl-obligatorio pl-1">*</strong>
            </span>
          </MDBRow>
          <MDBRow>
            <Field
              as={MDBInput}
              name="preguntasRespuestas[0].experiences"
              checked={experience === "si"}
              onChange={() => {
                setExperience("si");
                setFieldValue("preguntasRespuestas[0].experiences", "si");
              }}
              label="Si"
              type="radio"
              id="radiosi"
              className="form-control"
            />
            <Field
              as={MDBInput}
              name="preguntasRespuestas[0].experiences"
              checked={experience === "no"}
              onChange={() => {
                setExperience("no");
                setFieldValue("preguntasRespuestas[0].experiences", "no");
              }}
              label="No"
              type="radio"
              id="radiono"
              className="form-control"
            />
            <MDBCol size="12" className="px-0">
              <ErrorMessage
                component="span"
                className="text-danger"
                name="preguntasRespuestas[0].experiences"
              />
            </MDBCol>
          </MDBRow>
          {experience === "no" && (
            <MDBRow>
              <MDBCol size="12" className="px-0">
                <span className="campos my-3">
                  ¿Por qué le interesa ofertar sus servicios al sector, cuál es
                  su oferta de valor para las empresas de turismo?
                  <strong className="lbl-obligatorio pl-1">*</strong>
                </span>
                <Field
                  name="preguntasRespuestas[0].textAreaNo"
                  component="textarea"
                  rows="5"
                  className="form-control"
                  placeholder="Máximo 600 caracteres"
                  maxLength="600"
                  onChange={(e) => {
                    setCountTextAreaNo(e.target.value.length);
                    setFieldValue(
                      "preguntasRespuestas[0].textAreaNo",
                      e.target.value
                    );
                  }}
                />
              </MDBCol>
              <MDBCol size="12" className="px-0">
                <label className="contadorCaracter">
                  Caracteres restantes: <span>{600 - countTextAreaNo}</span>
                </label>
              </MDBCol>
              <MDBCol size="12" className="px-0">
                <ErrorMessage
                  component="span"
                  className="text-danger"
                  name="preguntasRespuestas[0].textAreaNo"
                />
              </MDBCol>
            </MDBRow>
          )}
          {experience === "si" && (
            <>
              <MDBRow>
                <MDBCol size="12" className="px-0">
                  <span className="campos my-3">
                    ¿Cuál es su oferta de valor para las empresas de turismo?
                    <strong className="lbl-obligatorio pl-1">*</strong>
                  </span>
                  <Field
                    name="preguntasRespuestas[0].textAreaSi"
                    component="textarea"
                    rows="5"
                    className="form-control"
                    placeholder="Máximo 600 caracteres"
                    maxLength="600"
                    onChange={(e) => {
                      setCountTextAreaSi(e.target.value.length);
                      setFieldValue(
                        "preguntasRespuestas[0].textAreaSi",
                        e.target.value
                      );
                    }}
                  />
                </MDBCol>
                <MDBCol size="12" className="px-0">
                  <label className="contadorCaracter">
                    Caracteres restantes: <span>{600 - countTextAreaSi}</span>
                  </label>
                </MDBCol>
                <MDBCol size="12" className="px-0">
                  <ErrorMessage
                    component="span"
                    className="text-danger"
                    name="preguntasRespuestas[0].textAreaSi"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <span className="campos my-3">
                  Adjunte 3 facturas o nombre de 3 empresas turisticas a las
                  cuales ha realizado servicios durante el ultimo año
                </span>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol size="6" className="pl-0">
                  <label>
                    Nombre Empresa 01
                    <strong className="lbl-obligatorio pl-1">*</strong>
                  </label>
                  <Field
                    name="preguntasRespuestas[0].firstCompanyName"
                    className="form-control"
                    autoComplete="off"
                    maxLength="50"
                  />
                  <ErrorMessage
                    component="span"
                    className="text-danger"
                    name="preguntasRespuestas[0].firstCompanyName"
                  />
                </MDBCol>
                <MDBCol size="6" className="pr-0">
                  <label>
                    Télefono Empresa 01
                    <strong className="lbl-obligatorio pl-1">*</strong>
                  </label>
                  <Field
                    name="preguntasRespuestas[0].firstCompanyPhone"
                    className="form-control"
                    autoComplete="off"
                    maxLength="50"
                  />
                  <ErrorMessage
                    component="span"
                    className="text-danger"
                    name="preguntasRespuestas[0].firstCompanyPhone"
                  />
                </MDBCol>
                <MDBCol size="12" className="mt-3 px-0">
                  <label>
                    Mail Empresa 01
                    <strong className="lbl-obligatorio pl-1">*</strong>
                  </label>
                  <Field
                    name="preguntasRespuestas[0].firstCompanyMail"
                    className="form-control"
                    autoComplete="off"
                    maxLength="50"
                  />
                  <ErrorMessage
                    component="span"
                    className="text-danger"
                    name="preguntasRespuestas[0].firstCompanyMail"
                  />
                </MDBCol>
                <MDBCol className="mt-3 px-0">
                  <FileUpload
                    endpoint="postulacion/save"
                    name="preguntasRespuestas[0].empresa01"
                    params=""
                    data=""
                    multiple="false"
                    isAutomaticUpload={false}
                    onSelectFiles={getFiles}
                  />
                  {storeEmpresa01.name && (
                    <MDBCol size="12" className="px-0">
                      <label className="texto-imagen-temp">
                        * Haz click en POSTULAR para guardar el archivo:{" "}
                        {storeEmpresa01.name}
                      </label>
                    </MDBCol>
                  )}
                </MDBCol>
              </MDBRow>
              <hr />
              <MDBRow className="mb-3">
                <MDBCol size="6" className="pl-0">
                  <label>Nombre Empresa 02</label>
                  <Field
                    name="preguntasRespuestas[0].secondCompanyName"
                    className="form-control"
                    autoComplete="off"
                    maxLength="50"
                  />
                </MDBCol>
                <MDBCol size="6" className="pr-0">
                  <label>Télefono Empresa 02</label>
                  <Field
                    name="preguntasRespuestas[0].secondCompanyPhone"
                    className="form-control"
                    autoComplete="off"
                    maxLength="50"
                  />
                  <ErrorMessage
                    component="span"
                    className="text-danger"
                    name="preguntasRespuestas[0].secondCompanyPhone"
                  />
                </MDBCol>
                <MDBCol size="12" className="mt-3 px-0">
                  <label>Mail Empresa 02</label>
                  <Field
                    name="preguntasRespuestas[0].secondCompanyMail"
                    className="form-control"
                    autoComplete="off"
                    maxLength="50"
                  />
                  <ErrorMessage
                    component="span"
                    className="text-danger"
                    name="preguntasRespuestas[0].secondCompanyMail"
                  />
                </MDBCol>
                <MDBCol className="mt-3 px-0">
                  <FileUpload
                    endpoint="postulacion/save"
                    name="preguntasRespuestas[0].empresa02"
                    params=""
                    data=""
                    multiple="false"
                    isAutomaticUpload={false}
                    onSelectFiles={getFiles}
                  />
                  {storeEmpresa02.name && (
                    <MDBCol size="12" className="px-0">
                      <label className="texto-imagen-temp">
                        * Haz click en POSTULAR para guardar el archivo:{" "}
                        {storeEmpresa02.name}
                      </label>
                    </MDBCol>
                  )}
                </MDBCol>
              </MDBRow>
              <hr />
              <MDBRow className="mb-3">
                <MDBCol size="6" className="pl-0">
                  <label>Nombre Empresa 03</label>
                  <Field
                    name="preguntasRespuestas[0].thirdCompanyName"
                    className="form-control"
                    autoComplete="off"
                    maxLength="50"
                  />
                </MDBCol>
                <MDBCol size="6" className="pr-0">
                  <label>Télefono Empresa 03</label>
                  <Field
                    name="preguntasRespuestas[0].thirdCompanyPhone"
                    className="form-control"
                    autoComplete="off"
                    maxLength="50"
                  />
                  <ErrorMessage
                    component="span"
                    className="text-danger"
                    name="preguntasRespuestas[0].thirdCompanyPhone"
                  />
                </MDBCol>
                <MDBCol size="12" className="mt-3 px-0">
                  <label>Mail Empresa 03</label>
                  <Field
                    name="preguntasRespuestas[0].thirdCompanyMail"
                    className="form-control"
                    autoComplete="off"
                    maxLength="50"
                  />
                  <ErrorMessage
                    component="span"
                    className="text-danger"
                    name="preguntasRespuestas[0].thirdCompanyMail"
                  />
                </MDBCol>
                <MDBCol className="mt-3 px-0">
                  <FileUpload
                    endpoint="postulacion/save"
                    name="preguntasRespuestas[0].empresa03"
                    params=""
                    data=""
                    multiple="false"
                    isAutomaticUpload={false}
                    onSelectFiles={getFiles}
                  />
                  {storeEmpresa03.name && (
                    <MDBCol size="12" className="px-0">
                      <label className="texto-imagen-temp">
                        * Haz click en POSTULAR para guardar el archivo:{" "}
                        {storeEmpresa03.name}
                      </label>
                    </MDBCol>
                  )}
                </MDBCol>
              </MDBRow>
            </>
          )}
          <MDBRow className="mb-3">
            <label>
              ¿Proviene o ha sido validado por alguna de las siguientes
              instituciones?
              <strong className="lbl-obligatorio pl-1">*</strong>
            </label>
            <MDBCol size="4" className="font-small">
              <Field
                as={MDBInput}
                name="preguntasRespuestas[0].validatedBy"
                label="Incubadoras"
                value="incubadoras"
                type="checkbox"
                id="checkbox1"
              />
            </MDBCol>
            <MDBCol size="4" className="font-small">
              <Field
                as={MDBInput}
                name="preguntasRespuestas[0].validatedBy"
                label="aceleradoras"
                value="aceleradoras"
                type="checkbox"
                id="checkbox2"
              />
            </MDBCol>
            <MDBCol size="4" className="font-small">
              <Field
                as={MDBInput}
                name="preguntasRespuestas[0].validatedBy"
                label="Corfo"
                value="corfo"
                type="checkbox"
                id="checkbox3"
              />
            </MDBCol>
            <MDBCol size="4" className="font-small">
              <Field
                as={MDBInput}
                name="preguntasRespuestas[0].validatedBy"
                label="CET"
                value="CET"
                type="checkbox"
                id="checkbox4"
              />
            </MDBCol>
            <MDBCol size="4" className="font-small">
              <Field
                as={MDBInput}
                name="preguntasRespuestas[0].validatedBy"
                label="Programas Sernatur"
                value="sernatur"
                type="checkbox"
                id="checkbox5"
              />
            </MDBCol>
            <MDBCol size="4" className="font-small">
              <Field
                as={MDBInput}
                name="preguntasRespuestas[0].validatedBy"
                label="Ninguno"
                value="Ninguno"
                type="checkbox"
                id="checkbox6"
              />
            </MDBCol>
            <MDBCol size="12" className="px-0">
              <ErrorMessage
                component="span"
                className="text-danger"
                name="preguntasRespuestas[0].validatedBy"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="mb-3">
            <MDBCol size="12" className="px-0">
              <label>
                ¿Cómo se enteró del programa?
                <strong className="lbl-obligatorio pl-1">*</strong>
              </label>
            </MDBCol>
            <MDBCol size="4" className="font-small">
              <Field
                as={MDBInput}
                name="preguntasRespuestas[0].knowHow"
                label="RRSS"
                value="RRSS"
                type="checkbox"
                id="checkbox7"
              />
            </MDBCol>
            <MDBCol size="4" className="font-small">
              <Field
                as={MDBInput}
                name="preguntasRespuestas[0].knowHow"
                label="Página web"
                value="web"
                type="checkbox"
                id="checkbox8"
              />
            </MDBCol>
            <MDBCol size="4" className="font-small">
              <Field
                as={MDBInput}
                name="preguntasRespuestas[0].knowHow"
                labelClass="text-nowrap"
                label="Invitación de Sernatur y/o Subse"
                value="sernatur"
                type="checkbox"
                id="checkbox9"
              />
            </MDBCol>
            <MDBCol size="4" className="font-small">
              <Field
                as={MDBInput}
                name="preguntasRespuestas[0].knowHow"
                checked={other}
                onClick={() => setOther(!other)}
                label="Otros"
                value="otros"
                type="checkbox"
                id="checkbox10"
              />
            </MDBCol>
            <MDBCol size="4" className="font-small">
              <Field
                as={MDBInput}
                name="preguntasRespuestas[0].knowHow"
                label="Ninguno"
                value="ninguno"
                type="checkbox"
                id="checkbox11"
              />
            </MDBCol>
            <MDBCol size="12" className="px-0">
              <ErrorMessage
                component="span"
                className="text-danger"
                name="preguntasRespuestas[0].knowHow"
              />
            </MDBCol>
            {other && (
              <>
                <MDBCol size="12" className="px-0">
                  <Field
                    name="preguntasRespuestas[0].others"
                    component="textarea"
                    rows="5"
                    className="form-control"
                    placeholder="Máximo 600 caracteres"
                    maxLength="600"
                    onChange={(e) => {
                      setCountOthers(e.target.value.length);
                      setFieldValue(
                        "preguntasRespuestas[0].others",
                        e.target.value
                      );
                    }}
                  />
                </MDBCol>
                <MDBCol size="12" className="px-0">
                  <label className="contadorCaracter">
                    Caracteres restantes: <span>{600 - countOthers}</span>
                  </label>
                </MDBCol>
                <MDBCol size="12" className="px-0">
                  <ErrorMessage
                    component="span"
                    className="text-danger"
                    name="preguntasRespuestas[0].others"
                  />
                </MDBCol>
              </>
            )}
          </MDBRow>
          <MDBRow className="mb-3">
            <label>
              Pagina web o redes sociales
              <strong className="lbl-obligatorio pl-1">*</strong>
            </label>
            <Field
              name="preguntasRespuestas[0].promotionSite"
              onBlur={validacionSitioWebEmpresa}
              className="form-control"
            />
            <MDBCol size="12" className="px-0">
              <ErrorMessage
                component="span"
                className="text-danger"
                name="preguntasRespuestas[0].promotionSite"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <label>
              Presentación de la empresa y sus servicios (resumen ejecutivo)
              <strong className="lbl-obligatorio pl-1">*</strong>
            </label>
            <FileUpload
              endpoint="postulacion/save"
              name="preguntasRespuestas[0].postulationFile"
              params=""
              data=""
              multiple="false"
              isAutomaticUpload={false}
              onSelectFiles={getFiles}
            />
            {storePostulationFile.name && (
              <MDBCol size="12" className="px-0">
                <label className="texto-imagen-temp">
                  * Haz click en POSTULAR para guardar el archivo:{" "}
                  {storePostulationFile.name}
                </label>
              </MDBCol>
            )}
          </MDBRow>
          {/* <MDBRow>
            <MDBCol size="12">
              <pre> {JSON.stringify(values, null, 2)}</pre>
            </MDBCol>
          </MDBRow> */}
          <MDBRow className="d-flex justify-content-center mt-5">
            <MDBBtn rounded className="btn-primario" type="submit">
              Postular
            </MDBBtn>
          </MDBRow>
        </Form>
      )}
    </Formik>
  );
};

export default PostulationForm;
