import React, { Component } from 'react';
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBRow, 
    MDBCol, MDBBtn, MDBCardImage, MDBCardTitle, MDBCard, MDBCardBody,
    MDBContainer } from 'mdbreact';
import LabelMapper from 'commons/components/LabelMapper';
import { createNewID } from 'commons/util';
import { Link } from 'react-router-dom';
import { DestacadoDetalle } from 'commons/constants';

class MisMegusta extends Component {
    obtenerImagenesAgrupadas() {
        const grupos = [];
        const { params } = this.props;
        const destacados = [].concat(params.destacados);
        const esResponsive = window.innerWidth <= 766;
        if (!destacados || destacados.length <= 0) return grupos;
        while (destacados[0]) {
            grupos.push(destacados.splice(0, esResponsive ? 1 : 4));
        }
        return grupos;
    }

    render() {
        const grupos = this.obtenerImagenesAgrupadas();
        if (grupos.length <= 0) return null;
        return (
            <div className="fondo-favoritos">
                <MDBContainer>
                    <section className="text-left">
                        <h5 className="titulo" ><LabelMapper label="MISMEGUSTA.MISMEGUSTATITULO" /></h5>
                        <MDBCarousel
                            activeItem={1}
                            length={grupos.length}
                            slide
                            showControls={false}
                            showIndicators
                            interval={false}
                            multiItem
                        >
                            <MDBCarouselInner>
                                {
                                    grupos.map((imagenes, i) => (
                                        <MDBCarouselItem itemId={i + 1} key={createNewID()}>
                                            <MDBRow>
                                                {
                                                    imagenes.map((img) => (
                                                        <MDBCol key={createNewID()} md="3">
                                                            <MDBCard className="mb-2 card-container pb-2">
                                                                <MDBCardImage className="card-img-top fotoCardDestacados" style={{ backgroundImage: `url(${img.url})` }} />
                                                                <MDBCardBody>
                                                                    <MDBCardTitle className="tituloDestacado"> {img.titulo} </MDBCardTitle>
                                                                    <span className="descripcionDestacado"> {img.descripcion} </span>
                                                                </MDBCardBody>
                                                                <div>
                                                                    <Link to={{
                                                                        pathname: DestacadoDetalle + img.idEmpresa,
                                                                        search: "?" + new URLSearchParams({
                                                                            origen: window.location.href,
                                                                        }).toString()
                                                                    }} >
                                                                        <MDBBtn className="btn btn-sm buttonDestacados" >
                                                                            <LabelMapper label="DESTACADOS.BUTTON1" />
                                                                        </MDBBtn>
                                                                    </Link>
                                                                </div>
                                                            </MDBCard>
                                                        </MDBCol>
                                                    ))
                                                }
                                            </MDBRow>
                                        </MDBCarouselItem>
                                    ))
                                }
                            </MDBCarouselInner>
                        </MDBCarousel>
                    </section>
                </MDBContainer>
            </div>
        );
    }
}
export default MisMegusta;
