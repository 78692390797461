import { parsePath, joinUrl } from "commons/util";
import { BASE_URL, CREDENTIALS } from "commons/constants";
import { AUTH_KEY } from "commons/constants";
export * from "./interceptor";

/**
 * @author Sinecio Bermúdez Jacque
 * @description Clase base de la que deben heredar todos los servicios.
 * permite la abstracción de peticiones utilizando fetch.
 */
export default class BaseService {
  /**
   * @author Sinecio Bermúdez Jacque
   * @description Permite realizar una petición utilizando el método `GET`
   * @param {String} url url relativa al endpoint, puede incluir variables del tipo `{var}`
   * que serán parseadas utilizando los parámetros indicados en el objeto literal `params`
   * @param {Object} params objeto literal donde cada propiedad y valor serán utilizados para construir
   * el path y el queryString en el parámetro `url`
   */
  static get(url, params) {
    return fetch(joinUrl(BASE_URL, parsePath(url, params)), {
      method: "GET",
      credentials: CREDENTIALS,
    })
      .then((response) =>
        response.json().then((resp) => ({
          status: response.status,
          data: resp,
        }))
      )
      .catch((response) => ({
        status: response.status,
        data: null,
      }));
  }

  /**
   * @author Sinecio Bermúdez Jacque
   * @description Permite realizar una petición utilizando el método `PUT`
   * @param {String} url url relativa al endpoint, puede incluir variables del tipo `{var}`
   * que serán parseadas utilizando los parámetros indicados en el objeto literal `params`
   * @param {Object} params objeto literal donde cada propiedad y valor serán utilizados para construir
   * el path y el queryString en el parámetro `url`.
   * @param {Object} data datos para enviar en el cuerpo de la petición.
   */
  static post(url, params, data) {
    return fetch(joinUrl(BASE_URL, parsePath(url, params)), {
      credentials: CREDENTIALS,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem(AUTH_KEY)}`,
        "Access-Control-Expose-Headers": "msg",
      },
    }).then((response) => {
      return response
        .json()
        .then((resp) => {
          return {
            status: response.status,
            data: resp,
          };
        })
        .catch(() => {
          return {
            status: response.status,
            data: response,
          };
        });
    });
  }

  static postXwwwForm(url, params, data) {
    return fetch(joinUrl(BASE_URL, parsePath(url, params)), {
      credentials: CREDENTIALS,
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((response) =>
      response.json().then((resp) => ({
        status: response.status,
        data: resp,
      }))
    );
  }

  static postMultipartFormData(url, params, data) {
    return fetch(joinUrl(BASE_URL, parsePath(url, params)), {
      credentials: CREDENTIALS,
      method: "POST",
      body: data,
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem(AUTH_KEY)}`,
      },
    }).then((response) =>
      response.json().then((resp) => ({
        status: response.status,
        data: resp,
      }))
    );
  }

  /**
   * @author Sinecio Bermúdez Jacque
   * @description Permite realizar una petición utilizando el método `POST`
   * @param {String} url url relativa al endpoint, puede incluir variables del tipo `{var}`
   * que serán parseadas utilizando los parámetros indicados en el objeto literal `params`
   * @param {Object} params objeto literal donde cada propiedad y valor serán utilizados para construir
   * el path y el queryString en el parámetro `url`.
   * @param {Object} data datos para enviar en el cuerpo de la petición.
   */
  static put(url, params, data) {
    return fetch(joinUrl(BASE_URL, parsePath(url, params)), {
      credentials: CREDENTIALS,
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem(AUTH_KEY)}`,
      },
    }).then((response) => {
      return response
        .json()
        .then((resp) => {
          return {
            status: response.status,
            data: resp,
          };
        })
        .catch(() => {
          return {
            status: response.status,
            data: response.type,
          };
        });
    });
  }

  /**
   * @author Sinecio Bermúdez Jacque
   * @description Permite realizar una petición utilizando el método `DELETE`
   * @param {String} url url relativa al endpoint, puede incluir variables del tipo `{var}`
   * que serán parseadas utilizando los parámetros indicados en el objeto literal `params`
   * @param {Object} params objeto literal donde cada propiedad y valor serán utilizados para construir
   * el path y el queryString en el parámetro `url`.
   * @param {Object} data datos para enviar en el cuerpo de la petición.
   */
  static delete(url, params, data) {
    return fetch(joinUrl(BASE_URL, parsePath(url, params)), {
      credentials: CREDENTIALS,
      method: "DELETE",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) =>
      response.json().then((resp) => ({
        status: response.status,
        data: resp,
      }))
    );
  }
}
