import {
    MATCH,
} from '../actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case MATCH:
            {
                return action.payload.data;
            }
        default:
            return state;
    }
};