import React from 'react';
import {
    Modal, ModalBody, ModalFooter,
} from '@ns-react/modal';
import { MDBCol, MDBRow, MDBContainer, MDBBtn } from "mdbreact";

class ModalBienvenida extends Modal {

    constructor(props) {
        super(props);
        this.cerrar = this.cerrar.bind(this);
    }

    cerrar() {
        super.closeModal();
    }

    componentWillUnmount() {
        this.props.crearEmpresa();
    }

    render() {
        return (
            <Modal size="small">
                <ModalBody>
                    <div className="modal-body" >
                        <MDBContainer>
                            <p className="text-center" style={{ fontSize: "18px" }} >
                                <strong style={{ color: "#4A91E3", fontWeight: "bold" }} >Bienvenid@ {this.props.nombre}</strong><br /> a Conecta Turismo.<br />
                            </p>
                            <p className="text-center" >
                                Completa los datos de tu empresa para poder utilizar el match.
                            </p>
                        </MDBContainer>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="modal-footer float-none" >
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol className="d-flex justify-content-center">
                                    <MDBBtn rounded className="btn-primario-modal-sm" onClick={this.cerrar} >
                                        Aceptar
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </div>
                </ModalFooter>
            </Modal >
        );
    }
}

export default ModalBienvenida;
