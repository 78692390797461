import {
  FETCHING_DATA,
  FETCHING_DATA_COMPLETE,
} from '../actionTypes';

/**
 * @description define el estado para el componente `loadingIcon` de acuerdo
 * a la acción notificada.
 */
export default (state = false, action) => {
  switch (action.type) {
    case FETCHING_DATA: {
      /**
			 * no hay condiciones, sólo informa el nuevo valor
			 * desde `action.payload.isFetching`
			 */
      return action.payload.isFetching;
    }

    case FETCHING_DATA_COMPLETE: {
      /**
			 * no hay condiciones, sólo informa el nuevo valor
			 * desde `action.payload.isFetching`
			 */
      return action.payload.isFetching;
    }

    default: return state;
  }
};
