import {
    MENSAJES,
    MSG_BANDEJA,
} from '../actionTypes';

/**
 * @description Envía el contador de msg pendientes
 */
export const mensajesData = (data) => ({
    type: MENSAJES,
    payload: {
        data: data
    },
    error: false,
});

/**
 * @description Envía los msg a la bandeja de entrada
 */
export const msgBandejaData = (data) => ({
    type: MSG_BANDEJA,
    payload: {
        data: data
    },
    error: false,
});