import React, { Component } from 'react';
import InputText from 'commons/components/InputText';
import LoginService from 'commons/services/LoginService';
import { alertV2, success, alertObligatorio } from 'commons/util';
import LabelMapper from 'commons/components/LabelMapper';
import { MDBContainer, MDBRow, MDBCol, MDBInput } from 'mdbreact';
import { connect } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { PREFIX_TELEFONO_PAIS, MSJE_ERROR } from 'commons/constants';
import TagManager from 'react-gtm-module';

class RegistroUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localNombre: '',
      apellido: '',
      email: '',
      telefono: '',
      localPassword: '',
      rePasword: '',
      fechaNacimiento: '',
      terminos: false,
    };
    this.handleInputNombre = this.handleInputNombre.bind(this);
    this.handleInputEmail = this.handleInputEmail.bind(this);
    this.onKeyPressCelular = this.onKeyPressCelular.bind(this);
    this.handleInputCelular = this.handleInputCelular.bind(this);
    this.handleInputPassword = this.handleInputPassword.bind(this);
    this.handleInputRePassword = this.handleInputRePassword.bind(this);
    this.validaPassword = this.validaPassword.bind(this);
    this.guardar = this.guardar.bind(this);
    this.handleInputTerminos = this.handleInputTerminos.bind(this);
  }

  handleInputTerminos(e) {
    let { terminos } = this.state;
    terminos = e.target.checked;
    this.setState({ terminos });
  }

  validaPassword() {
    let { rePasword, localPassword } = this.state;
    if (localPassword !== '' && rePasword !== '') {
      if (localPassword !== rePasword) {
        rePasword = '';
        localPassword = '';

        this.setState({ rePasword, localPassword });

        return false;
      } else {
        return true;
      }
    }
  }

  validaNombre(e) {
    let { localNombre } = this.state;
    this.setState({ localNombre });
  }

  handleInputNombre(e) {
    let { localNombre } = this.state;
    localNombre = e.target.value;
    this.setState({ localNombre });
  }

  handleInputEmail(e) {
    let { email } = this.state;
    email = e.target.value.trim();
    email = email.toLowerCase();
    this.setState({ email });
  }

  handleInputCelular(e) {
    let { telefono } = this.state;
    telefono = e.target.value;
    this.setState({ telefono });
  }

  onKeyPressCelular(e) {
    let { telefono } = this.state;
    telefono = e.target.value;

    var out = '';
    var filtro = '1234567890';//Caracteres validos

    //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos 
    for (var i = 0; i < telefono.length; i++)
      if (filtro.indexOf(telefono.charAt(i)) !== -1)
        //Se añaden a la salida los caracteres validos
        out += telefono.charAt(i);

    telefono = out;
    this.setState({ telefono });
  }

  handleInputPassword(e) {
    let { localPassword } = this.state;
    localPassword = e.target.value;
    this.setState({ localPassword });
  }

  handleInputRePassword(e) {
    let { rePasword } = this.state;
    rePasword = e.target.value;
    this.setState({ rePasword });
  }

  validaEmail(email) {
    let reg = /^[a-z0-9][a-z0-9-_.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9]).[a-z0-9]{2,10}(?:.[a-z]{2,10})?$/;
    return reg.test(email);
  }

  validaCelular() {
    let { telefono } = this.state;

    let phoneNumber = parsePhoneNumberFromString(PREFIX_TELEFONO_PAIS + telefono);
    if (phoneNumber === undefined) {
      return false;
    } else {
      if (parsePhoneNumberFromString(PREFIX_TELEFONO_PAIS + telefono, phoneNumber.country).isValid()) {
        this.setState({ telefono });
        return true;
      } else {
        return false;
      }
    }
  }

  validar() {
    let { localNombre, telefono, email, localPassword, rePasword, terminos } = this.state;
    let items = [];

    if (localNombre === undefined || localNombre.trim() === '') items.push('Nombre Completo');
    if (telefono === undefined || telefono.trim() === '') {
      items.push('Celular');
    } else {
      if (this.validaCelular() === false) {
        items.push('El formato del Celular no es correcto');
      }
    }

    if (email === undefined || email.trim() === '') {
      items.push('Email');
    } else {
      if (this.validaEmail(email) === false) {
        items.push('Email no tiene el formato correcto');
      }
    }

    if (localPassword === undefined || localPassword.trim() === '') items.push('Contraseña');
    if (rePasword === undefined || rePasword.trim() === '') items.push('Confirmar Contraseña');
    if (this.validaPassword() === false) {
      items.push('Las contraseñas no coinciden');
    }
    if (terminos === false) items.push('Términos y Condiciones');

    if (items.length > 0) {
      alertObligatorio('Campos obligatorios', items);
      return false;
    } else {
      return true;
    }
  }

  guardar() {
    if (this.validar()) {
      const params = {};
      const {
        localNombre, telefono, email, localPassword, fechaNacimiento,
      } = this.state;
      let { usuario } = this.props;
      const data = {
        id: usuario != null ? usuario.data.id : 1,
        nombre: localNombre,
        celular: telefono,
        mail: email,
        username: email,
        password: localPassword,
        fechaNaci: fechaNacimiento
      }
      if (usuario != null) {
        LoginService.actualizaUsuario(params, data);
      } else {        
        const self = this;
        LoginService.guardarUsuarioTxt(params, data).then(function (returnData) {
          if (returnData.status === 200) {
            self.registroUsuario(localNombre, email);
            self.props.cancelar();
            success('Aviso', 'Favor revisar su correo, para finalizar el registro en Conecta Turismo.');
          } else if (returnData.status === 417 && returnData.data !== "") {        
              alertV2('Aviso', returnData.data)
          }
          else {
            alertV2('Aviso', MSJE_ERROR);
          }
        });

      }
    }
  }

  registroUsuario = (nombre, email) => {
    let tagManagerArgs = {
      dataLayer: {
        'event': 'RegistroUsuario',
        'category': 'Usuario',
        'action': 'Registro',
        'var_etiqueta': nombre,
        'var_valor': email,
      },
      dataLayerName: 'Sernatur'
    }
    TagManager.dataLayer(tagManagerArgs);
  }

  completaUsuario(usuario) {
    let email = usuario.data.mail;
    let telefono = usuario.data.celular;
    let localNombre = usuario.data.nombre;
    let fechaNacimiento = usuario.data.fechaNaci;
    let localRut = usuario.data.rut;
    let apellido = usuario.data.apPaterno;
    this.setState({
      email, fechaNacimiento, localNombre, telefono, localRut, apellido
    });
  }

  render() {
    let { labelMap } = this.props;
    let link = labelMap.LINK || {};

    const {
      guardar, handleInputNombre,
      validaEmail, handleInputEmail, handleInputCelular, onKeyPressCelular,
      handleInputPassword, handleInputRePassword,
    } = this;
    const {
      localNombre, email, telefono,
      localPassword, rePasword
    } = this.state;
    const { usuario } = this.props;
    if (usuario != null && localNombre.length <= 0) {
      this.completaUsuario(usuario)
    }
    return (
      <MDBContainer className="registro-usuario">
        <MDBContainer>
          <MDBRow>
            <span className="campos mt-4"><LabelMapper label="REGISTRO.NOMBRE" /></span>
          </MDBRow>
          <MDBRow>
            <InputText
              name="firstName"
              required
              value={localNombre}
              onChange={handleInputNombre}
              className="form-control" autocomplete="off"
              maxLength="50"
            />
          </MDBRow>
          <MDBRow>
            <span className="campos mt-3"><LabelMapper label="REGISTRO.CELULAR" /></span>
          </MDBRow>
          <MDBRow>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">{PREFIX_TELEFONO_PAIS}</span>
              </div>
              <InputText
                name="celular"
                required
                value={telefono}
                placeholder="Ejemplo: 91234567"
                onChange={handleInputCelular}
                className="form-control" autocomplete="off"
                maxLength="8"
                onKeyUp={onKeyPressCelular}
              />
            </div>
          </MDBRow>
          <MDBRow>
            <span className="campos mt-3"><LabelMapper label="REGISTRO.EMAIL" /></span>
          </MDBRow>
          <MDBRow>
            <InputText
              name="email"
              required
              value={email}
              onBlur={validaEmail}
              onChange={handleInputEmail}
              className="form-control" autocomplete="off"
              maxLength="50"
            />
          </MDBRow>
        </MDBContainer>
        <MDBContainer>
          <MDBRow>
            <span className="campos mt-3"><LabelMapper label="REGISTRO.CLAVE" /></span>
          </MDBRow>
          <MDBRow>
            <input
              type="password"
              name="password1"
              required
              value={localPassword}
              onChange={handleInputPassword}
              className="form-control" autocomplete="off"
            />
          </MDBRow>
          <MDBRow>
            <span className="campos mt-3"><LabelMapper label="REGISTRO.REPASSWORD" /></span>
          </MDBRow>
          <MDBRow>
            <input
              type="password"
              name="password2"
              required
              value={rePasword}
              onChange={handleInputRePassword}
              className="form-control" autocomplete="off"
            />
          </MDBRow>
        </MDBContainer>
        <MDBContainer>
          <MDBRow className="terminos-custom">
            <MDBInput className="mr-0" required label="Acepto los" type="checkbox"
              checked={this.state.terminos} value={this.state.terminos}
              id="checkbox1"
              onChange={this.handleInputTerminos} >
              <a target="_blank" rel="noopener noreferrer" href={link.TERMINOS}>
                <LabelMapper label="REGISTRO.TERMINOSYCONDICIONES" />
              </a>
            </MDBInput>
          </MDBRow>
        </MDBContainer>
        <MDBContainer className="mt-2">
          <MDBRow>
            <MDBCol>
              <div className="text-center">
                <button className="btn btn-sm btn-primary btn-rounded m-0 mb-2 mt-3 w-100 btn-principal" onClick={guardar} type="button">Registrarme</button>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    );
  }
}
/**
 * @description establece las props para el componente LabelMapperProvider
 * de acuerdo al `state`
 */
const mapStateToProps = (state) => ({
  labelMap: state.labelMap,
});

export default connect(
  mapStateToProps,
)(RegistroUsuario);
