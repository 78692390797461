import React from 'react';
import {
  Modal, ModalBody, ModalFooter,
} from '@ns-react/modal';
import { MDBCol, MDBRow, MDBContainer, MDBBtn } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DURACION_MODAL_RENOVACION } from 'commons/constants';

class ModalRenovarSesion extends Modal {
  constructor(props) {
    super(props);

    this.renovar = this.renovar.bind(this);
  }

  componentDidMount = () => {
    setTimeout(() => {
       sessionStorage.clear();
       window.location.href = window.location.origin;
    }, DURACION_MODAL_RENOVACION);
  }
  
  cerrar() {
    this.guardar = false;
    super.closeModal();
  }

  componentWillUnmount() {
    if (this.guardar) {
      this.props.renovar();
    }
  }

  renovar() {
    this.guardar = true;
    super.closeModal();
  }

  render() {
    return (
      <Modal size="small">
        <ModalBody>
          <div className="modal-body" >
            <MDBContainer>
              <div className="row mb-3">
                <div className="col d-flex justify-content-center">
                  <FontAwesomeIcon icon={["far", "clock"]} size="4x" color="#8b929a" ></FontAwesomeIcon>
                </div>
              </div>
              <div className="row">
                <div className="col" >
                  <h4 className="text-center" >Tu sesión expirará</h4>
                </div>
              </div>
              <div className="row" >
                <div className="col">
                  <h5 className="text-center" >¿Necesitas más tiempo?</h5>
                </div>
              </div>
            </MDBContainer>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="modal-footer float-none" >
            <MDBContainer>
              <MDBRow>
                <MDBCol className="d-flex justify-content-center">
                  <MDBBtn rounded className="btn-primario-modal-sm" onClick={this.renovar}  >
                    Continuar
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </ModalFooter>
      </Modal >
    );
  }
}

export default ModalRenovarSesion;
