import { createStore } from 'redux';
import reducers from './reducers';

/**
 * @description almacena `store` en variable
 * para no ejecutar `createStore` al realizar una importación
 * de `store` desde otro punto.
 */
const store = createStore(reducers);

export default store;
