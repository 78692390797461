import {
    DESTACADOS_TEC
} from '../actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case DESTACADOS_TEC:
            {
                return action.payload.data;
            }
        default:
            return state;
    }
};