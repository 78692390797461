import React, { Component } from 'react';
import {
  MDBContainer, MDBCol, MDBBtn, MDBCardImage, MDBCardTitle, MDBCard, MDBCardBody,
} from 'mdbreact';
import LabelMapper from 'commons/components/LabelMapper';
import { createNewID } from 'commons/util';
import { Link } from 'react-router-dom';
import { DestacadoDetalle, FLAG_EMPRESA, Inicio } from 'commons/constants';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { connect } from 'react-redux';

class DestacadosImplTec extends Component {

  render() {
    const itemsResponsive = {
      0: {
        items: 1,
      },
      1024: {
        items: 4
      }
    };

    let {destacados} = this.props.params;

    if(this.props.destacadosTecProps !== null && this.props.destacadosTecProps.destacados !== undefined){
      destacados = this.props.destacadosTecProps.destacados;
    }

   if (destacados.length <= 0) return null;

    return (
      <div className="fondo-Destacado mb-3" >
        <MDBContainer>
          <section className="my-4">
            <h5 className="titulo">
              Proveedores Tecnológicos Destacados
              {/* <LabelMapper label="DESTACADOS.TITULOTEC" /> */}
            </h5>
            <AliceCarousel mouseTrackingEnabled responsive={itemsResponsive} buttonsDisabled={true} infinite={false}>
              {
                  destacados.map((img) => (
                  <MDBCol key={createNewID()} >
                    <MDBCard className="mb-2 card-container pb-2">
                      <MDBCardImage className="card-img-top fotoCardDestacados" style={{ backgroundImage: `url(${img.url})` }} />
                      <MDBCardBody className="text-center"  >
                        <MDBCardTitle className="tituloDestacado"> {img.titulo} </MDBCardTitle>
                        <span className="descripcionDestacado">  {img.descripcion} </span>
                      </MDBCardBody>
                      <div style={{ display: sessionStorage.getItem(FLAG_EMPRESA) === 'true' ? 'inline-block' : 'none' }} >
                        <Link to={{
                          pathname: DestacadoDetalle + img.idEmpresa,
                          search: "?" + new URLSearchParams({
                            origen: Inicio,
                          }).toString()
                        }} >
                          <MDBBtn className="btn btn-sm buttonDestacados" >
                            <LabelMapper label="DESTACADOS.BUTTON1" />
                          </MDBBtn>
                        </Link>
                      </div>
                    </MDBCard>
                  </MDBCol>
                ))
              }
            </AliceCarousel>
          </section>
        </MDBContainer>
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  destacadosTecProps: state.destacadosTecProps
});

export default connect(
  mapStateToProps,
)(DestacadosImplTec);
