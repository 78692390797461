import {
    MSG_BANDEJA,
} from '../actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case MSG_BANDEJA: {
            return action.payload.data;
        }
        default:
            return state;
    }
};