import React, { Component } from 'react';
import InputText from 'commons/components/InputText';
import LoginService from 'commons/services/LoginService';
import LabelMapper from 'commons/components/LabelMapper';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { connect } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { alertV2, success, alertObligatorio } from 'commons/util';
import { PREFIX_TELEFONO_PAIS, MSJE_ERROR } from 'commons/constants';

class EditarUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localNombre: '',
      apellido: '',
      email: '',
      telefono: '',
      localPassword: '',
      localPasswordActual: '',
      rePasword: '',
      fechaNacimiento: '',
      terminos: false,
    };
    this.handleInputNombre = this.handleInputNombre.bind(this);
    this.handleInputEmail = this.handleInputEmail.bind(this);
    this.handleInputCelular = this.handleInputCelular.bind(this);
    this.handleInputPasswordActual = this.handleInputPasswordActual.bind(this);

    this.handleInputPassword = this.handleInputPassword.bind(this);
    this.handleInputRePassword = this.handleInputRePassword.bind(this);
    this.validaPassword = this.validaPassword.bind(this);
    this.validaEmail = this.validaEmail.bind(this);
    this.guardar = this.guardar.bind(this);
    this.validaCelular = this.validaCelular.bind(this);
    this.validaNombre = this.validaNombre.bind(this);
    this.handleInputTerminos = this.handleInputTerminos.bind(this);

    this.onKeyPressCelular = this.onKeyPressCelular.bind(this);
  }

  handleInputTerminos(e) {
    let { terminos } = this.state;
    terminos = e.target.checked;
    this.setState({ terminos });
  }

  validaPassword() {
    let { rePasword, localPassword } = this.state;
    if (localPassword !== '' && rePasword !== '') {
      if (localPassword !== rePasword) {
        rePasword = '';
        localPassword = '';

        this.setState({ rePasword, localPassword });

        return false;
      } else {
        return true;
      }
    }
  }

  validaNombre(e) {
    let { localNombre } = this.state;
    this.setState({ localNombre });
  }

  handleInputNombre(e) {
    let { localNombre } = this.state;
    localNombre = e.target.value;
    this.setState({ localNombre });
  }

  handleInputEmail(e) {
    let { email } = this.state;
    email = e.target.value;
    this.setState({ email });
  }

  handleInputCelular(e) {
    let { telefono } = this.state;
    telefono = e.target.value;
    this.setState({ telefono });
  }

  onKeyPressCelular(e) {
    let { telefono } = this.state;
    telefono = e.target.value;

    var out = '';
    var filtro = '1234567890';//Caracteres validos
	
    //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos 
    for (var i=0; i<telefono.length; i++)
       if (filtro.indexOf(telefono.charAt(i)) !== -1)
             //Se añaden a la salida los caracteres validos
	     out += telefono.charAt(i);
	
    telefono = out;
    this.setState({ telefono });
  }

  handleInputPasswordActual(e) {
    let { localPasswordActual } = this.state;
    localPasswordActual = e.target.value;
    this.setState({ localPasswordActual });
  }

  handleInputPassword(e) {
    let { localPassword } = this.state;
    localPassword = e.target.value;
    this.setState({ localPassword });
  }

  handleInputRePassword(e) {
    let { rePasword } = this.state;
    rePasword = e.target.value;
    this.setState({ rePasword });
  }

  validaEmail(email) {
    let reg = /^[a-z0-9][a-z0-9-_.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9]).[a-z0-9]{2,10}(?:.[a-z]{2,10})?$/;
    return reg.test(email);
  }

  validaCelular() {
    let { telefono } = this.state;

    let phoneNumber = parsePhoneNumberFromString(PREFIX_TELEFONO_PAIS + telefono);
    if (phoneNumber === undefined) {
      return false;
    } else {
      if (parsePhoneNumberFromString(PREFIX_TELEFONO_PAIS + telefono, phoneNumber.country).isValid()) {
        this.setState({ telefono });
        return true;
      } else {
        return false;
      }
    }
  }

  validar() {
    let { localNombre, telefono, email, localPasswordActual, localPassword, rePasword } = this.state;
    let items = [];

    if (localNombre === undefined || localNombre.trim() === '') items.push('Nombre Completo');
    if (telefono === undefined || telefono.trim() === '') {
      items.push('Celular');
    } else {
      if (this.validaCelular() === false) {
        items.push('El formato del Celular no es correcto');
      }
    }

    if (email === undefined || email.trim() === '') {
      items.push('Email');
    } else {
      if (this.validaEmail(email) === false) {
        items.push('Email no tiene el formato correcto');
      }
    }

    if (localPasswordActual.trim() !== '' &&
      localPassword.trim() !== '' &&
      localPasswordActual.trim() !== '') {
      if (this.validaPassword() === false) {
        items.push('Las contraseñas no coinciden');
      }
    }

    if (localPasswordActual.trim() !== '') {
      if (localPassword.trim() === '' && !items.includes('Nueva Contraseña')) {
        items.push('Nueva Contraseña');
      }
      if (rePasword.trim() === '' && !items.includes('Confirmar Nueva Contraseña')) {
        items.push('Confirmar Nueva Contraseña');
      }
    }

    if (localPassword.trim() !== '') {
      if (localPasswordActual.trim() === '' && !items.includes('Contraseña Actual')) {
        items.push('Contraseña Actual');
      }
      if (rePasword.trim() === '' && !items.includes('Confirmar Nueva Contraseña')) {
        items.push('Confirmar Nueva Contraseña');
      }
    }

    if (rePasword.trim() !== '') {
      if (localPasswordActual.trim() === '' && !items.includes('Contraseña Actual')) {
        items.push('Contraseña Actual');
      }
      if (localPassword.trim() === '' && !items.includes('Nueva Contraseña')) {
        items.push('Nueva Contraseña');
      }
    }

    if (items.length > 0) {
      alertObligatorio('Campos obligatorios', items);
      return false;
    } else {
      return true;
    }
  }

  guardar() {
    if (this.validar()) {
      const params = {};
      const {
        localNombre, telefono, email, localPassword, fechaNacimiento,
      } = this.state;
      let { usuario } = this.props;
      const data = {
        id: usuario != null ? usuario.data.id : 1,
        nombre: localNombre,
        celular: telefono,
        mail: email,
        username: email,
        password: localPassword,
        fechaNaci: fechaNacimiento
      }
      if (usuario != null) {
        LoginService.resfrescarTicket().then(function () {

          LoginService.actualizaUsuario(params, data).then(function (returnData) {
            if (returnData.status === 200) {
              success('Aviso', 'Información actualizada correctamente');
            } else {
              alertV2('Aviso', MSJE_ERROR);
            }
          });

        });
      }
    }
  }

  completaUsuario(usuario) {
    let email = usuario.data.mail;
    let telefono = usuario.data.celular;
    let localNombre = usuario.data.nombre;
    let fechaNacimiento = usuario.data.fechaNaci;
    let localRut = usuario.data.rut;
    let apellido = usuario.data.apPaterno;
    this.setState({
      email, fechaNacimiento, localNombre, telefono, localRut, apellido
    });
  }

  render() {
    const {
      guardar, handleInputNombre,
      handleInputEmail, handleInputCelular, onKeyPressCelular,
      handleInputPassword, handleInputRePassword, handleInputPasswordActual,
      validaNombre
    } = this;
    const {
      localNombre, email, telefono,
      localPassword, rePasword, localPasswordActual
    } = this.state;
    const { usuario } = this.props;
    if (usuario != null && localNombre.length <= 0) {
      this.completaUsuario(usuario)
    }
    return (
      <MDBContainer className="float-left margin-dashboard">
        <div className="row p-0">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">
            <MDBContainer className="p-0">
              <MDBRow>
                <h5 className="titulo-dashboard py-3"> <LabelMapper label="REGISTRO.INFOPERSONAL" /> </h5>
              </MDBRow>
              <MDBRow>
                <span className="campos mt-2"><LabelMapper label="REGISTRO.NOMBRE" /><strong className="lbl-obligatorio pl-1" >*</strong></span>
              </MDBRow>
              <MDBRow>
                <InputText
                  name="firstName"
                  required
                  value={localNombre}
                  onChange={handleInputNombre}
                  onBlur={validaNombre}
                  className="form-control" autocomplete="off"
                  maxLength="50"
                />
              </MDBRow>
              <MDBRow>
                <span className="campos mt-3"><LabelMapper label="REGISTRO.CELULAR" /><strong className="lbl-obligatorio pl-1" >*</strong></span>
              </MDBRow>
              <MDBRow>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{PREFIX_TELEFONO_PAIS}</span>
                  </div>
                  <InputText
                    name="celular"
                    required
                    value={telefono}
                    onChange={handleInputCelular}
                    placeholder="Ejemplo: 91234567"
                    className="form-control" autocomplete="off"
                    onKeyUp={onKeyPressCelular}
                    maxLength="8"
                  />
                </div>
              </MDBRow>
              <MDBRow>
                <span className="campos mt-3"><LabelMapper label="REGISTRO.EMAIL" /><strong className="lbl-obligatorio pl-1" >*</strong></span>
              </MDBRow>
              <MDBRow>
                <InputText
                  name="email"
                  required
                  value={email}
                  onChange={handleInputEmail}
                  className="form-control" autocomplete="off"
                  disabled
                />
              </MDBRow>
            </MDBContainer>
            <MDBContainer className="mt-5">
              <MDBRow>
                <h5 className="titulo-dashboard py-3"> <LabelMapper label="REGISTRO.CONTRASENA" /></h5>
              </MDBRow>
              <MDBRow>
                <span className="campos mt-2"><LabelMapper label="REGISTRO.PASSWORDACTUAL" /></span>
              </MDBRow>
              <MDBRow>
                <input
                  type="password"
                  name="password0"
                  required
                  value={localPasswordActual}
                  onChange={handleInputPasswordActual}
                  className="form-control" autocomplete="off"
                />
              </MDBRow>
              <MDBRow>
                <span className="campos mt-3">Nueva Contraseña</span>
              </MDBRow>
              <MDBRow>
                <input
                  type="password"
                  name="password1"
                  required
                  value={localPassword}
                  onChange={handleInputPassword}
                  className="form-control" autocomplete="off"
                />
              </MDBRow>
              <MDBRow>
                <span className="campos mt-3">Confirmar Nueva Contraseña</span>
              </MDBRow>
              <MDBRow>
                <input
                  type="password"
                  name="password2"
                  required
                  value={rePasword}
                  onChange={handleInputRePassword}
                  className="form-control" autocomplete="off"
                />
              </MDBRow>
            </MDBContainer>
            <MDBContainer className="mt-5">
              <MDBRow className="text-center" >
                <MDBCol size="12" >
                  <MDBBtn rounded className="btn-primario" onClick={guardar} >Guardar</MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </div>
      </MDBContainer>
    );
  }
}
/**
 * @description establece las props para el componente LabelMapperProvider
 * de acuerdo al `state`
 */
const mapStateToProps = (state) => ({
  labelMap: state.labelMap,
});

export default connect(
  mapStateToProps,
)(EditarUsuario);
