import {
  FETCHING_DATA,
  FETCHING_DATA_COMPLETE,
} from '../actionTypes';

/**
 * @description Envía al store la data `isFetching` como `true` para
 * determinar que se están realizando peticiones con fetch
 */
export const fetchingData = () => ({
  type: FETCHING_DATA,
  payload: {
    isFetching: true,
  },
});

/**
 * @description Envía al store la data `isFetching` como `false` para
 * determinar que se terminaron las peticiones, esta acción debe
 * ocurrir sólo cuando ya no quedan más peticiones pendientes.
 */
export const fetchingDataComplete = () => ({
  type: FETCHING_DATA_COMPLETE,
  payload: {
    isFetching: false,
  },
});
