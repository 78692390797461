import {
    AVATAR,
} from '../actionTypes';

/**
 * @description Envía al store la data `map` de los labels
 * obtenidos
 */
export const avatarData = (data) => ({
    type: AVATAR,
    payload: {
        data: data
    },
    error: false,
});