/**
 * @author Felipe Alamos
 * @description Función que permite dar formato a un rut
 */
export default function formateaRut(rut) {
    let actual = rut.replace(/^0+/, "");
    let rutPuntos = "";
    if (actual !== '' && actual.length > 1 && actual.length < 13) {
        let sinPuntos = actual.replace(/\./g, "");
        let actualLimpio = sinPuntos.replace(/-/g, "");
        let inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        /*let i = 0;
        let j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
            debugger;
            let letra = inicio.charAt(i);
            rutPuntos = letra + rutPuntos;            
            if (j % 3 === 0 && j <= inicio.length - 1) {
                rutPuntos = +rutPuntos;
            }
            j++;
        }*/
        let dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = inicio + "-" + dv;
        //rutPuntos = '76694082-K'
        if (validaRut(rutPuntos) === false) {
            rutPuntos = "";
        }
    } else {
        rutPuntos = "";
    }

    return rutPuntos;
}

function validaRut(rutCompleto) {
    rutCompleto = rutCompleto.replace("‐", "-");
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
        return false;
    var tmp = rutCompleto.split('-');
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv === 'K') digv = 'k';
    return (dv(rut).toString() === digv);
}

function dv(T) {
    var M = 0,
        S = 1;
    for (; T; T = Math.floor(T / 10))
        S = (S + T % 10 * (9 - M++ % 6)) % 11;
    return S ? S - 1 : 'k';
}