import {
    DESTACADOS_TUR,
    DESTACADOS_TEC,
} from '../actionTypes';

/**
 * @description Envía al store la data `map` de los labels
 * obtenidos
 */
export const destacadosTurData = (data) => ({
    type: DESTACADOS_TUR,
    payload: {
        data: data
    },
    error: false,
});

export const destacadosTecData = (data) => ({
    type: DESTACADOS_TEC,
    payload: {
        data: data
    },
    error: false,
});