import React, { Component } from 'react';
import LoginService from 'commons/services/LoginService';
import EditarUsuario from 'commons/components/sesion/editarUsuarios/EditarUsuario';

class MiperfilProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        usuario: {}
      },
    };
  }

  componentDidMount() {
    this.recuperaUsuario();
  }

  recuperaUsuario() {
    const self = this;
    LoginService.resfrescarTicket().then(function () {
      LoginService.recuperaUsuario().then((usuarios) => {
        self.setState({
          usuario: usuarios
        });
      });
    });
  }

  render() {
    const { usuario } = this.state;

    return (
      <EditarUsuario usuario={usuario} />
    );
  }
}

export default MiperfilProvider;
