import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn} from 'mdbreact';
import LabelMapper from 'commons/components/LabelMapper';

class ErrorApp extends Component {

  constructor(props) {
    super(props);

    this.toHome = this.toHome.bind(this);
  }

  toHome() {
    this.props.history.push("/");
  }

  render() {

    return (
        <MDBContainer className="border border-dark carousel-custom-detalle container-fluid">
          <MDBRow className="mt-5">
              <h5 className="text-primary text-left font-weight-bold">
              <LabelMapper label="MSG.ERROR_GENERAL_PAGE" /> </h5>
          </MDBRow>
          <MDBRow className="mt-3">
            <MDBCol className="d-flex justify-content-end">
                  <MDBBtn className="btn buttonDestacados text-capitalize" onClick={this.toHome} >
                    <LabelMapper label="COMUN.VOLVER" />
                  </MDBBtn>
             </MDBCol>
          </MDBRow>
        </MDBContainer>
    );
  }
}

export default ErrorApp;
