import React, { Component } from "react";
import {
  MDBCol,
  MDBBtn,
  MDBCardImage,
  MDBCardTitle,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBBadge,
} from "mdbreact";
import LabelMapper from "commons/components/LabelMapper";
import { createNewID, alertV2 } from "commons/util";
import { Link } from "react-router-dom";
import { DestacadoDetalle, FLAG_EMPRESA } from "commons/constants";
import ModalMatch from "./../../../home/components/ModalMatch";
import { modal } from "commons/util";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { connect } from "react-redux";

class MisMatch extends Component {
  configurarMatch = () => {
    let flagEmpresa = sessionStorage.getItem(FLAG_EMPRESA);
    if (flagEmpresa === "true") {
      modal(ModalMatch, {
        verMatch: this.verMatch,
      });
    } else {
      alertV2("Aviso", "Registra tu Empresa y podrás utilizar MATCH");
    }
  };

  verMatch = () => {
    this.props.actualizar();
  };

  render() {
    const itemsResponsive = {
      0: {
        items: 1,
      },
      426: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1024: {
        items: 4,
      },
    };

    let { destacados } = this.props.params;
    let { verResultados } = this.props;

    if (
      this.props.matchProps !== null &&
      this.props.matchProps.destacados !== undefined
    ) {
      destacados = this.props.matchProps.destacados;
    }

    if (
      this.props.matchProps !== null &&
      this.props.matchProps.verResultados !== undefined
    ) {
      verResultados = this.props.matchProps.verResultados;
    }

    const rating =
      this.props.params.rating === undefined ? 0 : this.props.params.rating;
    let tamanioSlider = "col-md-12";
    // let cantidad = this.props.params.destacados === undefined ? 0 : this.props.params.destacados.length;
    let cantidad = destacados === undefined ? 0 : destacados.length;

    switch (cantidad) {
      case 1:
        tamanioSlider = "col-md-3";
        break;
      case 2:
        tamanioSlider = "col-md-6";
        break;
      case 3:
        tamanioSlider = "col-md-9";
        break;
      default:
        tamanioSlider = "col-md-12 px-0";
        break;
    }

    let tipoEmpresas;
    let tipoEmp
    let esTecnologica = false;
    if(this.props.params.filtros !== undefined){
      tipoEmpresas = this.props.params.filtros.map((cat) => cat.tipoEmpresa)
      if(tipoEmpresas.length > 0){
        tipoEmp = tipoEmpresas[0]
        if(tipoEmp === "TEC")
          esTecnologica = true;
      }
    }

    return (
      <div className="fondo-mis-match">
        <MDBContainer className="container-mis-match">
          <div className="card h-100">
            <div className="card-body p-2">
              <div className="row align-items-center h-100">
                <div className="col-lg-1 d-none d-md-block"></div>
                <div className="col-md-4 col-lg-3 col-xl-2 mx-auto d-none d-md-block">
                  <p className="mt-0 mb-0 font-weight-bold">
                    Tu avance del
                    <br />
                    Match está al
                    <br />
                  </p>
                </div>
                <div className="col-md-2 col-lg-1 d-none d-md-block">
                  <div className="progress" data-percentage={rating}>
                    <span className="progress-left">
                      <span className="progress-bar"></span>
                    </span>
                    <span className="progress-right">
                      <span className="progress-bar"></span>
                    </span>
                    <div className="progress-value">
                      <div>{rating}%</div>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-md-none mb-3">
                  <div className="row">
                    <div className="col mx-auto pt-3 pl-0">
                      <p className="mt-0 mb-0 font-weight-bold">
                        Tu avance del
                        <br />
                        Match está al
                        <br />
                      </p>
                    </div>
                    <div className="col-xs-4">
                      <div className="progress" data-percentage={rating}>
                        <span className="progress-left">
                          <span className="progress-bar"></span>
                        </span>
                        <span className="progress-right">
                          <span className="progress-bar"></span>
                        </span>
                        <div className="progress-value">
                          <div>{rating}%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-7 col-xl-8 mx-auto">
                  {destacados !== undefined && destacados.length === 0 ? (
                    //this.props.params.destacados !== undefined && this.props.params.destacados.length === 0
                    <p className="descripcion-match">
                      Aún no has completado tu Match
                      <br />
                      ¿Qué estás esperando?
                      <br />
                      <MDBBtn
                        rounded
                        className="btn-primario btn-mis-match mt-3 mb-0"
                        onClick={this.configurarMatch}
                      >
                        Comenzar
                      </MDBBtn>
                    </p>
                  ) : (
                    <p className="descripcion-match">
                      ¿No encontraste lo que buscabas?
                      <br />
                      Prueba actualizando tu Match
                      <br />
                      <MDBBtn
                        rounded
                        className="btn-primario btn-mis-match mt-3 mb-0"
                        onClick={this.configurarMatch}
                      >
                        Nuevo Match
                      </MDBBtn>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <section className="text-left">
            {destacados !== undefined &&
            destacados.length > 0 &&
            verResultados ? (
              //this.props.params.destacados !== undefined && this.props.params.destacados.length > 0 && this.props.verResultados ?
              <React.Fragment>
                <h5 className="titulo mt-3 mb-2">Parámetros de Búsqueda</h5>
                <div className="row">                  
                  {esTecnologica ?
                    this.props.params.filtros.map((cat, i) => (
                      <div className="col">
                        <MDBBadge className="badge-categoria mr-2">
                          {cat.descripcion}
                        </MDBBadge>
                        {cat.servicios.map((serv) => (
                          <React.Fragment>
                            <MDBBadge color="primary mr-2">
                              {serv.descripcion}
                            </MDBBadge>
                            {serv.subCategorias.map((sub) => (
                              <MDBBadge color="badge-categoria mr-2">
                                {sub.descripcion}
                              </MDBBadge>
                            ))}
                          </React.Fragment>                       
                        ))}
                      </div>
                      ))
                    :
                      this.props.params.filtros.map((cat, i) => (
                      <div className="col">
                        <MDBBadge className="badge-categoria mr-2">
                          {cat.descripcion}
                        </MDBBadge>
                        {cat.subCategorias.map((sub) => (
                          <MDBBadge color="primary mr-2">
                            {sub.descripcion}
                          </MDBBadge>
                        ))}
                      </div>
                      ))
                  }
                </div>
                <hr className="mt-3 mb-4 w-75" />
                <div className="row">
                  <MDBCol key={createNewID()} className={tamanioSlider}>
                    <AliceCarousel
                      mouseTrackingEnabled
                      buttonsDisabled={true}
                      responsive={itemsResponsive}
                      infinite={false}
                      items={cantidad}
                    >
                      {destacados.map((img) => (
                        //this.props.params.destacados.map((img) => (
                        <MDBCol key={createNewID()}>
                          <MDBCard className="mb-2 card-container pb-2">
                            <MDBCardImage
                              className="card-img-top fotoCardDestacados"
                              style={{ backgroundImage: `url(${img.url})` }}
                            />
                            <MDBCardBody className="text-center">
                              <MDBCardTitle className="tituloDestacado">
                                {" "}
                                {img.nombre}{" "}
                              </MDBCardTitle>
                              <span className="descripcionDestacado">
                                {" "}
                                {img.descripcionBreve.length > 70
                                  ? img.descripcionBreve.substring(0, 70) +
                                    "..."
                                  : img.descripcionBreve}{" "}
                              </span>
                            </MDBCardBody>
                            <div className="row">
                              <div className="col-md-4">
                                <label className="lbl-rating">
                                  {img.rating}%<br />
                                  <sup>Match</sup>
                                </label>
                              </div>
                              <div className="col-md-8 text-right">
                                <Link
                                  to={{
                                    pathname: DestacadoDetalle + img.id,
                                    search:
                                      "?" +
                                      new URLSearchParams({
                                        origen: "/dashboard/2/match",
                                      }).toString(),
                                  }}
                                >
                                  <MDBBtn className="btn btn-sm buttonDestacados btnDestacado-match">
                                    <LabelMapper label="DESTACADOS.BUTTON1" />
                                  </MDBBtn>
                                </Link>
                              </div>
                            </div>
                          </MDBCard>
                        </MDBCol>
                      ))}
                    </AliceCarousel>
                  </MDBCol>
                </div>
              </React.Fragment>
            ) : verResultados ? (
              // this.props.verResultados ?
              <div className="match-sin-resultados mt-5">
                <h4 className="titulo-sin-resultados">
                  No se encontraron resultados
                </h4>
                <p className="w-75">
                  Por el momento no hemos encontrado empresas que hagan match
                  con tu perfil, en la medida que los otros usuarios completen
                  su perfil la plataforma actualizará tus resultados.
                </p>
              </div>
            ) : (
              <div className="match-sin-resultados mt-5">
                <h4 className="titulo-sin-resultados">
                  Actualizando resultados...
                </h4>
              </div>
            )}
          </section>
        </MDBContainer>
      </div>
    );
  }
}
//export default MisMatch;

const mapStateToProps = (state) => ({
  matchProps: state.matchProps,
});

//export default withRouter(DestacadosImplTec);

export default connect(mapStateToProps)(MisMatch);
