import BaseService from './base/BaseService';

/**
 * @author Falamos
 * @description capa de servicio para la vista `home`, extiende
 * de la clase base `BaseService` y realiza una llamada a la api `/HomeRest`
 */
export default class HomeRest extends BaseService {
  /**
     * @author Falamos
     * @description metodo que se encarga de recuperar los datos para el carousel
     */
  static findCarrusel(params) {
    return super.get('home/findCarrusel', params);
  }

  /**
     * @author Falamos
     * @description metodo que se encarga de recuperar los datos para el componente destacado turismo
     */
  static findDestacadosTur(params) {
    return super.get('home/findDestacadosTur', params);
  }

  /**
   * @author Falamos
   * @description metodo que se encarga de recuperar los datos para el componente destacado turismo, con filtro
   */
  static findDestacadosTurFiltro(params) {
    return super.get('home/findDestacadosTurFiltro', params);
  }

  /**
   * @author Falamos
   * @description metodo que se encarga de recuperar los datos para el componente destacado tecnologia
   */
  static findDestacadosTec(params) {
    return super.get('home/findDestacadosTec', params);
  }

    /**
   * @author Falamos
   * @description metodo que se encarga de recuperar los datos para el componente destacado tecnologia, con filtro
   */
  static findDestacadosTecFiltro(params) {
    return super.get('home/findDestacadosTecFiltro', params);
  }

  /**
     * @author Falamos
     * @description metodo que se encarga de recuperar los datos para el componente testimonios
     */
  static findTestimonios(params) {
    return super.get('home/findTestimonios', params);
  }

  /**
     * @author Falamos
     * @description metodo que se encarga de recuperar los datos para el componente detalle de empresa
     */
  static empresaDestacada(params) {
    return super.get('home/empresaDestacada', params);
  }

  static solicitudContacto(data) {
    return super.post('home/mailContacto', null, data);
  }

  static findVideos(data) {
    return super.get('home/findVideoConfig');
  }

}
